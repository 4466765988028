import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { get } from "lodash";
import { NotificationManager } from "react-notifications";
import { rateList } from "_common/constants/statusType";
import ModalBet from "./ModalBet";

export default function GameBoard({ game_id, data_price, direction }) {
  const queryClient = useQueryClient();
  const {
    authStore: { user },
  } = useStores();

  const [last_price, setLastPrice] = useState(get(data_price, "lastPrice", 0));
  const [game_type, setGameType] = useState(direction || "buy");
  const [bet_type, setBetType] = useState(30);
  const [amount, setAmount] = useState();
  const [loss_price, setLossPrice] = useState();
  const [profit_price, setProfitPrice] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleChangeInput = (event) => {
    if (!event?.target?.value) {
      setAmount();
    } else {
      setAmount(Number(event?.target?.value));
    }
  };

  useEffect(() => {
    setLastPrice(Number(get(data_price, "lastPrice", 0)));
  }, [data_price]);

  const handelSubmit = (type) => {
    if (!amount || amount <= 0)
      return NotificationManager.warning(i18next.t("amount_cannot_empty"));
    setGameType(type);
    setOpenModal(true);
    return null;
  };

  return (
    <div className="feature-board contract-board">
      <div className="qty-input !w-full mt-[20px]">
        <input
          placeholder={i18next.t("transaction_amount_enter", {
            min: formatNumber(10),
            max: formatNumber(1000000),
          })}
          min={0}
          type="number"
          inputMode="decimal"
          className="sbui-input sbui-input--large"
          step="0.000001"
          value={amount}
          onChange={handleChangeInput}
        />
        <button
          type="button"
          className="btn btn-add font-bold !bg-transparent !text-[#fbc501]"
          onClick={() => setAmount(Number(user?.balance_option))}
          style={{
            width: 64,
          }}
        >
          {i18next.t("all")}
        </button>
      </div>
      <div className="flex justify-between py-[5px]">
        <span>{i18next.t("available")}</span>
        <b>{formatNumber(user?.balance_option)}USDT</b>
      </div>
      <div className="flex mt-[20px]">
        <p className="text-[16px] mb-[10px]">
          <b>{i18next.t("choose_time")}</b>
        </p>
      </div>
      <div className="list-time">
        {rateList?.map((item) => (
          <div
            className={classNames("item", {
              active: bet_type === item?.value,
            })}
            onClick={() => setBetType(item?.value)}
          >
            <h5>
              {item?.value}
              <small>s</small>
            </h5>
            <p>
              ({i18next.t("win")}
              {item?.rate}%)
            </p>
          </div>
        ))}
      </div>
      <div className="button-footer ">
        <button
          type="button"
          className={classNames("btn btn-buy")}
          onClick={() => handelSubmit("buy")}
        >
          {i18next.t("rise")}
        </button>
        <button
          type="button"
          className={classNames("btn btn-sell")}
          onClick={() => handelSubmit("sell")}
        >
          {i18next.t("fall")}
        </button>
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          data={{
            game_id,
            type: "feature",
            mode: bet_type,
            game_type,
            amount,
            lastPrice: last_price,
            profit_price,
            loss_price,
          }}
        />
      )}
    </div>
  );
}
