import React, { useState } from "react";
import { Portal } from "react-portal";
import i18next from "i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Currency from "./Currency";

export default function ListCoin({ open, onClose }) {
  const [active, setActive] = useState("option");

  return (
    <Portal>
      <div className="left-draw" style={{ display: open ? "block" : "none" }}>
        <div className="mask" onClick={onClose} />
        <div className="content">
          <h2 className="title">{i18next.t("spot_trading")}</h2>
          <div className="search-wrapper">
            <div className="input-search">
              <FontAwesomeIcon icon={faSearch} />
              <input placeholder={i18next.t("search")} />
            </div>
          </div>
          <div className="tabs tab-bin">
            <div
              className={classNames("tab", {
                active: active === "option",
              })}
              onClick={() => setActive("option")}
            >
              <span>{i18next.t("favorite")}</span>
            </div>
            <div
              className={classNames("tab", {
                active: active === "usdt",
              })}
              onClick={() => setActive("usdt")}
            >
              <span>{i18next.t("all")}</span>
            </div>
          </div>
          {active === "usdt" && <Currency onClose={onClose} quote="USDT" />}
        </div>
      </div>
    </Portal>
  );
}
