import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import TransferMobile from "./Mobile";
import TransferDesktop from "./Desktop";

const TransferPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <TransferMobile />;
  return <TransferDesktop />;
});

export default TransferPage;
