import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { UserFundApi } from "states/api/userFund";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate, useParams } from "react-router-dom";
import { fundStatus } from "_common/constants/statusType";
import moment from "moment";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import IncomeBreakdown from "./IncomeBreakdown";

function BasicInformation({ data }) {
  return (
    <div className="p-[20px] font-bold">
      <div className="flex justify-between mb-[10px]">
        <span className="text-[16px] text-[#888]">{i18next.t("amount")}</span>
        <span className="text-[16px] text-[#1e1e1e]">
          {formatNumber(data?.amount, "0,0.[00000]")} USDT
        </span>
      </div>
      <div className="flex justify-between mb-[10px]">
        <span className="text-[16px] text-[#888]">
          {i18next.t("allocated_time")}
        </span>
        <span className="text-[16px] text-[#1e1e1e]">
          {data?.created_at &&
            moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")}
        </span>
      </div>
      <div className="flex justify-between mb-[10px]">
        <span className="text-[16px] text-[#888]">
          {i18next.t("interest_accrual_time")}
        </span>
        <span className="text-[16px] text-[#1e1e1e]">
          {data?.created_at &&
            moment(data?.created_at)
              .add(1, "day")
              .format("YYYY-MM-DD HH:mm:ss")}
        </span>
      </div>
      <div className="flex justify-between mb-[10px]">
        <span className="text-[16px] text-[#888]">
          {i18next.t("payment_time")}
        </span>
        <span className="text-[16px] text-[#1e1e1e]">
          {data?.created_at &&
            moment(data?.created_at)
              .add(data?.date, "day")
              .format("YYYY-MM-DD HH:mm:ss")}
        </span>
      </div>
      <div className="flex justify-between mb-[10px] mt-[20px]">
        <span className="text-[16px] text-[#888]">
          {i18next.t("project_name")}
        </span>
        <span className="text-[16px] text-[#1e1e1e]">{data?.fund?.name}</span>
      </div>
      <div className="flex justify-between mb-[10px]">
        <span className="text-[16px] text-[#888]">
          {i18next.t("daily_rate")}
        </span>
        <span className="text-[16px] text-[#1e1e1e]">
          {formatNumber(data?.fund?.profit, "0,0.[0000]")}%
        </span>
      </div>
      <div className="flex justify-between mb-[10px]">
        <span className="text-[16px] text-[#888]">{i18next.t("duration")}</span>
        <span className="text-[16px] text-[#1e1e1e]">
          {formatNumber(data?.fund?.date, "0,0.[0000]")}
        </span>
      </div>
      <div className="flex justify-between mb-[10px]">
        <span className="text-[16px] text-[#888]">{i18next.t("grant")}</span>
        <span className="text-[16px] text-[#1e1e1e]">{i18next.t("daily")}</span>
      </div>
      <div className="flex justify-between mb-[10px]">
        <span className="text-[16px] text-[#888]">{i18next.t("type")}</span>
        <span className="text-[16px] text-[#1e1e1e]">{i18next.t("win")}</span>
      </div>
    </div>
  );
}

export default function FundHistoryDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;

  const [activeTab, setActiveTab] = useState("basic");

  const { data, refetch } = useQuery(
    ["funds", "history_detail", id],
    () =>
      UserFundApi.getDetail({
        id,
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <HeaderTop title={i18next.t("stake_detail")} />
      <div className="fund-history-detail py-[60px]">
        <div className="pb-[45px] px-[20px]">
          <h3 className="text-center text-[20px] font-bold mt-[15px]">
            {data?.fund?.name}
          </h3>
          <div className="flex justify-center mt-[5px]">
            <div className="tag bd-link">
              {i18next.t(fundStatus[data?.status])}
            </div>
          </div>
          <h1 className="text-center text-[32px] font-[900] mt-[30px] text-[#ff6a6a] leading-[30px]">
            {formatNumber(data?.amount_payback, "0,0.[000]")} USDT
          </h1>
          <p className="text-center font-[500] text-[#888] text-[15px]">
            {i18next.t("expected_profit")}
          </p>
        </div>
        <div className="split-line-10" />
        <div className="py-[20px]">
          <div className="tabs tab-bin">
            <div
              className={classNames("tab", {
                active: activeTab === "basic",
              })}
              onClick={() => setActiveTab("basic")}
            >
              <span>{i18next.t("basic_information")}</span>
            </div>
            <div
              className={classNames("tab", {
                active: activeTab === "income",
              })}
              onClick={() => setActiveTab("income")}
            >
              <span>{i18next.t("income_breakdown")}</span>
            </div>
          </div>
          {activeTab === "basic" && <BasicInformation data={data} />}
          {activeTab === "income" && (
            <div className="px-[20px]">
              <IncomeBreakdown fund_id={data?.id} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
