import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import i18next from "i18next";
import { PairApi } from "states/api/pair";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import BodyCoin from "./BodyCoin";

const Favorites = observer(({ setActive, quote }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { favorites },
  } = useStores();

  const [activeTab, setActiveTab] = useState("spot");

  const { data: listCurrency, refetch: onGetList } = useQuery(
    ["pairs", "list", quote],
    () =>
      PairApi.getList({
        params: {
          page: 1,
          limit: 50,
          quote,
          transaction_status: 1,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!quote) return;
    onGetList();
  }, [quote]);

  const coinListPrice = useMemo(
    () =>
      listCurrency?.data
        ?.filter((obj) => favorites?.indexOf(obj?.code) > -1)
        ?.map((item) => {
          const price = data?.find(
            (obj) => obj?.symbol?.toLowerCase() === item?.code
          );

          return {
            ...item,
            ...price,
            priceChangePercent: item?.percent || price?.priceChangePercent,
            volume: item?.volume || price?.volume,
            lastPrice: item?.price || price?.lastPrice,
            symbol: item?.code,
          };
        }),
    [listCurrency, favorites, data]
  );

  return (
    <div className="coin-list">
      <div className="flex items-center justify-between">
        <div className="tabs tab-bin">
          <div
            className={classNames("tab", {
              active: activeTab === "spot",
            })}
            onClick={() => setActiveTab("spot")}
          >
            <span>{i18next.t("spot")}</span>
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "futures",
            })}
            onClick={() => setActiveTab("futures")}
          >
            <span>{i18next.t("futures")}</span>
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "options",
            })}
            onClick={() => setActiveTab("options")}
          >
            <span>{i18next.t("options")}</span>
          </div>
        </div>
        <FontAwesomeIcon icon={faSearch} className="text-[24px] text-[#888]" />
      </div>

      <div className="list">
        <div className="h-[100vh] overflow-y-scroll">
          <div className="item !bg-[transparent]">
            <div className="col1">
              <div className="flex items-center gap-2">{i18next.t("pair")}</div>
            </div>
            <div className="col2 !justify-center">
              {i18next.t("latest_price")}
            </div>
            <div className="col3">{i18next.t("change")}</div>
          </div>
          {favorites?.length ? (
            <BodyCoin coinListPrice={coinListPrice} />
          ) : (
            <div className="flex flex-col items-center gap-4 py-[30px]">
              <FontAwesomeIcon
                icon={faPlus}
                className="text-[40px] text-[#fbbc01]"
                onClick={() => setActive("spot")}
              />
              <span className="text-[18px] text-[#bdbdbd]">
                {i18next.t("added_successfully")}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Favorites;
