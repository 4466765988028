import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import UpdateUserMobile from "./Mobile";
import UpdateUserDesktop from "./Desktop";

const UpdateUserPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <UpdateUserMobile />;
  return <UpdateUserDesktop />;
});

export default UpdateUserPage;
