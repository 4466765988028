import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import HeaderDesktop from "_common/Layout/Header/HeaderDesktop";

import DepositMobile from "./Mobile";
import DepositDesktop from "./Desktop";

const DepositPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <DepositMobile />;
  return <DepositDesktop />;
});

export default DepositPage;
