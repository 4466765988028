import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

import i18next from "i18next";

import LeftNav from "pages/User/LeftNav";
import ListRecordDesktop from "./ListRecord";

export default function HistoryDepositDesktop() {
  const queryClient = useQueryClient();
  const [params] = useSearchParams();
  const type = params?.get("type");

  const [activeTab, setActiveTab] = useState(type || "deposit");

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div>
              <div className="title">
                <h3>{i18next.t("record")}</h3>
              </div>
              <div className="tabs tab-box">
                <div
                  className={classNames("tab", {
                    active: activeTab === "deposit",
                  })}
                  onClick={() => setActiveTab("deposit")}
                >
                  {i18next.t("deposit_records")}
                </div>
                <div
                  className={classNames("tab", {
                    active: activeTab === "withdraw",
                  })}
                  onClick={() => setActiveTab("withdraw")}
                >
                  {i18next.t("withdraw_records")}
                </div>
                <div
                  className={classNames("tab", {
                    active: activeTab === "transfer",
                  })}
                  onClick={() => setActiveTab("transfer")}
                >
                  {i18next.t("transfer_records")}
                </div>
              </div>
              <div className="my-[15px] px-[30px]">
                <ListRecordDesktop type={activeTab} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
