import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";

const Banner = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, general },
  } = useStores();

  return (
    <div className="css-1pysja1">
      <div className="bg-box">
        <div className="css-1t9l9dt" style={{ width: "100%" }}>
          <div className="css-8hstpq-bg css-8hstpq">
            <div className="css-b22026 reg-input-box">
              <div className="css-1xamyaw  css-1xamyaw-l">
                <div className="css-1xamyaw-content">
                  <p
                    className="fw"
                    style={{
                      fontSize: "38px",
                      textAlign: "initial",
                      color: "#ffffff",
                    }}
                  >
                    {general?.name_website} {i18next.t("home_title1")}
                  </p>
                  <p
                    style={{
                      fontSize: "38px",
                      color: "#ffffff",
                      textAlign: "initial",
                    }}
                  >
                    {i18next.t("home_title2")}
                  </p>
                  <form className="form-inline flex items-center">
                    <input
                      type="text"
                      className="form-control mb-2 mr-sm-2 reginput"
                      placeholder={i18next.t("enter_email_phone")}
                    />
                    <button
                      type="submit"
                      className=" mb-2 ml-2 regbtn"
                      onClick={() => navigate("/auth/login")}
                    >
                      {i18next.t("sign_up")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Banner;
