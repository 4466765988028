import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import HeaderDesktop from "_common/Layout/Header/HeaderDesktop";

import WithdrawMobile from "./Mobile";
import WithdrawDesktop from "./Desktop";

const WithdrawSubmit = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <WithdrawMobile />;
  return <WithdrawDesktop />;
});

export default WithdrawSubmit;
