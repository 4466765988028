import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import UserInfoMobile from "./Mobile";
import UserInfoDesktop from "./Desktop";

const UserInfoPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <UserInfoMobile />;
  return <UserInfoDesktop />;
});

export default UserInfoPage;
