import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { PriceApi } from "states/api/price";
import { useQuery } from "@tanstack/react-query";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import i18next from "i18next";

function getRandomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

const ListCoin = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  const [activeTab, setActiveTab] = useState("hot");

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 20,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [listCoins, setListCoin] = useState([]);

  const handelRandom = (item) => {
    const priceChangePercent = Number(item?.price?.priceChangePercent);
    const lastPrice = Number(item?.price?.lastPrice);
    const deltaPercent = Math.abs(priceChangePercent) * 0.01;
    const deltaPrice = Math.abs(lastPrice) * 0.002;

    const randomPercent = getRandomFloat(
      priceChangePercent - deltaPercent,
      priceChangePercent + deltaPercent
    );

    const randomPrice = getRandomFloat(
      lastPrice - deltaPrice,
      lastPrice + deltaPrice
    );

    return {
      ...item,
      price: {
        ...item?.price,
        priceChangePercent: randomPercent,
        lastPrice: randomPrice,
      },
    };
  };

  const handleConvertData = () => {
    const result = games?.map((item) => {
      const price = data?.find(
        (obj) => obj?.symbol?.toLowerCase() === item?.code
      );
      return handelRandom({
        ...item,
        symbol: price?.symbol,
        price: {
          ...price,
          priceChangePercent: item?.percent || price?.priceChangePercent,
          volume: item?.volume || price?.volume,
          lastPrice: item?.price || price?.lastPrice,
        },
      });
    });
    setListCoin(result);
  };

  useEffect(() => {
    handleConvertData();
    const interval = setInterval(() => {
      handleConvertData();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [games, data]);

  return (
    <div className="home-market">
      <div className="tabs tab-bin">
        <div
          className={classNames("tab", {
            active: activeTab === "hot",
          })}
          onClick={() => setActiveTab("hot")}
        >
          <span>{i18next.t("hot")}</span>
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "top",
          })}
          onClick={() => setActiveTab("top")}
        >
          <span>{i18next.t("top_gainer")}</span>
        </div>
      </div>
      <div className="coin-list pb-[1rem] mt-[1rem]">
        <div className="list">
          <div className="item header !static !transform-none">
            <div className="col1 text-[#707A8A]">{i18next.t("pair")}</div>
            <div className="col2 text-[#707A8A]">{i18next.t("last_price")}</div>
            <div className="col3 text-[#707A8A]">{i18next.t("change")}%</div>
          </div>
          <div className="emptybox" />
          {listCoins?.map((item) => (
            <Link
              className="item"
              to={`/market/detail?symbol=${item?.code}&name=${item?.name}`}
              key={item?.id}
            >
              <div className="col1 !flex-row items-center">
                <img
                  src={item?.image_url}
                  alt=""
                  width="20"
                  height="20"
                  className="mr-[10px]"
                />
                <div>
                  <p className="name">{item?.name}</p>
                  <p className="!text-[14px]">
                    24H {formatNumber(item?.volume)}
                  </p>
                </div>
              </div>
              <div className="col2">
                <span
                  className={classNames("price", {
                    "text-[#3db485]": item?.price?.priceChangePercent > 0,
                    "text-[#e6495c]": item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                </span>
              </div>
              <div className="col3">
                <span
                  className={classNames("tag ", {
                    green: item?.price?.priceChangePercent > 0,
                    red: item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
});

export default ListCoin;
