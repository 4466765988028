import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import ModalBet from "../Mobile/ModalBet";

const GameBoard = observer(
  ({ game_id, balanceMain, currentCoin, data_price }) => {
    const {
      authStore: { games, user },
    } = useStores();

    const [openModal, setOpenModal] = useState(false);
    const [game_type, setGameType] = useState();
    const [buyType, setType] = useState("market");
    const [priceBuy, setPriceBuy] = useState();
    const [priceSell, setPriceSell] = useState();
    const [amountBuy, setAmountBuy] = useState();
    const [amountSell, setAmountSell] = useState();

    useEffect(() => {
      setPriceBuy(data_price?.lastPrice);
      setPriceSell(data_price?.lastPrice);
    }, [data_price]);

    const handleBetGame = (type) => {
      setGameType(type);
      setOpenModal(true);
    };

    return (
      <div className="w-full h-[360px] border-t-[5px] border-[#151617]">
        <div className="w-full h-[360px]">
          <div className="contentbox !w-full !p-[0]">
            <div className="tradebox !w-full !p-[0]">
              <div className="flex justify-between items-center h-[37px] bg-[#1f1f1f] px-5">
                <div>
                  <span className="mr-[5px]">{i18next.t("available")}</span>
                  <b>{formatNumber(user?.balance, "0,0.[00000]")} USDT</b>
                </div>
                <div>
                  <span className="mr-[5px]">{i18next.t("available")}</span>{" "}
                  <b className="uppercase">
                    {formatNumber(balanceMain?.amount, "0,0.[00000]")}{" "}
                    {balanceMain?.symbol}
                  </b>
                </div>
              </div>
              <div className="formbox_op">
                <div
                  className={`formbox_op_list jy-btn ${
                    buyType === "limit" && "btn_bg_color"
                  }`}
                  onClick={() => setType("limit")}
                >
                  {i18next.t("limit")}
                </div>
                <div
                  className={`formbox_op_list jy-btn ${
                    buyType === "market" && "btn_bg_color"
                  }`}
                  onClick={() => setType("market")}
                >
                  {i18next.t("market")}
                </div>
              </div>
              <div className="tradebox_l !w-full !p-[0] flex">
                <div className="formbox max-w-[50%] px-[15px]">
                  <div className="input-item ">
                    <span className="label">{i18next.t("price")}</span>
                    <div className="input-group">
                      <input
                        type="number"
                        step={0.00000001}
                        inputMode="decimal"
                        placeholder={i18next.t("market_optimal_price")}
                        value={buyType === "limit" && priceBuy}
                        disabled={buyType === "market"}
                      />
                      <span>USDT</span>
                    </div>
                  </div>
                  <div className="input-item  mt-[15px]">
                    <span className="label">{i18next.t("amount")}</span>
                    <div className="input-group">
                      <input
                        type="number"
                        step={0.00000001}
                        inputMode="decimal"
                        placeholder={i18next.t("transaction_amount")}
                        value={amountBuy}
                        onChange={(e) => setAmountBuy(e?.target?.value)}
                      />
                      <span>{currentCoin?.currency}</span>
                    </div>
                  </div>
                  <div className="mt-[10px] text-[#c7cce6]">
                    {i18next.t("volume")}{" "}
                    {formatNumber(amountBuy * priceBuy, "0,0.[0000]")} USDT
                  </div>
                  <button
                    type="button"
                    onClick={() => handleBetGame("buy")}
                    className="w-full h-[36px] leading-[36px] text-white text-center !bg-[#35b65a] rounded-[5px] mt-[10px]"
                  >
                    <span className="f12 !text-white">
                      {i18next.t("buy")} {currentCoin?.currency}
                    </span>
                  </button>
                </div>
                <div className="formbox max-w-[50%] px-[15px]">
                  <div className="input-item ">
                    <span className="label">{i18next.t("price")}</span>
                    <div className="input-group">
                      <input
                        type="number"
                        step={0.00000001}
                        inputMode="decimal"
                        placeholder={i18next.t("market_optimal_price")}
                        value={buyType === "limit" && priceSell}
                        disabled={buyType === "market"}
                      />
                      <span>USDT</span>
                    </div>
                  </div>
                  <div className="input-item mt-[15px]">
                    <span className="label">{i18next.t("amount")}</span>
                    <div className="input-group">
                      <input
                        type="number"
                        step={0.00000001}
                        inputMode="decimal"
                        placeholder={i18next.t("transaction_amount")}
                        value={amountSell}
                        onChange={(e) => setAmountSell(e?.target?.value)}
                      />
                      <span>{currentCoin?.currency}</span>
                    </div>
                  </div>
                  <div className="mt-[10px] text-[#c7cce6]">
                    {i18next.t("volume")}{" "}
                    {formatNumber(amountSell * priceSell, "0,0.[0000]")} USDT
                  </div>
                  <button
                    type="button"
                    onClick={() => handleBetGame("sell")}
                    className="w-full h-[36px] leading-[36px] text-white text-center !bg-[#ff494a] [px-[15px] rounded-[5px] mt-[10px]"
                  >
                    <span className="f12 !text-white">
                      {i18next.t("sell")} {currentCoin?.currency}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openModal && (
          <ModalBet
            open={openModal}
            onClose={() => setOpenModal(false)}
            data={{
              symbol: balanceMain?.symbol,
              game_id,
              game_type,
              amount: game_type === "buy" ? amountBuy : amountSell,
            }}
          />
        )}
      </div>
    );
  }
);

export default GameBoard;
