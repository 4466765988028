import React, { useEffect, useState } from "react";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import classNames from "classnames";
import { gameBetItemType } from "_common/constants/statusType";
import moment from "moment";
import { get } from "lodash";
import { GamePlayerApi } from "states/api/gamePlayer";
import { useQuery } from "@tanstack/react-query";

export default function FeatureHistoryMobile() {
  const [status, setStatus] = useState("pending");

  const { data, refetch } = useQuery(
    ["game-player", "contract", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          type: "contract",
          status,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [status]);

  return (
    <div className="container page-history pt-[50px]">
      <HeaderTop title={i18next.t("future_order")} />
      <div className="tabs">
        <div
          className={classNames("tab", {
            active: status === "pending",
          })}
          onClick={() => setStatus("pending")}
        >
          {i18next.t("position")}
        </div>
        <div
          className={classNames("tab", {
            active: status === "open",
          })}
          onClick={() => setStatus("open")}
        >
          {i18next.t("open_order")}
        </div>
        <div
          className={classNames("tab", {
            active: status === "all",
          })}
          onClick={() => setStatus("all")}
        >
          {i18next.t("order_history")}
        </div>
      </div>
      <div className="list-history-data ">
        {get(data, "data", [])?.map((item) => (
          <div key={item.id} className="listbox">
            <div className="listbox_title items-center">
              <div className="listbox_title_l">
                <p className="text-[20px] uppercase">{item?.game?.name}</p>
              </div>
              <div className="listbox_title_r">
                <p className="text-[18px] leading-[50px] text-[500] uppercase">
                  {formatNumber(item?.lever)}X
                </p>
              </div>
            </div>
            <div className="w-full py-[5px] px-[12px] flex flex-wrap">
              <div className="flex justify-between h-[30px] items-center w-full">
                <span className="text-[14px]">{i18next.t("game_type")}</span>
                <span
                  className={classNames("text-[14px]", {
                    "text-[#f5465c]": item?.bet_game === "short",
                    "text-[#60c08c]": item?.bet_game === "long",
                  })}
                >
                  {i18next.t(item?.bet_game)}
                </span>
              </div>
              <div className="flex justify-between h-[30px] items-center w-full">
                <span className="text-[14px]">
                  {i18next.t("total_traded")}/{i18next.t("ordered_volume")}
                </span>
                <span className="text-[14px]">
                  {formatNumber(item?.amount, "0,0.[00000]")} /{" "}
                  {formatNumber(item?.amount, "0,0.[00000]")}
                </span>
              </div>
              <div className="flex justify-between h-[30px] items-center w-full">
                <span className="text-[14px]">
                  {i18next.t("open_price")}/{i18next.t("close_price")}
                </span>
                <span className="text-[14px]">
                  {formatNumber(item?.open, "0,0.[00000]")} /{" "}
                  {item?.close > 0
                    ? formatNumber(item?.close, "0,0.[00000]")
                    : "--"}
                </span>
              </div>
              <div className="flex justify-between h-[30px] items-center w-full">
                <span className="text-[14px]">{i18next.t("margin")}</span>
                <span className="text-[14px]">
                  {formatNumber(item?.total, "0,0.[0000]")}
                </span>
              </div>
              <div className="flex justify-between h-[30px] items-center w-full">
                <span className="text-[14px]">{i18next.t("fee")}</span>
                <span className="text-[14px]">
                  {formatNumber(item?.fee, "0,0.[00000]")}
                </span>
              </div>
              <div className="flex justify-between h-[30px] items-center w-full">
                <span className="text-[14px]">
                  {i18next.t("profit_and_loss")}
                </span>
                <span className="text-[14px]">
                  {item?.amount_payback > 0 &&
                    formatNumber(item?.amount_payback, "0,0.[0000]")}
                  {item?.amount_lose > 0 &&
                    `-${formatNumber(item?.amount_lose, "0,0.[0000]")}`}
                </span>
              </div>
              <div className="flex justify-between h-[30px] items-center w-full">
                <span className="text-[14px]">{i18next.t("time")}</span>
                <span className="text-[14px]">
                  {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>
              <div className="flex justify-between h-[30px] items-center w-full">
                <span className="text-[14px]">{i18next.t("status")}</span>
                <span className="text-[14px]">{i18next.t(item?.status)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
