import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";

const ModalBet = observer(({ open, onClose, data }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.optionPosition({
        params: {
          game_id: data.game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "contract"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        betGame({
          user_id: user?.id,
          time: variables?.type,
          game_id: res?.data?.id,
          ...variables,
        });
        setLoading(false);
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    try {
      if (!data?.amount) throw i18next.t("enter_large_amount_msg");
      if (!data?.game_type) throw i18next.t("select_game_type_msg");
      if (!data?.type) throw i18next.t("select_game_type_msg");

      onSubmit({
        final_total: data?.final_total,
        amount: data.amount,
        type: data?.type,
        mode: data?.mode,
        lever: data?.lever,
        bet_game: data?.game_type,
        price: data?.lastPrice,
        profit_price: data?.profit_price,
        loss_price: data?.loss_price,
      });
      return null;
    } catch (error) {
      setLoading(false);
      return NotificationManager.error(error);
    }
  };

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" />
        <div className="modal-content" style={{ width: "80%" }}>
          <div className="modal-body">
            <h5 className="text-center">{i18next.t("warm_tip")}</h5>
            <p className="text-center mt-[5px] !text-[#646566] !text-[14px]">
              {i18next.t("warn_contract_tip", {
                type: i18next.t(data?.game_type),
                lever: data?.lever,
                amount: data?.amount,
              })}
            </p>
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button
                type="button"
                className="btn-cancel"
                onClick={() => onClose()}
              >
                {i18next.t("cancel")}
              </button>
              <button
                type="button"
                className="btn-save"
                onClick={() => handleSubmit()}
              >
                <span className="text-blue">{i18next.t("confirm")} </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;
