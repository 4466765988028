import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import icon_margin from "assets/icons/icon_margin.svg";
import icon_contract from "assets/icons/icon_contract.svg";
import icon_etf from "assets/icons/icon_etf.svg";
import i18next from "i18next";

const News = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="news">
      <p className="why-title">{i18next.t("tit1")}</p>
      <ul className="news-item">
        <li>
          <p className="news-title">{i18next.t("tit2")}</p>
          <p className="news-desc">{i18next.t("tit3")}</p>
        </li>
        <li>
          <p className="news-title">{i18next.t("tit4")}</p>
          <p className="news-desc">{i18next.t("tit5")}</p>
        </li>
        <li>
          <p className="news-title">{i18next.t("tit6")}</p>
          <p className="news-desc">{i18next.t("tit7")}</p>
        </li>
      </ul>
    </div>
  );
});

export default News;
