import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";

export default function BodyCoin({ type, coinListPrice }) {
  const navigate = useNavigate();

  return (
    <div>
      {coinListPrice?.map((item) => (
        <div
          onClick={() => {
            navigate(`/market/detail?symbol=${item?.symbol}&type=${type}`);
          }}
          className="item !bg-[transparent]"
          key={item?.id}
        >
          <div className="col1">
            <div className="flex  items-center gap-2">
              <img src={item?.image_url} alt="" />
              <div>
                <p className="name">{item?.name}</p>
                <p className="!text-[14px]">24H {formatNumber(item?.volume)}</p>
              </div>
            </div>
          </div>
          <div className="col2 !justify-center">
            <span
              className={classNames("price", {
                "text-[#3db485]": item?.priceChangePercent > 0,
                "text-[#e6495c]": item?.priceChangePercent <= 0,
              })}
            >
              {formatNumber(item?.lastPrice, "0,0.[00000]")}
            </span>
          </div>
          <div className="col3">
            <span
              className={classNames("tag ", {
                green: item?.priceChangePercent > 0,
                red: item?.priceChangePercent <= 0,
              })}
            >
              {formatNumber(item?.priceChangePercent, "0,0.[00]")}%
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
