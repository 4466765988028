import React, { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { listTime } from "_common/constants/statusType";

export default function GameHeader({
  game_id,
  data_price,
  isLight,
  setIsLight,
  time,
}) {
  const navigate = useNavigate();
  const { dataGame } = useNotify();
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="trade-header mt-[15px]">
      <div className="price-info">
        <div className="col1">
          <h4
            className={classNames({
              "text-red": Number(data_price?.priceChangePercent) <= 0,
              "text-green": Number(data_price?.priceChangePercent) > 0,
            })}
          >
            {formatNumber(dataGame?.lastPrice, "0,0.[0000]")}
          </h4>
          <h5
            className={classNames({
              "text-red": Number(data_price?.priceChangePercent) <= 0,
              "text-green": Number(data_price?.priceChangePercent) > 0,
            })}
          >
            <span className="ml-2">
              {formatNumber(data_price?.priceChangePercent, "0,0.[00]")}%
            </span>
          </h5>
        </div>
        <div className="col2">
          <div className="!w-full flex">
            <p className="col1">{i18next.t("low")}</p>
            <h5 className="col2 justify-end">
              {formatNumber(data_price?.highPrice, "0,0.[00000]")}
            </h5>
          </div>
          <div className="!w-full flex">
            <p className="col1">{i18next.t("high")}</p>
            <h5 className="col2 justify-end">
              {formatNumber(data_price?.lowPrice, "0,0.[00000]")}
            </h5>
          </div>
          <div className="!w-full flex">
            <p className="col1">24H quantity</p>
            <h5 className="col2 justify-end">
              {formatNumber(data_price?.volume, "0,0.[0000]")}
            </h5>
          </div>
        </div>
      </div>
      <div className="chart-time">
        {listTime?.map((item) => (
          <button
            type="button"
            key={item.value}
            className={classNames("btn btn-time", {
              active: time === item?.value,
            })}
            onClick={() =>
              (window.location.href = `${window.location.pathname}?time=${
                item?.value
              }&symbol=${game_id}&theme=${isLight ? "light" : "dark"}`)
            }
          >
            {item.text}
          </button>
        ))}
      </div>
    </div>
  );
}
