import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import NoData from "_common/component/NoData";
import classNames from "classnames";
import { gameBetItemType } from "_common/constants/statusType";

export default function HistoryTrade({ status }) {
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const { dataGame } = useNotify();

  const { data, refetch } = useQuery(
    ["game-player", "spot"],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status: "open",
          type: "spot",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  if (!data?.data?.length) return <NoData />;

  return (
    <div className="mx-auto w-full">
      <div className="list-history-data ">
        {get(data, "data", [])?.map((item) => {
          const time = Number(get(dataGame?.[item?.id], "time", 0));
          const type = Number(get(item, "type", 360));
          const process = (time / type) * 100;
          return (
            <div key={item.id} className="listbox">
              <div className="w-full py-[5px] px-[12px] flex flex-wrap gap-1">
                <div className="flex gap-1 items-center  w-[48%]">
                  <span
                    className={classNames("text-[16px]", {
                      "text-[#f5465c]": item?.bet_game === "sell",
                      "text-[#60c08c]": item?.bet_game === "buy",
                    })}
                  >
                    {i18next.t(gameBetItemType[item?.bet_game])}
                  </span>
                  <span className="text-[16px] font-bold uppercase">
                    {item?.game?.name}
                  </span>
                </div>
                <div className="flex gap-1 items-center  w-[48%]">
                  <span className="fbv text-[14px]">{i18next.t("status")}</span>
                  <span className="text-[14px]">
                    {item?.status === "bet"
                      ? i18next.t("inprogress")
                      : i18next.t("complete")}
                  </span>
                </div>
                <div className="flex gap-1 items-center  w-[48%]">
                  <span className="fbv text-[14px]">
                    {i18next.t("average_price")}
                  </span>
                  <span className="text-[14px]">
                    {formatNumber(item?.open, "0,0.[00000]")}
                  </span>
                </div>
                <div className="flex gap-1 items-center  w-[48%]">
                  <span className="fbv text-[14px]">{i18next.t("type")}</span>
                  <span className="text-[14px]">{i18next.t(item?.mode)}</span>
                </div>
                <div className="flex gap-1 items-center  w-[48%]">
                  <span className="fbv text-[14px]">{i18next.t("amount")}</span>
                  <span className="text-[14px]">
                    {formatNumber(item?.amount, "0,0.[0000]")}
                  </span>
                </div>

                <div className="flex gap-1 items-center  w-[48%]">
                  <span className="fbv text-[14px]">{i18next.t("time")}</span>
                  <span className="text-[14px]">
                    {item?.created_at &&
                      moment(item?.created_at).format("MM-DD HH:mm:ss")}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
