import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";

import iconUser from "assets/img/icon-user.png";
import SelectLanguage from "_common/component/SelectLanguage";
import classNames from "classnames";

const HomeHeader = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  const [activeTab, setActiveTab] = useState("digital");

  return (
    <div className="no_headers header_box flex items-center justify-between">
      <div className="flex-1 pl-[1rem]">
        {authenticated ? (
          <Link to="/member" className="h-[50px] leading-[50px]">
            <img className="w-[30px] h-[30px]" src={iconUser} alt="" />
          </Link>
        ) : (
          <Link
            to="/auth/login"
            className="h-[50px] leading-[50px] font-bold text-[16px] text-gray-500"
          >
            {i18next.t("login")}
          </Link>
        )}
      </div>
      <div className="flex-1">
        {/*
        <div className="tabs">
          <div
            className={classNames("tab", {
              active: activeTab === "digital",
            })}
            onClick={() => setActiveTab("digital")}
          >
            {i18next.t("digital")}
          </div>
      <div
            className={classNames("tab", {
              active: activeTab === "forex",
            })}
            onClick={() => setActiveTab("forex")}
          >
            {i18next.t("forex")}
          </div>
        </div>
         */}
      </div>
      <div className="flex-1 pr-[1rem]">
        <SelectLanguage />
      </div>
    </div>
  );
});

export default HomeHeader;
