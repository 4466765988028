import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "_common/hooks";

import i18next from "i18next";
import { Link } from "react-router-dom";

const LoginComponent = observer(() => {
  const { authStore } = useStores();

  return (
    <div className="login-bg">
      <h2 className="text-center text-[20px] font-bold text-white">
        {i18next.t("welcome_to")}{" "}
        <span className="uppercase">{authStore?.general?.name_website}</span>
      </h2>
      <div className="login-box">
        <Link to="/auth/login" className="login">
          {i18next.t("login")}
        </Link>
        <Link to="/auth/login" className="register">
          {i18next.t("register")}
        </Link>
      </div>
    </div>
  );
});

export default LoginComponent;
