import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import NoData from "_common/component/NoData";
import classNames from "classnames";

import Item from "./Item";

export default function HistoryTrade({ data_price, setReportData }) {
  const navigate = useNavigate();
  const [activeHis, setActiveHis] = useState("open");

  const { data, refetch } = useQuery(
    ["game-player", "contract", activeHis],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status: activeHis,
          type: "contract",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [activeHis]);

  useEffect(() => {
    const result = { percent: 0, profit: 0, margin: 0 };
    get(data, "data", [])?.forEach((item) => {
      const lastPrice = Number(get(data_price, "lastPrice", 0));
      const open = Number(get(item, "open", 1));
      const amountLever = Number(get(item, "lever", 0));
      const amount = Number(get(item, "total", 0));
      const betGame = item?.bet_game;

      let percent = ((lastPrice - open) / open) * 100 * amountLever;

      if (betGame === "short") {
        percent = ((open - lastPrice) / open) * 100 * amountLever;
      }

      const amountPercent = (percent / 100) * amount;

      result.percent += percent;
      result.profit += amountPercent;
      result.margin += amount;
    });

    setReportData(result);
  }, [data?.data, data_price, setReportData]);

  return (
    <div className="mx-auto w-full">
      <div className="flex items-center justify-between relative">
        <div className="tabs tab-bin">
          <div
            className={classNames("tab", {
              active: activeHis === "open",
            })}
            onClick={() => setActiveHis("open")}
          >
            <span>
              {i18next.t("open_order")}({data?.data?.length})
            </span>
          </div>
          <div
            className={classNames("tab", {
              active: activeHis === "pending",
            })}
            onClick={() => setActiveHis("pending")}
          >
            <span>{i18next.t("position")}(0)</span>
          </div>
        </div>
        <Link
          to="/feature/order"
          className="btn-his text-[18px] text-[#b9b9b9] "
        >
          <FontAwesomeIcon icon={faFile} />
        </Link>
      </div>
      <div className="list-history-data ">
        {data?.data?.length > 0 ? (
          get(data, "data", [])?.map((item) => (
            <Item key={item?.id} data={item} data_price={data_price} />
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
