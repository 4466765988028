import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
  faClipboardList,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import classNames from "classnames";
import LeftNav from "pages/User/LeftNav";
import ListRecordDesktop from "pages/History/ListHistoryBalance/Desktop/ListRecord";

const fee = 0;

export default function WithdrawDesktop() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { pushNotify } = useNotify();

  const schema = yup
    .object({
      address: yup.string(),
      amount: yup.number().required(i18next.t("verify_require")),
    })
    .required();

  const symbol = params.get("symbol");
  const network = params.get("network") || "erc20";

  const {
    authStore: { user, general, coins, address_select },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const amount = watch("amount");

  useEffect(() => {
    if (!address_select?.address) return;
    setValue("address", address_select?.address);
  }, [address_select]);

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  const mainBalance = useMemo(
    () => user?.tokens?.find((obj) => obj?.symbol === symbol),
    [user, symbol]
  );

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({
        params: {
          ...variables,
          symbol,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        pushNotify({
          type: "withdraw",
        });
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    // if (!bank_default?.id)
    //   return NotificationManager.error("Vui lòng liên kết tài khoản ngân hàng");

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_withdraw
        )}`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_withdraw
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div className="bg-[#f8f8f8]">
              <div className="title">
                <h3>{i18next.t("Withdraw")}</h3>
              </div>
              <div id="withdraw" className="px-[15px]">
                <div className="flex justify-between items-center h-[50px] bg-[#f8f8f8] px-[30px]">
                  <div className="line_1">
                    <span className="text-[20px] font-bold">
                      {mainCoin?.name}
                    </span>
                  </div>
                  <div
                    className="flex items-center"
                    onClick={() => navigate("/member/withdraw")}
                  >
                    <span className="font-bold text-[#5c5c5c] mr-[5px]">
                      {i18next.t("select_currency")}
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
                  </div>
                </div>
                <div className="bg-white">
                  <div className="w-[472px] mx-auto ">
                    <div className="imgbox">
                      {symbol === "usdt" && (
                        <>
                          <p className="text-[15px] mb-[5px] text-[#1e1e1e] font-bold">
                            {i18next.t("chain_name")}
                          </p>
                          <div className="flex  gap-2">
                            <div
                              className={classNames(
                                "imgbox_3 flex-1 !bg-[#f8f8f8]",
                                {
                                  showQrb: !network || network === "erc20",
                                }
                              )}
                              onClick={() =>
                                navigate(
                                  "/member/withdraw/submit?symbol=usdt&network=erc20",
                                  {
                                    replace: true,
                                  }
                                )
                              }
                            >
                              <span className="fzmm">ERC20</span>
                            </div>
                            <div
                              className={classNames(
                                "imgbox_3 flex-1 !bg-[#f8f8f8]",
                                {
                                  showQrb: network === "trc20",
                                }
                              )}
                              onClick={() =>
                                navigate(
                                  "/member/withdraw/submit?symbol=usdt&network=trc20",
                                  {
                                    replace: true,
                                  }
                                )
                              }
                            >
                              <span className="fzmm">TRC20</span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <form
                      className="imgbox !mt-0"
                      onSubmit={handleSubmit(onSave)}
                    >
                      <div className="imgbox_4 h-[500px] !border-[0] !mt-[0]">
                        <div className="imgbox_1  mt-[10px] flex justify-between">
                          <div className="h-[40px] leading-[40px]">
                            <span className="fzmm fe6im">
                              {i18next.t("address")}
                            </span>
                          </div>
                        </div>
                        <div className="tx_inputbox">
                          <div className="tx_inputbox_l">
                            <input
                              type="text"
                              autoComplete="off"
                              className="w-full h-[30px]"
                              placeholder={i18next.t("enter_address")}
                              {...register("address")}
                            />
                          </div>
                          <div className="tx_inputbox_r">
                            <FontAwesomeIcon
                              onClick={() =>
                                navigate("/member/wallet?type=select")
                              }
                              className="text-[20px] text-[#4e4e4e]"
                              icon={faAddressBook}
                            />
                          </div>
                        </div>

                        <div className="imgbox_1  mt-[10px] flex justify-between">
                          <div className="h-[40px] leading-[40px]">
                            <span className="fzmm fe6im">
                              {i18next.t("withdraw_amount")}
                            </span>
                          </div>
                        </div>
                        <div className="tx_inputbox">
                          <div className="tx_inputbox_l !w-[60%]">
                            <input
                              type="number"
                              inputMode="decimal"
                              min={0}
                              step={0.00000001}
                              autoComplete="off"
                              className="w-full h-[30px]"
                              placeholder={i18next.t("Withdrawal Amount")}
                              {...register("amount")}
                            />
                          </div>
                          <div className="tx_inputbox_r !w-[40%]">
                            <div className="inputbox_r_r !w-full !text-right">
                              <span className="text-[#888] font-bold !text-[14px]">
                                {mainCoin?.name} |{" "}
                                <span
                                  className="text-black"
                                  onClick={() =>
                                    setValue(
                                      "amount",
                                      Number(mainBalance?.amount)
                                    )
                                  }
                                >
                                  {i18next.t("all")}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between text-[#888] text-[12px] mt-[5px]">
                          <span>
                            {i18next.t("available")}{" "}
                            {formatNumber(mainBalance?.amount, "0,0.[00000]")}{" "}
                            {mainCoin?.name}
                          </span>
                          <span>
                            {i18next.t("min")}{" "}
                            {formatNumber(general?.min_deposit)}{" "}
                            {mainCoin?.name}
                          </span>
                        </div>
                        <div className="imgbox_1  mt-[10px] flex justify-between">
                          <div className="h-[40px] leading-[40px]">
                            <span className="fzmm fe6im">
                              {i18next.t("fee")}
                            </span>
                          </div>
                        </div>
                        <div className="tx_inputbox !w-full">
                          <div className="tx_inputbox_l">
                            <input
                              type="text"
                              autoComplete="off"
                              className="w-full h-[30px]"
                              value={formatNumber(amount * fee, "0,0.[0000]")}
                              placeholder={i18next.t("fee")}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between text-[#888] text-[16px] font-bold mt-[15px]">
                          <span>{i18next.t("arrival_quantity")}</span>
                          <span>
                            {formatNumber(amount * (1 - fee), "0,0.[00000]")}
                          </span>
                        </div>
                        <div className="imgbox_1 mt-[10px]">
                          <span className="fzmm fe6im">
                            {i18next.t("fund_password")}
                          </span>
                        </div>
                        <div className="tx_inputbox">
                          <div className="tx_inputbox_l">
                            <input
                              type="password"
                              placeholder={i18next.t("fund_password")}
                              className="w-full h-[30px] text-[#707a8a]"
                              {...register("wallet_password")}
                            />
                          </div>
                        </div>
                        <div className="imgbox_8 !h-[unset] mt-[20px]">
                          <div className="imgbox_10 !bg-[#FBC501]" id="sumbtn">
                            <button
                              type="submit"
                              className="fzmm uppercase"
                              disabled={!isValid}
                            >
                              {i18next.t("withdraw")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mt-[20px]">
                  <div className="recharge-record">
                    <div className="flex justify-between items-center py-[10px]">
                      <h5 className="text-[#989898] font-bold">
                        {i18next.t("withdraw_records")}
                      </h5>
                      <Link to="/board/history?type=withdraw" className="link">
                        {i18next.t("all_record")}
                      </Link>
                    </div>
                    <div className="mt-[10px]">
                      <ListRecordDesktop type="withdraw" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
