import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import i18next from "i18next";

export default function Withdraw() {
  const navigate = useNavigate();
  const {
    authStore: { tokens, banks, setBankDeposit },
  } = useStores();

  const [rechargeType, setRechargeType] = useState(2);

  return (
    <div className="container page-deposit">
      <HeaderTop title={i18next.t("select_currency")} />
      <div id="deposit" className="pt-[50px] p-[10px]">
        {tokens?.map((item) => (
          <Link to={`/member/withdraw/submit?symbol=${item?.symbol}`}>
            <div className="lmcoin">
              <span className=" text-[16px] font-bold">{item?.name}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
