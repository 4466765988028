import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import UploadFile from "_common/component/UploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import QRCode from "react-qr-code";
import classNames from "classnames";
import LeftNav from "pages/User/LeftNav";
import ListRecordDesktop from "pages/History/ListHistoryBalance/Desktop/ListRecord";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
  })
  .required();

export default function DepositDesktop() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { pushNotify } = useNotify();

  const symbol = params.get("symbol");
  const network = params.get("network");

  const [address, setAddress] = useState();
  const [back_images, setBackImage] = useState();

  const {
    authStore: { user, general, banks, game_wallet, coins },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  useEffect(() => {
    if (!mainCoin) return;
    if (symbol === "usdt") {
      if (network === "trc20") {
        setAddress(mainCoin?.trc20_address);
      } else if (network === "erc20") {
        setAddress(mainCoin?.erc20_address);
      } else {
        setAddress(mainCoin?.primary_address);
      }
    } else {
      setAddress(mainCoin?.primary_address);
    }
  }, [mainCoin, symbol, network]);

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          symbol,
          receive_address: address,
          network,
          payment_image: back_images,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        setBackImage();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
        pushNotify({
          type: "deposit",
        });
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_deposit
        )}`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_deposit
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  const onReceiveBackImages = (images) => {
    setBackImage(images[0]?.url);
  };

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div className="bg-[#f8f8f8]">
              <div className="title">
                <h3>{i18next.t("Deposit")}</h3>
              </div>
              <div id="withdraw">
                <div className="flex justify-between items-center h-[50px] bg-[#f8f8f8] px-[30px]">
                  <div className="line_1">
                    <span className="text-[20px] font-bold">
                      {mainCoin?.name}
                    </span>
                  </div>
                  <div
                    className="flex items-center"
                    onClick={() => navigate("/member/recharge")}
                  >
                    <span className="font-bold text-[#5c5c5c] mr-[5px]">
                      {i18next.t("select_currency")}
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
                  </div>
                </div>
                <div className="imgbox !py-[30px]">
                  {symbol === "usdt" && (
                    <div className="w-[472px] mx-auto">
                      <p className="text-[15px] mb-[5px] text-[#989898] font-bold">
                        {i18next.t("chain_name")}
                      </p>
                      <div className="flex gap-2">
                        <div
                          className={classNames(
                            "imgbox_3 flex-1 !bg-[#f8f8f8]",
                            {
                              showQrb: !network || network === "erc20",
                            }
                          )}
                          onClick={() =>
                            navigate(
                              "/member/deposit?symbol=usdt&network=erc20",
                              {
                                replace: true,
                              }
                            )
                          }
                        >
                          <span className="font-bold">ERC20</span>
                        </div>
                        <div
                          className={classNames(
                            "imgbox_3 flex-1 !bg-[#f8f8f8]",
                            {
                              showQrb: network === "trc20",
                            }
                          )}
                          onClick={() =>
                            navigate(
                              "/member/deposit?symbol=usdt&network=trc20",
                              {
                                replace: true,
                              }
                            )
                          }
                        >
                          <span className="font-bold">TRC20</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className=" mt-[30px]">
                    <div className="!h-[200px] flex justify-center">
                      <div className="wallet-qr w-[200px] h-[200px]">
                        {address && (
                          <QRCode
                            size={256}
                            viewBox="0 0 256 256"
                            className="w-[180px] h-[180px]"
                            value={address}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="imgbox_4 address">
                    <div className="w-fit mx-auto bg-[#f6f6f6] rounded-[20px] px-[20px]">
                      <div className="flex items-center justify-center gap-2">
                        <span className="text-[#c1c1c1]">
                          {i18next.t("deposit_address")}
                        </span>
                        <span className="text-[#424344] text-[14px] font-bold text-center">
                          {address}
                        </span>
                        <div className="text-center py-[10px]">
                          <span
                            className="link px-[5px] py-[2px] text-[12px]"
                            onClick={() => handleCopyLink(address)}
                          >
                            {i18next.t("copy")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-[30px] py-[10px]">
                  <div className="deposit-tip">
                    <p className="text-[#888] font-[500] text-[12px] mb-[10px]">
                      {i18next.t("deposit_title")}
                    </p>
                    <ul className="text-[#888] font-[500] text-[12px]">
                      <li>
                        {i18next.t("deposit_tip1", {
                          symbol: symbol?.toUpperCase(),
                        })}
                      </li>
                      <li>{i18next.t("deposit_tip2")}</li>
                      <li>{i18next.t("deposit_tip3")}</li>
                      <li>{i18next.t("deposit_tip4")}</li>
                      <li>{i18next.t("deposit_tip5")}</li>
                    </ul>
                  </div>
                </div>

                <form className="imgbox" onSubmit={handleSubmit(onSave)}>
                  <div className="!w-[427px] mx-auto">
                    <div className="imgbox_4 h-[200px] !border-[0] !mt-[0]">
                      <div className="imgbox_5">
                        <span className="fzmm fe6im">
                          {i18next.t("deposit_amount")}
                        </span>
                      </div>
                      <div className="zz_inputbox !bg-[#f6f6f6] !px-[10px]">
                        <input
                          type="number"
                          inputMode="decimal"
                          min={0}
                          step={0.00000001}
                          className="zz_input"
                          {...register("amount")}
                        />
                        <span className="text-[#A5A5A5]">{mainCoin?.name}</span>
                      </div>
                      <div className="imgbox_8 mt-[20px]">
                        <div className="imgbox_10" id="sumbtn">
                          <button
                            type="submit"
                            className="fzmm w-full uppercase"
                            disabled={!isValid}
                          >
                            {i18next.t("deposit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="mt-[20px]">
                  <div className="recharge-record">
                    <div className="flex justify-between items-center py-[10px]">
                      <h5 className="text-[#989898] font-bold">
                        Deposit Records
                      </h5>
                      <Link to="/board/history?type=deposit" className="link">
                        All records
                      </Link>
                    </div>
                    <div className="mt-[10px]">
                      <ListRecordDesktop type="deposit" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
