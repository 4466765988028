import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import { formatNumber } from "_common/utils/formatValue";
import { get } from "lodash";
import { rateList } from "_common/constants/statusType";

import ModalBet from "../Mobile/ModalBet";

const GameBoard = observer(({ game_id, data_price }) => {
  const {
    authStore: { user },
  } = useStores();

  const [last_price, setLastPrice] = useState(get(data_price, "lastPrice", 0));
  const [game_type, setGameType] = useState("buy");
  const [bet_type, setBetType] = useState(30);
  const [amount, setAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleChangeInput = (event) => {
    if (!event?.target?.value) {
      setAmount();
    } else {
      setAmount(Number(event?.target?.value));
    }
  };

  useEffect(() => {
    setLastPrice(Number(get(data_price, "lastPrice", 0)));
  }, [data_price]);

  return (
    <div className="w-full ">
      <div className="w-full">
        <p className="text-[12px]">
          <span className="text-[#bdbdbd]">{i18next.t("available")}</span>
          <b className="text-[#fbbc01] ml-[5px]">
            {formatNumber(user?.balance_option, "0,0.[00000]")} USDT
          </b>
        </p>
        <div className="cus-select mt-[10px]">
          <select
            value={bet_type}
            onChange={(e) => setBetType(e?.target?.value)}
          >
            {rateList?.map((item) => (
              <option
                value={item?.value}
                onClick={() => setBetType(item?.value)}
              >
                {item?.value}s [{item?.rate}%]
              </option>
            ))}
          </select>
        </div>

        <div className="tradebox_l !w-full !p-[0]">
          <div className="formbox">
            <div className="input-item  mt-[15px]">
              <div className="input-group">
                <input
                  type="number"
                  inputMode="decimal"
                  placeholder="Volume"
                  step={0.00000001}
                  value={amount}
                  onChange={handleChangeInput}
                />
                <span onClick={() => setAmount(Number(user?.balance_option))}>
                  {i18next.t("all")}
                </span>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setGameType("buy");
                setOpenModal(true);
              }}
              className="w-full h-[36px] leading-[36px] text-white text-center !bg-[#01bc9b] rounded-[20px] mt-[10px]"
            >
              <span className="f12 !text-white">{i18next.t("rise")}</span>
            </button>
            <button
              type="button"
              onClick={() => {
                setGameType("sell");
                setOpenModal(true);
              }}
              className="w-full h-[36px] leading-[36px] text-white text-center !bg-[#e54b4b] rounded-[20px] mt-[10px]"
            >
              <span className="f12 !text-white">{i18next.t("fall")}</span>
            </button>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            game_id,
            type: "feature",
            mode: bet_type,
            game_type,
            amount,
            lastPrice: last_price,
          }}
        />
      )}
    </div>
  );
});

export default GameBoard;
