import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { listTime } from "_common/constants/statusType";

export default function GameChart({ game_id, isLight }) {
  const {
    authStore: { trading_view },
  } = useStores();
  const [time, setTime] = useState(listTime[1]?.value);

  return (
    <div className="w-full h-[550px] bg-[#1a1b1c] p-[5px]">
      <div className="w-full h-full p-[8px]">
        <div className="changInterval flex">
          {listTime?.map((item) => (
            <div
              key={item?.value}
              className={classNames("interval-item fcf", {
                active: time === item?.value,
              })}
              onClick={() => setTime(item?.value)}
            >
              {item.text}
            </div>
          ))}
        </div>
        <div id="highcharts">
          <iframe
            title="chart"
            src={`${trading_view}/v4?symbol=${game_id}&color=rgb(26,27,28)&theme=${
              isLight ? "light" : "dark"
            }&type=${time}`}
            width="100%"
            height="100%"
            style={{ height: 500 }}
          />
        </div>
      </div>
    </div>
  );
}
