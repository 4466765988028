import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "_common/hooks";

import { observer } from "mobx-react-lite";
import { Portal } from "react-portal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { PaymentApi } from "states/api/payment";
import i18next from "i18next";
import IncomeBreakdown from "./IncomeBreakdown";

const ModalFundProfit = observer(({ open, onClose, fund_id }) => {
  const navgate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const { data, refetch } = useQuery(
    ["funds", "profit"],
    () =>
      PaymentApi.getList({
        params: {
          page: 1,
          limit: 50,
          object_id: fund_id,
          type: "fund",
          mode: "profit",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" onClick={() => onClose()} />
        <div className="modal-content" style={{ width: "80%" }}>
          <div className="modal-header">
            <h5>{i18next.t("income_breakdown")}</h5>
            <span className="btn-close" onClick={() => onClose()}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
          <div className="modal-body !p-[20px]">
            <IncomeBreakdown fund_id={fund_id} />
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalFundProfit;
