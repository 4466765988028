import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";

function HeaderTop({
  title,
  position = "",
  isBack = true,
  theme = "dark",
  right,
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, game_wallet },
  } = useStores();

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div
      className={classNames("bg_header", {
        bg_light: theme === "light",
      })}
    >
      {isBack && (
        <span className="btn_back" onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faAngleLeft}
            color="#707A8A"
            className="w-[20px] h-[20px]"
          />
        </span>
      )}
      <h2
        className={classNames("bg_header_name text-[18px] font-bold", {
          [position]: true,
        })}
      >
        {title}
      </h2>
      {right && <div className="right_rf">{right}</div>}
    </div>
  );
}

export default HeaderTop;
