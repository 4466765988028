import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

import Pagination from "_common/component/Pagination";
import { PaymentApi } from "states/api/payment";
import i18next from "i18next";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";
import { paymentStatusColor, walletTypes } from "_common/constants/statusType";
import NoData from "_common/component/NoData";

export default function ListRecord() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { data, refetch } = useQuery(
    ["payment", "list"],
    () =>
      PaymentApi.getList({
        params: {
          page: 1,
          limit: 20,
          type: "invite",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <table className="table-coin">
      <thead>
        <tr>
          <th>
            <div className="cell">{i18next.t("account_of_invitee")}</div>
          </th>
          <th>
            <div className="cell">{i18next.t("transaction_amount")}(USDT)</div>
          </th>
          <th>
            <div className="cell">{i18next.t("rebate_amount")}(USDT)</div>
          </th>
          <th>
            <div className="cell">{i18next.t("contributors")}</div>
          </th>
          <th>
            <div className="cell">{i18next.t("contribution_amount")}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.data?.map((item) => (
          <tr key={item?.symbol}>
            <td>
              <div className="cell">
                {item?.created_at &&
                  moment(item?.created_at).format("HH:mm:ss DD/MM/YYYY")}
              </div>
            </td>
            <td>
              <div className="cell">
                <span
                  className={classNames("font-bold", {
                    [paymentStatusColor[item?.status]]: true,
                  })}
                >
                  {i18next.t(item?.status)}
                </span>
              </div>
            </td>
            <td>
              <div className="cell font-bold">{item?.token?.name}</div>
            </td>
            <td>
              <div className="cell">
                {formatNumber(item?.final_total, "0,0.[000000]")}
              </div>
            </td>
            <td>
              <div className="cell">
                {i18next.t(walletTypes[item?.from])} {">> "}
                {i18next.t(walletTypes[item?.to])}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
