import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { useNavigate } from "react-router-dom";
import NoData from "_common/component/NoData";
import classNames from "classnames";
import Item from "./Item";

export default function HistoryTrade({ data_price }) {
  const navigate = useNavigate();
  const [activeHis, setActiveHis] = useState("bet");

  const { data, refetch } = useQuery(
    ["game-player", "feature", activeHis],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status: activeHis,
          type: "feature",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [activeHis]);

  return (
    <div className="mx-auto w-full">
      <div className="flex items-center justify-between relative">
        <div className="tabs tab-bin">
          <div
            className={classNames("tab", {
              active: activeHis === "bet",
            })}
            onClick={() => setActiveHis("bet")}
          >
            <span>{i18next.t("open_order")}</span>
          </div>
          <div
            className={classNames("tab", {
              active: activeHis === "all",
            })}
            onClick={() => setActiveHis("all")}
          >
            <span>{i18next.t("transaction_record")}</span>
          </div>
        </div>
      </div>
      <div className="list-history-data ">
        {data?.data?.length > 0 ? (
          get(data, "data", [])?.map((item) => (
            <Item key={item?.id} data={item} data_price={data_price} />
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
