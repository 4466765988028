import React from "react";
import { Routes, Route } from "react-router-dom";

import DigitalLayout from "_common/Layout/DigitalLayout";
import NoFooterLayout from "_common/Layout/NoFooterLayout";
import UnAuthLayout from "_common/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import LoginPage from "pages/Auth/Login";
import RegisterPage from "pages/Auth/Register";

import { MiddlewareRouter } from "routers/MiddlewareRouter";
import CheckAuth from "routers/CheckAuth";
import MemberPage from "pages/Member";
import Deposit from "pages/Wallet/Deposit";

import HomeUnAuth from "pages/HomeUnAuth";

import Withdraw from "pages/Wallet/Withdraw/Withdraw";
import Recharge from "pages/Wallet/Deposit/Recharge";
import KYC from "pages/User/KYC/KYC";
import UpdateUser from "pages/User/UpdateUser";
import Security from "pages/Member/Security";
import WithdrawSubmit from "pages/Wallet/Withdraw";
import ShareLink from "pages/User/ShareLink";

import FeaturePage from "pages/Feature";
import SpotTradePage from "pages/SpotTrade";
import KYCFirst from "pages/User/KYC/KYCFirst";
import AddBankCard from "pages/Wallet/Bank/AddBankCard";
import CheckAuthHome from "routers/CheckAuthHome";
import UserInfoPage from "pages/User/UserInfo";
import Language from "pages/Member/Language";
import FundPage from "pages/Fund";
import FundDetail from "pages/Fund/FundDetail";
import FundHistory from "pages/Fund/FundHistory";
import AddBankForm from "pages/Wallet/Bank/AddBankForm";
import HistoryFeaturePage from "pages/History/FeaturePage";

import WalletAddress from "pages/Wallet/WalletAddress";
import ContractPage from "pages/Contract";

import Transfer from "pages/Wallet/Transfer";
import CSKH from "pages/CSKH";
import HistorySpotPage from "pages/History/SpotPage";
import MarketDetailPage from "pages/MarketDetail";
import MarketPage from "pages/Market";
import BalancePage from "pages/Wallet/Balance";
import ListHistoryBalance from "pages/History/ListHistoryBalance";
import WalletAddressAddAdd from "pages/Wallet/WalletAddressAdd";
import HistoryContractPage from "pages/History/ContractPage";
import ChangePasswordPage from "pages/User/ChangePassword";
import ChangeWalletPasswordPage from "pages/User/ChangeWalletPassword";
import FundHistoryDetail from "pages/Fund/FundHistory/FundHistoryDetail";

export function Routers() {
  return (
    <Routes>
      <Route
        path="/cskh"
        element={
          <CheckAuthHome>
            <UnAuthLayout />
          </CheckAuthHome>
        }
      >
        <Route index element={<CSKH />} />
      </Route>

      <Route path="/" element={<DigitalLayout />}>
        <Route index element={<HomeUnAuth />} />
        <Route path="home" element={<HomeUnAuth />} />
        <Route path="market">
          <Route index element={<MarketPage />} />
          <Route path="detail" element={<MarketDetailPage />} />
        </Route>
        <Route path="contract">
          <Route index element={<ContractPage />} />
          <Route path="order" element={<HistoryContractPage />} />
        </Route>
        <Route path="spot">
          <Route index element={<SpotTradePage />} />
          <Route path="order" element={<HistorySpotPage />} />
        </Route>
        <Route path="feature">
          <Route index element={<FeaturePage />} />
          <Route path="order" element={<HistoryFeaturePage />} />
        </Route>
      </Route>

      <Route
        path="/board"
        element={
          <MiddlewareRouter>
            <DigitalLayout />
          </MiddlewareRouter>
        }
      >
        <Route path="home" element={<HomeUnAuth />} />
        <Route path="wallet" element={<BalancePage />} />
        <Route path="fund">
          <Route index element={<FundPage />} />
          <Route path="list" element={<FundPage />} />
          <Route path="history" element={<FundHistory />} />
          <Route path="history/:id" element={<FundHistoryDetail />} />
          <Route path="detail/:id" element={<FundDetail />} />
        </Route>
        <Route path="history" element={<ListHistoryBalance />} />
        <Route path="histories/balance" element={<ListHistoryBalance />} />
        <Route path="spot" element={<SpotTradePage />} />
      </Route>

      <Route
        path="/member"
        element={
          <MiddlewareRouter>
            <NoFooterLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<MemberPage />} />
        <Route path="info" element={<UserInfoPage />} />
        <Route path="kyc" element={<KYC />} />
        <Route path="kyc/first" element={<KYCFirst />} />
        <Route path="update-profile" element={<UpdateUser />} />
        <Route path="sharing" element={<ShareLink />} />
        <Route path="bank" element={<AddBankCard />} />
        <Route path="bank/submit" element={<AddBankForm />} />
        <Route path="wallet" element={<WalletAddress />} />
        <Route path="add-wallet" element={<WalletAddressAddAdd />} />
        <Route path="language" element={<Language />} />
        <Route path="security" element={<Security />} />
        <Route path="password" element={<ChangePasswordPage />} />
        <Route path="wallet-password" element={<ChangeWalletPasswordPage />} />
        <Route path="recharge" element={<Recharge />} />
        <Route path="transfer" element={<Transfer />} />
        <Route path="deposit" element={<Deposit />} />
        <Route path="withdraw">
          <Route index element={<Withdraw />} />
          <Route path="submit" element={<WithdrawSubmit />} />
        </Route>
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
