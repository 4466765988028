import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

import Pagination from "_common/component/Pagination";
import { PaymentApi } from "states/api/payment";
import i18next from "i18next";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";
import { paymentStatusColor, walletTypes } from "_common/constants/statusType";
import NoData from "_common/component/NoData";
import { UserWalletApi } from "states/api/wallet";
import { NotificationManager } from "react-notifications";

export default function ListAddress() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { setAddressSelect },
  } = useStores();

  const [params] = useSearchParams();

  const type = params?.get("type");

  const { data, refetch } = useQuery(
    ["user_wallet", "list"],
    () =>
      UserWalletApi.getList({
        params: {
          page: 1,
          limit: 20,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const { mutate: onDelete, isLoading } = useMutation(
    (id) =>
      UserWalletApi.delete({
        id,
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["user_wallet", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("add_success")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  if (!data?.data?.length) return <NoData />;

  return (
    <table className="table-coin">
      <thead>
        <tr>
          <th>
            <div className="cell">{i18next.t("coin")}</div>
          </th>
          <th>
            <div className="cell">{i18next.t("address")}</div>
          </th>
          <th>
            <div className="cell">{i18next.t("remarks")}</div>
          </th>
          <th>
            <div className="cell">{i18next.t("operation")}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.data?.map((item) => (
          <tr key={item?.symbol}>
            <td>
              <div className="cell font-bold uppercase">{item?.symbol}</div>
            </td>
            <td>
              <div className="cell">{item?.address}</div>
            </td>
            <td>
              <div className="cell">{item?.note}</div>
            </td>
            <td>
              <div className="cell">
                {type === "select" ? (
                  <button
                    type="button"
                    className="btn-asset change"
                    onClick={() => {
                      if (type === "select") {
                        setAddressSelect(item);
                        navigate(-1);
                      }
                    }}
                  >
                    {i18next.t("select")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-asset change"
                    onClick={() => onDelete(item?.id)}
                  >
                    {i18next.t("delete")}
                  </button>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
