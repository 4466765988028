import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faGear,
  faJournalWhills,
  faRectangleList,
  faShield,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";

import icon33 from "assets/img/icon/icon33.png";
import icon34 from "assets/img/icon/icon34.png";
import icon35 from "assets/img/icon/icon35.png";
import icon36 from "assets/img/icon/icon36.png";
import icon37 from "assets/img/icon/icon37.png";
import icon38 from "assets/img/icon/icon38.png";
import icon39 from "assets/img/icon/icon39.png";

const LeftNav = observer(() => {
  const {
    authStore: { clear },
  } = useStores();
  const location = useLocation();
  const pathName = location?.pathname;

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  return (
    <div className="person-left">
      <div className="slidebar">
        <div>
          <div className="title">
            <img src={icon33} alt="" />
            <span>{i18next.t("general")}</span>
          </div>
          <div className="namebox">
            <Link to="/member" className="router-link active">
              {i18next.t("account")}
            </Link>
            <Link to="/member/sharing" className="router-link">
              {i18next.t("invite")}
            </Link>
          </div>
        </div>
        <div>
          <div className="title">
            <img src={icon34} alt="" />
            <span>{i18next.t("financial")}</span>
          </div>
          <div className="namebox">
            <Link to="/board/wallet" className="router-link">
              {i18next.t("balance")}
            </Link>
            <Link to="/member/deposit?symbol=usdt" className="router-link">
              {i18next.t("deposit")}
            </Link>
            <Link
              to="/member/withdraw/submit?symbol=usdt"
              className="router-link"
            >
              {i18next.t("withdraw")}
            </Link>
            <Link to="/board/history" className="router-link">
              {i18next.t("record")}
            </Link>
          </div>
        </div>
        <div>
          <div className="title">
            <img src={icon35} alt="" />
            <span>{i18next.t("order")}</span>
          </div>
          <div className="namebox">
            <Link to="/spot/order?type=open" className="router-link">
              {i18next.t("open_orders")}
            </Link>
            <Link to="/spot/order?type=all" className="router-link">
              {i18next.t("order_history")}
            </Link>
          </div>
        </div>
        <div>
          <div className="title">
            <img src={icon36} alt="" />
            <span>{i18next.t("futures")}</span>
          </div>
          <div className="namebox">
            <Link to="/feature/order?type=pending" className="router-link">
              {i18next.t("position")}
            </Link>
            <Link to="/feature/order?type=open" className="router-link">
              {i18next.t("open_orders")}
            </Link>
            <Link to="/feature/order?type=all" className="router-link">
              {i18next.t("order_history")}
            </Link>
          </div>
        </div>
        <div>
          <div className="title">
            <img src={icon38} alt="" />
            <span>{i18next.t("options")}</span>
          </div>
          <div className="namebox">
            <Link to="/contract/order?type=pending" className="router-link">
              {i18next.t("position")}
            </Link>
            <Link to="/contract/order?type=open" className="router-link">
              {i18next.t("open_orders")}
            </Link>
            <Link to="/contract/order?type=all" className="router-link">
              {i18next.t("order_history")}
            </Link>
          </div>
        </div>
        <div>
          <div className="namebox">
            <div className="router-link" onClick={() => onLogout()}>
              {i18next.t("logout")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LeftNav;
