import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faRotate } from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import LeftNav from "../LeftNav";

const UserInfoDesktop = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, game_wallet, language, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div className="bg-[#f8f8f8]">
              <div className="title">
                <h3>{i18next.t("settings_center")}</h3>
              </div>
              <div id="container_main" className="user-info ">
                <div className="memberForm !w-[427px] mx-auto">
                  <ul className="memberMenu">
                    <li>
                      <Link to="/member/update-profile">
                        <div>
                          <span>{i18next.t("user_name")}</span>
                        </div>
                        <div>
                          <span>{user?.full_name}</span>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="angle-right"
                          />
                        </div>
                      </Link>
                    </li>
                    <li>
                      <div className="link">
                        <div>
                          <span>{i18next.t("account")}</span>
                        </div>
                        <div>
                          <span>{user?.username}</span>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="angle-right"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="memberForm">
                  <ul className="memberMenu">
                    <li>
                      <Link to="/member/wallet-password">
                        <div>
                          <span>{i18next.t("set_fund_password")}</span>
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="angle-right"
                          />
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/member/security">
                        <div>
                          <span>{i18next.t("edit_login_password")}</span>
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="angle-right"
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="memberForm pb-3">
                  <ul className="memberMenu">
                    <li>
                      <Link to="/member/language">
                        <div>
                          <span>{i18next.t("switch_language")}</span>
                        </div>
                        <div>
                          <span>{i18next.t(language)}</span>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="angle-right"
                          />
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/member/line">
                        <div>
                          <span>{i18next.t("switch_line")}</span>
                        </div>
                        <div>
                          <span>{i18next.t("line")} 1</span>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="angle-right"
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                  <span className="sbui-btn-container sbui-btn--w-full">
                    <button
                      type="submit"
                      onClick={() => onLogout()}
                      className="btn-logout sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                    >
                      <span>{i18next.t("logout")}</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UserInfoDesktop;
