import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import SpotHistoryMobile from "./Mobile";
import SpotHistoryDesktop from "./Desktop";

const HistorySpotPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <SpotHistoryMobile />;
  return <SpotHistoryDesktop />;
});

export default HistorySpotPage;
