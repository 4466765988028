import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";

import imgEmpty from "assets/img/empty-dark.png";
import { GamePlayerApi } from "states/api/gamePlayer";

import Item from "./Item";

const HistoryTrade = observer(({ data_price, setReportData }) => {
  const {
    authStore: { games, user },
  } = useStores();

  const [status, setStatus] = useState("open");

  const { data, refetch } = useQuery(
    ["game-player", "contract", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status,
          type: "contract",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [status]);

  return (
    <div className="w-full min-h-[360px] bg-[#151617] border-t-[1px] border-[#2c2d2e] mt-[10px]">
      <div className="order-top">
        <div
          className={`order-top-span order-top-current fcfs ${
            status === "open" && "order-top-select"
          } `}
          onClick={() => setStatus("open")}
        >
          {i18next.t("position")}
        </div>
        <div
          className={`order-top-span order-top-current fcfs ${
            status === "complete" && "order-top-select"
          } `}
          onClick={() => setStatus("complete")}
        >
          {i18next.t("order_history")}
        </div>
      </div>
      <div className="order-main">
        <div className="table-box order-main-table-current">
          <table className="table fck order-main-table w-full">
            <thead>
              <tr className="fccs">
                <td>{i18next.t("feature")}</td>
                <td>{i18next.t("open_price")}</td>
                {status === "closed" && <td>{i18next.t("close_price")}</td>}
                <td>{i18next.t("margin")}</td>
                <td>
                  {i18next.t("amount_money")}({i18next.t("pies")})
                </td>
                <td>{i18next.t("type")}</td>
                <td>{i18next.t("open_time")}</td>
                {status === "open" && <td>{i18next.t("poundage")}</td>}
                {status === "closed" && <td>{i18next.t("fee")}</td>}
                <td>{i18next.t("p_L")}</td>
                <td>{i18next.t("state")}</td>
                {status === "open" && <td>{i18next.t("operation")}</td>}
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item) => (
                <Item
                  status={status}
                  data={item}
                  data_price={data_price}
                  key={item?.id}
                />
              ))}
            </tbody>
          </table>
          {!data?.data?.length && (
            <div className="table-history-more">
              <img src={imgEmpty} alt="" className="empty-svg inline" />
              <p className="fccs">Chưa có giao dịch</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default HistoryTrade;
