import React from "react";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import LoginMobile from "./LoginMobile";
import LoginDesktop from "./LoginDesktop";

function LoginPage() {
  const { isMobile } = useMediaQuery();
  if (isMobile) return <LoginMobile />;

  return <LoginDesktop />;
}

export default LoginPage;
