import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronRight,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";

import icon40 from "assets/img/icon/icon40.png";
import LeftNav from "pages/User/LeftNav";
import { kycStatusText } from "_common/constants/statusType";
import i18next from "i18next";

const MemberDesktop = observer(() => {
  const {
    authStore: { user, general },
  } = useStores();
  const navigate = useNavigate();

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div className="bg-[#f8f8f8]">
              <div className="title">
                <h3>{i18next.t("account")}</h3>
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <div className="text-[12px] text-[#c1c1c1] font-[500]">
                      {i18next.t("wallet_password")}
                    </div>
                    <div className="text-[#bdbdbd] text-[10px] font-[400]">
                      {i18next.t("used_in_fiat")}
                    </div>
                    <Link
                      to="/member/wallet-password"
                      type="button"
                      className="btn-asset change"
                    >
                      {i18next.t("setting")}
                    </Link>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="text-[12px] text-[#c1c1c1] font-[500]">
                      {i18next.t("password")}
                    </div>
                    <Link
                      to="/member/password"
                      type="button"
                      className="btn-asset change"
                    >
                      {i18next.t("change")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="header-info mb-[18px]">
                <div className="flex flex-col items-center justify-center">
                  <img src={icon40} alt="" />
                  <h1 className="text-[#fbbc01] font-bold text-[18px] mt-[16px]">
                    {user?.username}
                  </h1>
                  <p className="font-bold text-[#121212] text-[12px] mb-[10px]">
                    <span>{i18next.t("recommend_id")}：</span>
                    <span>{user?.ref_no}</span>
                  </p>
                  <p className="text-[14px] font-bold text-[#c1c1c1] text-center">
                    {i18next.t("user_tit1")}
                  </p>
                </div>
              </div>
              <div className="safety ">
                <div className="borderBase">
                  <div className="text-[#c1c1c1] font-bold text-[14px] mb-[10px]">
                    {i18next.t("security")}
                  </div>
                  <div className="text-[14px] font-bold text-[#fbbc01]">
                    24h {i18next.t("amount")} BTC
                  </div>
                </div>
                <div className="borderBase">
                  <div className="w-[250px] flex items-center">
                    {user?.email ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-[#60ad76] text-[20px]"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-[#cf2f43] text-[20px]"
                      />
                    )}

                    <div className="ml-[14px] text-[14px] text-[#424344] font-bold">
                      <p className="mb-[5px]">{i18next.t("by_email")}</p>
                      <p>{i18next.t("amount")} +50 BTC </p>
                    </div>
                  </div>
                  <div className="text-[12px] w-[252px] text-[#c1c1c1]">
                    {i18next.t("tip1")}
                  </div>
                  <div className="w-[128px] text-[#c1c1c1] text-[12px] ">
                    {user?.email}
                  </div>
                  {user?.email ? (
                    <Link
                      to="/member/update-profile?type=email"
                      type="button"
                      className="btn-asset primary"
                    >
                      {i18next.t("change")}
                    </Link>
                  ) : (
                    <Link
                      to="/member/update-profile?type=email"
                      type="button"
                      className="btn-asset change"
                    >
                      {i18next.t("link")}
                    </Link>
                  )}
                </div>
                <div className="borderBase">
                  <div className="w-[250px] flex items-center">
                    {user?.phone ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-[#60ad76] text-[20px]"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-[#cf2f43] text-[20px]"
                      />
                    )}
                    <div className="ml-[14px] text-[14px] text-[#424344] font-bold">
                      <p className="mb-[5px]">{i18next.t("phone_verify")}</p>
                      <p>{i18next.t("amount")} +50 BTC </p>
                    </div>
                  </div>
                  <div className="text-[12px] w-[252px] text-[#c1c1c1]">
                    {i18next.t("tip2")}
                  </div>
                  <div className="w-[128px] text-[#c1c1c1] text-[12px] ">
                    {user?.phone}
                  </div>
                  {user?.phone ? (
                    <Link
                      to="/member/update-profile?type=phone"
                      type="button"
                      className="btn-asset primary"
                    >
                      {i18next.t("change")}
                    </Link>
                  ) : (
                    <Link
                      to="/member/update-profile?type=phone"
                      type="button"
                      className="btn-asset change"
                    >
                      {i18next.t("link")}
                    </Link>
                  )}
                </div>
                <div className="borderBase">
                  <div className="w-[250px] flex items-center">
                    {user?.advanced_verify === 2 ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-[#60ad76] text-[20px]"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-[#cf2f43] text-[20px]"
                      />
                    )}
                    <div className="ml-[14px] text-[14px] text-[#424344] font-bold">
                      <p className="mb-[5px]">{i18next.t("id_verify")}</p>
                      <p>{i18next.t("amount")} +50 BTC </p>
                    </div>
                  </div>
                  <div className="text-[12px] w-[252px] text-[#c1c1c1]">
                    {i18next.t("id_verify")}
                  </div>
                  <div className="w-[128px] text-[#c1c1c1] text-[12px] ">
                    {user?.email}
                  </div>
                  <div
                    onClick={() => {
                      if (!user?.advanced_verify) {
                        navigate("/member/kyc");
                      }
                    }}
                    type="button"
                    className="btn-asset primary"
                  >
                    {i18next.t(kycStatusText[user?.advanced_verify])}
                  </div>
                </div>
                {/* <div className="borderBase">
                  <div className="w-[250px] flex items-center">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-[#cf2f43] text-[20px]"
                    />
                    <div className="ml-[14px] text-[14px] text-[#424344] font-bold">
                      <p className="mb-[5px]">Google Authenticator</p>
                      <p>Amount +50 BTC </p>
                    </div>
                  </div>
                  <div className="text-[12px] w-[252px] text-[#c1c1c1]">
                    Double authentication, login or withdrawal need to be
                    verified again
                  </div>
                  <div className="w-[128px] text-[#c1c1c1] text-[12px] ">
                    {user?.email}
                  </div>
                  <button type="button" className="btn-asset primary">
                    verify
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MemberDesktop;
