import i18next from "i18next";
import React from "react";
import LeftNav from "pages/User/LeftNav";
import KycForm from "../KycForm";

export default function KYCDesktop() {
  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div className="bg-[#f8f8f8]">
              <div className="title">
                <h3>{i18next.t("set_wallet_password")}</h3>
              </div>
              <div className="bg-white mt-[20px]">
                <div className="w-[427px] mx-[auto]">
                  <KycForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
