import { useStores } from "_common/hooks";
import { observer } from "mobx-react";
import React from "react";

const ModalWelcome = observer(({ onClose }) => {
  const {
    authStore: { welcome_url },
  } = useStores();
  return (
    <div className="modal modal-welcome">
      <div className="mask" onClick={() => onClose()} />
      <div className="modal-content">
        <img src={welcome_url} alt="" />
      </div>
    </div>
  );
});

export default ModalWelcome;
