export const paymentStatusText = {
  pending: "Đang xử lý",
  complete: "Hoàn thành",
  error: "Thất bại",
  reject: "Thất bại",
};

export const paymentTypeText = {
  withdraw: "Withdraw",
  deposit: "Deposit",
  refund: "Refund",
};

export const paymentTypeColor = {
  withdraw: "text-red-600",
  deposit: "text-yellow-700",
  refund: "text-yellow-700",
  system: "text-yellow-700",
  commission: "text-yellow-700",
  add_money: "text-yellow-700",
  sub_money: "text-red-600",
};

export const paymentMethodText = {
  vnd: "TRC20",
  game: "ERC20",
};

export const paymentStatusColor = {
  pending: "text-yellow-700",
  complete: "text-green-600",
  error: "text-red-600",
};

export const paymentAddType = {
  exchange: "-",
  withdraw: "-",
  deposit: "+",
  add_money: "+",
  sub_money: "-",
  commission: "+",
  bonus: "+",
  fund: "+",
  transfer: "+",
};

export const gameStatusText = {
  betGame: "Đang cược",
  win: "Đã trúng thưởng",
  loss: "Chưa trúng thưởng",
};

export const gameTypeText = {
  bet1: "Quần áo",
  bet2: "Mũ",
  bet3: "Trang sức",
  bet4: "Đồng hồ",
  bet5: "Giày dép",
};

export const gameBetSpotStatus = {
  open: "in_progress",
  closed: "closed",
};

export const gameBetSpotType = {
  buy: "buy",
  sell: "sell",
};

export const gameBetItemType = {
  buy: "buy",
  sell: "sell",
};

export const contractBetItemType = {
  buy: "Rise",
  sell: "Fall",
};

export const walletTypes = {
  amount: "spot",
  fiat_amount: "fiat",
  trading_amount: "futures",
  option_amount: "options",
  amount_web3: "financial_account",
};

export const kycStatusText = {
  0: "un_authenticated",
  1: "under_review",
  2: "certified",
  3: "reject_review",
};

export const kycStatusColor = {
  0: "text-gray",
  1: "text-yellow-500",
  2: "text-green-600",
  3: "text-red-600",
};

export const gameLevelText = {
  level_1: "DIOR",
  level_2: "GUCCI",
  level_3: "CHANEL",
};

export const positionKeyText = {
  agency: "Đại lý",
  user: "Thành viên",
  admin: "Quản trị viên",
  super: "Quản trị viên",
};

export const fundStatus = {
  pending: "stacking",
  finish: "over",
  complete: "over",
  refund: "refund",
  error: "reject",
};

export const gameTypeList = [
  {
    time: 30,
    profit: 30,
    text: "30 sec",
  },
  {
    time: 60,
    profit: 50,
    text: "60 sec",
  },
  {
    time: 90,
    profit: 70,
    text: "90 sec",
  },
  {
    time: 120,
    profit: 90,
    text: "120 sec",
  },
];

export const ContractDirectType = {
  buy: "rise",
  sell: "fall",
};

export const rateList = [
  {
    value: 30,
    rate: 95,
  },
  {
    value: 60,
    rate: 80,
  },
  {
    value: 120,
    rate: 75,
  },
  {
    value: 200,
    rate: 70,
  },
  {
    value: 300,
    rate: 15,
  },
];

export const listTime = [
  {
    value: "line",
    text: "Realtime",
  },
  {
    value: "1m",
    text: "1min",
  },
  {
    value: "5m",
    text: "5min",
  },
  {
    value: "15m",
    text: "15min",
  },
  {
    value: "30m",
    text: "30min",
  },
  {
    value: "1h",
    text: "1hour",
  },
  {
    value: "1d",
    text: "1day",
  },
  {
    value: "1w",
    text: "1week",
  },
];

export const leverList = [
  {
    value: 10,
    text: "10X",
  },
  {
    value: 20,
    text: "20X",
  },
  {
    value: 50,
    text: "50X",
  },
  {
    value: 100,
    text: "100X",
  },
  {
    value: 125,
    text: "125X",
  },
];

export const buttons = [25, 50, 75, 100];
