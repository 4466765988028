import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShield,
  faPowerOff,
  faTimes,
  faMoon,
  faSun,
  faSackDollar,
  faAngleRight,
  faFileInvoice,
  faDatabase,
  faEnvelopeOpenText,
  faCommentDots,
  faLanguage,
  faCog,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import i18next from "i18next";

const MemberMobile = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, theme_light, setThemeLight, clear, language },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div className="member-page">
      <div className="mu-card">
        <div className="mu-card-header">
          <div className="mu-card-left">
            <FontAwesomeIcon
              className="text-[26px]"
              icon={faTimes}
              onClick={() => navigate("/")}
            />
          </div>
          {/* <div
            className="mu-card-right"
            onClick={() => setThemeLight(!theme_light)}
          >
            <FontAwesomeIcon icon={theme_light ? faMoon : faSun} />
          </div> */}
        </div>
        <div className="mu-card-body">
          <h1>
            {i18next.t("hi")}, {user?.username}
          </h1>
          <p>
            <span className="opacity-[0.6] text-[18px]">
              {i18next.t("invite_code")}: {user?.ref_no}
            </span>
            <span className="bg-[#2cd5b9] text-[#fff] opacity-[1] px-[10px] py-[2px] rounded-md ml-[5px]">
              {i18next.t("copy")}
            </span>
          </p>
        </div>
      </div>

      <div className="no_inbox lbox_f_br">
        <Link to="/spot/order">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon icon={faSackDollar} className="text-[#FBC501]" />
              <span className="ml-[10px] f16 ">{i18next.t("spot_order")}</span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </Link>
        <Link to="/contract/order">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faFileInvoice}
                className="text-[#FBC501]"
              />
              <span className="ml-[10px] f16 ">
                {i18next.t("future_order")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </Link>
        <Link to="/member/info">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon icon={faShield} className="text-[#FBC501]" />
              <span className="ml-[10px] f16 ">
                {i18next.t("account_security")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </Link>
        <Link to="/member/security">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon icon={faDatabase} className="text-[#FBC501]" />
              <span className="ml-[10px] f16 ">
                {i18next.t("french_currency_setting")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </Link>
        {/* <Link to="/member/sharing">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faEnvelopeOpenText}
                className="text-[#FBC501]"
              />
              <span className="ml-[10px] f16 ">{i18next.t("cashback")}</span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </Link> */}
        <Link to="/cskh">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faCommentDots}
                className="text-[#FBC501]"
              />
              <span className="ml-[10px] f16 ">
                {i18next.t("customer_feedback")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </Link>
        <Link to="/member/language">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon icon={faLanguage} className="text-[#FBC501]" />
              <span className="ml-[10px] f16 ">{i18next.t("language")}</span>
            </div>
            <div className="lbox_r">
              <span className="mr-[5px] text-[#888]">
                {i18next.t(language)}
              </span>
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </Link>
        {/* <Link to="/member/line">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon icon={faCog} className="text-[#FBC501]" />
              <span className="ml-[10px] f16 ">{i18next.t("setting")}</span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </Link>
        <a href="https://download.bufinix.com/">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon icon={faShareNodes} className="text-[#FBC501]" />
              <span className="ml-[10px] f16 ">
                {i18next.t("download_app")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon className="text-[#888]" icon={faAngleRight} />
            </div>
          </div>
        </a> */}
        <div onClick={onLogout}>
          <div className="lbox">
            <div className="lbox_l flex items-center ">
              <FontAwesomeIcon
                icon={faPowerOff}
                width="18"
                fontSize="18"
                color="#FBC501"
              />
              <span className="ml-[10px] f16 ">{i18next.t("log_out")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MemberMobile;
