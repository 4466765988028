import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import moment from "moment";

export default function HistoryPrice() {
  const {
    authStore: { trading_view },
  } = useStores();

  const { dataGame } = useNotify();

  function genRand(min, max) {
    return Math.random() * (max - min) + min;
  }

  const listBuy = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 10; index += 1) {
      result.push({
        price: beginPrice + index * alpha,
        volume: genRand(0, 1),
        line: genRand(10, 90),
        time: moment().format("HH:mm:ss"),
        index: 10 - index,
      });
    }

    return result;
  }, [dataGame]);

  const listSell = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 10; index += 1) {
      result.push({
        price: beginPrice - index * alpha,
        volume: genRand(0, 1),
        line: genRand(10, 90),
        time: moment().format("HH:mm:ss"),
        index: 10 - index,
      });
    }

    return result;
  }, [dataGame]);

  return (
    <div className="w-full">
      <div className="w-full h-[20px] px-[5px]">
        <div className="tradebox_title col-4 tcl">
          <span className="fccs f12">{i18next.t("direction")}</span>
        </div>
        <div className="tradebox_title tcc col-4">
          <span className="fccs f12">{i18next.t("price")}</span>
        </div>
        <div className="tradebox_title col-4 tcr">
          <span className="fccs f12">{i18next.t("amount")}</span>
        </div>
      </div>
      <div className="trade_listbox" id="tradesellbox">
        {listSell.map((item, index) => (
          <div className="w-full h-[30px]" key={`sell-${index.toString()}`}>
            <div className="trade_list col-4 tcr">
              <span className="green f12">
                {i18next.t("sell")} {item?.index}
              </span>
            </div>
            <div className="trade_list col-4 tcl">
              <span className="green f12">
                {formatNumber(item.price, "0,0.[00]")}
              </span>
            </div>
            <div className="trade_list col-4 tcc">
              <span className="green f12">
                {formatNumber(item.volume, "0,0.[000000]")}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="trade_listpricebox closeprice" id="closeprice">
        <span className="red fw !text-[22px]">
          {formatNumber(dataGame?.lastPrice, "0,0.0000")}
        </span>
      </div>
      <div className="trade_listbox" id="tradesellbox">
        {listBuy.reverse().map((item, index) => (
          <div className="w-full h-[30px]" key={`buy-${index.toString()}`}>
            <div className="trade_list col-4 tcr">
              <span className="red  f12">
                {i18next.t("buy")} {item?.index}
              </span>
            </div>
            <div className="trade_list col-4 tcl">
              <span className="red  f12">
                {formatNumber(item.price, "0,0.[00]")}
              </span>
            </div>
            <div className="trade_list col-4 tcc">
              <span className="red  f12">
                {formatNumber(item.volume, "0,0.[000000]")}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
