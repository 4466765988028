import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLongArrowAltLeft,
  faShareAlt,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import NoData from "_common/component/NoData";
import classNames from "classnames";
import { gameBetItemType } from "_common/constants/statusType";
import ModalClosePosition from "../ModalClosePosition";

export default function Item({ data, data_price }) {
  const navigate = useNavigate();

  const [openModalClose, setModalClose] = useState();
  const [dataDetail, setDataDetail] = useState();

  const handleClosePosition = (data) => {
    setDataDetail(data);
    setModalClose(true);
  };

  const percentAmount = useMemo(() => {
    const lastPrice = Number(get(data_price, "lastPrice", 0));
    const open = Number(get(data, "open", 1));
    const amountLever = Number(get(data, "lever", 0));
    const betGame = data?.bet_game;

    if (betGame === "short") {
      return ((open - lastPrice) / open) * 100 * amountLever;
    }

    return ((lastPrice - open) / open) * 100 * amountLever;
  }, [data, data_price?.lastPrice]);

  const profitAmount = useMemo(() => {
    const lastPrice = Number(get(data_price, "lastPrice", 0));
    const open = Number(get(data, "open", 1));
    const amountLever = Number(get(data, "amount_lever", 0));
    const betGame = data?.bet_game;
    if (betGame === "short") {
      return (open - lastPrice) * amountLever;
    }

    return (lastPrice - open) * amountLever;
  }, [data, percentAmount]);

  return (
    <>
      <div key={data?.id} className="listbox">
        <div className="listbox_title !h-[35px]">
          <div className="listbox_title_l">
            <p
              className={classNames("f14 text-[16px] text-[500] uppercase", {
                "text-[#f5465c]": data?.bet_game === "short",
                "text-[#60c08c]": data?.bet_game === "long",
              })}
            >
              {i18next.t(data?.bet_game)} {formatNumber(data?.lever)}X{" "}
              {data?.game?.name}
            </p>
          </div>
          <div className="listbox_title_r">
            <FontAwesomeIcon icon={faShareSquare} className="text-[#666]" />
          </div>
        </div>
        <div className="w-full py-[5px] px-[12px] flex flex-wrap gap-2">
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px]">
              {i18next.t("average_opening_price")}
            </span>
            <span className="text-[14px]">
              {formatNumber(data?.open, "0,0.[00000]")}
            </span>
          </div>
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px] text-center">
              {i18next.t("mark_price")}
            </span>
            <span className="text-[14px] text-center">
              {formatNumber(data_price?.lastPrice, "0,0.[00000]")}
            </span>
          </div>
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px] text-right">
              {i18next.t("margin")}
            </span>
            <span className="text-[14px] text-right">
              {formatNumber(data?.total, "0,0.[0000]")}
            </span>
          </div>
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px]">
              {i18next.t("take_profit_trigger_price")}
            </span>
            <span className="text-[14px]">
              {data?.profit_price
                ? formatNumber(data?.profit_price, "0,0.[00000]")
                : "--"}
            </span>
          </div>
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px] text-center">
              {i18next.t("stop_loss_trigger_price")}
            </span>
            <span className="text-[14px] text-center">
              {data?.loss_price
                ? formatNumber(data?.loss_price, "0,0.[00000]")
                : "--"}
            </span>
          </div>
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px] text-right">
              {i18next.t("position_amount")}
            </span>

            <span className="text-[14px] text-right">
              {formatNumber(data?.amount, "0,0.[00000]")}
            </span>
          </div>
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px]">{i18next.t("expected_revenue")}</span>
            <span className="text-[14px]">
              {formatNumber(profitAmount, "0,0.[00000]")}
            </span>
          </div>
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px] text-center">
              {i18next.t("estimated_liquidation")}
            </span>
            <span className="text-[14px] text-center">
              {formatNumber(data?.expected_price, "0,0.[00000]")}
            </span>
          </div>
          <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
            <span className="text-[14px] text-right">{i18next.t("yield")}</span>
            <span
              className={classNames("text-[14px] text-right", {
                "text-[#f5465c]": percentAmount <= 0,
                "text-[#60c08c]": percentAmount > 0,
              })}
            >
              {formatNumber(percentAmount, "0,0.[00]")}%
            </span>
          </div>
          <div className="flex justify-between gap-2 h-[40px] datas-center w-[100%]">
            <button type="button" className="btn btn-contract">
              {i18next.t("stop_profit_loss")}
            </button>
            <button type="button" className="btn btn-contract">
              {i18next.t("adjust_leverage")}
            </button>
            <button
              type="button"
              className="btn btn-contract"
              onClick={() => handleClosePosition(data)}
            >
              {i18next.t("close_position")}
            </button>
          </div>
        </div>
      </div>
      {openModalClose && (
        <ModalClosePosition
          data_price={data_price}
          data={dataDetail}
          open={openModalClose}
          onClose={() => setModalClose(false)}
        />
      )}
    </>
  );
}
