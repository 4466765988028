import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import KYCMobile from "./Mobile/KYC";
import KYCDesktop from "./Desktop/KYCDesktop";

const KYCPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <KYCMobile />;
  return <KYCDesktop />;
});

export default KYCPage;
