import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { UserFundApi } from "states/api/userFund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import { fundStatus } from "_common/constants/statusType";
import moment from "moment";
import classNames from "classnames";
import ModalFundProfit from "./ModalFundProfit";

export default function ListDataDesktop() {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState();

  const { data, refetch } = useQuery(
    ["funds", "history"],
    () =>
      UserFundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="fund-detail-page">
      <div className="container mt-5">
        <div className="w-full flex items-center justify-between mb-[20px]">
          <span className="text-black text-[18px] font-bold">
            {i18next.t("my_staking")}
          </span>
        </div>
        <table className="table-fund">
          <thead>
            <th>{i18next.t("mining_pool")}</th>
            <th>{i18next.t("income_currency")}</th>
            <th>{i18next.t("rate_of_return")}</th>
            <th>{i18next.t("investment_period")}</th>
            <th>{i18next.t("staking_mount")}</th>
            <th>{i18next.t("expected_return")}</th>
            <th>{i18next.t("expected_end_time")}</th>
            <th>{i18next.t("status")}</th>
            <th>{i18next.t("operate")}</th>
          </thead>
          <tbody>
            {data?.data?.map((item) => (
              <tr key={item?.id}>
                <td>{item?.fund.name}</td>
                <td>USDT</td>
                <td>{formatNumber(item?.fund.profit, "0,0.[00000]")}%</td>
                <td>
                  {formatNumber(item?.fund?.date)} {i18next.t("DAY")}
                </td>
                <td>{formatNumber(item?.amount)}</td>
                <td>{formatNumber(item?.amount_payback, "0,0.[00000]")}</td>
                <td>
                  {moment(item?.created_at)
                    .add(item?.fund?.date, "day")
                    .format("YYYY-MM-DD")}
                </td>
                <td
                  className={classNames({
                    "text-[#fbbc01]": item?.status === "pending",
                    "text-[#a3a3a3]": item?.status === "complete",
                  })}
                >
                  {i18next.t(fundStatus[item?.status])}
                </td>
                <td>
                  <span
                    className="text-[#fbbc01]"
                    onClick={() => setOpenModal(item?.id)}
                  >
                    {i18next.t("view_earning")}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!!openModal && (
        <ModalFundProfit
          open={!!openModal}
          fund_id={openModal}
          onClose={() => setOpenModal()}
        />
      )}
    </div>
  );
}
