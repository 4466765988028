import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "_common/utils/formatValue";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function FundItem({ item }) {
  const navigate = useNavigate;
  const queryClient = useQueryClient();

  return (
    <div key={item?.id} className="item">
      <div className="py-[15px] px-[20px]">
        <div className="flex  justify-between gap-2 mb-[15px]">
          <div className="flex items-center gap-4">
            <span className="text-lg font-bold">{item?.name}</span>
          </div>
        </div>
        <div className="flex justify-between font-bold">
          <div className="text-left text-bold text-[#ff6a6a] text-[22px]">
            {formatNumber(item?.profit, "0,0.[0000]")}%{" "}
          </div>
          <div className="text-right text-bold text-[#000] text-[16px]">
            {formatNumber(item?.min, "0,0.[0000]")}～
            {formatNumber(item?.max, "0,0.[0000]")} USDT
          </div>
        </div>
        <div className="flex justify-between font-[500] text-[16px]">
          <div className="text-left text-bold text-[#888]">
            {i18next.t("daily_rate")}
          </div>
          <div className="text-right text-bold text-[#888]">
            {i18next.t("staking_amount_range")}
          </div>
        </div>
        <div className="my-[10px] divide-y-[1px] border-b border-solid border-[#e3e3e3]" />
        <div className="flex justify-between items-center text-[#888] font-[500]">
          <div className="flex-1">
            <p>{i18next.t("staking_coin")}</p>
            <p className="text-[#126469] mt-[5px]">USDT</p>
          </div>
          <div className="flex-1 text-center">
            <p>{i18next.t("profit_coin")}</p>
            <p className="text-[#126469] mt-[5px]">USDT</p>
          </div>
          <div className="flex-1 text-right">
            <p>{i18next.t("remaining_share")}</p>
            <p className="text-[#000000] mt-[5px]">{i18next.t("unlimited")}</p>
          </div>
        </div>
        <div className="flex justify-between items-center text-[#888] font-[500] mt-[10px]">
          <div className="flex-1">
            <p>{i18next.t("duration")}</p>
            <p className="text-[#126469] mt-[5px]">
              {formatNumber(item?.date)}
            </p>
          </div>
          <div className="flex-1 text-center">
            <p>{i18next.t("grant")}</p>
            <p className="text-[#126469] mt-[5px]">{i18next.t("daily")}</p>
          </div>
          <div className="flex-1 text-right">
            <p>{i18next.t("type")}</p>
            <p className="text-[#000000] mt-[5px]">{i18next.t("income")}</p>
          </div>
        </div>
        <Link
          to={`/board/fund/detail/${item?.id}`}
          type="button"
          className="sbui-btn  btn-fund-add !mt-[15px]"
        >
          {i18next.t("staking_now")}
        </Link>
      </div>
    </div>
  );
}
