import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faUser,
  faHeadset,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import applicationIcon from "assets/icons/application.svg";
import chartIcon from "assets/icons/chart.svg";
import hotIcon from "assets/icons/hot-2.svg";
import btcIcon from "assets/icons/btcIcon.svg";
import downIcon from "assets/icons/downIcon.svg";
import icon5 from "assets/img/icon/icon-5.png";
import qrdownload from "assets/img/qrdownload.png";
import i18next from "i18next";
import SelectLanguage from "_common/component/SelectLanguage";

const HeaderDesktop = observer(({ is_open, setMenuOpen }) => {
  const {
    authStore: { logo, general, authenticated, user },
  } = useStores();

  return (
    <header className="css-jmskxt">
      <Link to="/" className="css-1mvf8us">
        <img src={logo} className="css-1jgk2rg css-logo" alt="" />
        <div className="css-1tp5kus header-title">
          <div className="css-vurnku  f22 fw header-title">
            {general?.name_website}
          </div>
        </div>
      </Link>
      <div className="css-1tp5kus">
        <Link to="/market" className="css-1smf7ma">
          <div className="css-vurnku">{i18next.t("market")}</div>
        </Link>
      </div>
      <div className="css-1tp5kus">
        <Link to="/board/spot" className="css-1smf7ma">
          <div className="css-vurnku">{i18next.t("spot")}</div>
        </Link>
      </div>
      <div className="css-1tp5kus buy_coins">
        <Link to="/feature" className="css-1smf7ma">
          <div className="css-vurnku">{i18next.t("feature")}</div>
        </Link>
      </div>
      <div className="css-1tp5kus trad_coins">
        <Link to="/contract" className="css-1smf7ma">
          <div className="css-vurnku">{i18next.t("option_trading")}</div>
        </Link>
      </div>
      <div className="css-1tp5kus">
        <Link to="/board/fund" className="css-1smf7ma">
          <div className="css-vurnku">{i18next.t("financial_service")}</div>
        </Link>
      </div>
      {/* <div className="css-1tp5kus">
        <Link to="/board/wallet" className="css-1smf7ma">
          <div className="css-vurnku">{i18next.t("my_assets")}</div>
        </Link>
      </div> */}
      <div className="css-11y6cix" />
      <ul id="nav" className="nav !items-center">
        <div className="css-wu6zme !items-center">
          {authenticated ? (
            <div className="css-mu7imd">
              <div className="css-1smf7ma ">
                <Link to="/member">
                  <div className="css-15owl46" style={{ padding: "0px 5px;" }}>
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon
                        icon={faCircleUser}
                        className="w-[20px] h-[20px]"
                      />
                      <span>{user?.username}</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div className="css-mu7imd">
                <div className="css-1smf7ma">
                  <Link to="/auth/login">
                    <div
                      className="css-15owl46"
                      style={{ padding: "0px 5px;" }}
                    >
                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon
                          icon={faUser}
                          className="w-[16px] h-[16px]"
                        />
                        <span>{i18next.t("sign_in")}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="css-mu7imd">
                <div className="css-1smf7ma">
                  <Link to="/auth/register">
                    <div
                      className="css-15owl46"
                      style={{ padding: "0px 5px;" }}
                    >
                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="w-[16px] h-[16px]"
                        />
                        <span>{i18next.t("sign_up")}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="css-mu7imd">
          <Link to="/cskh">
            <div className="css-1smf7ma">
              <div className="css-15owl46" style={{ padding: "0px 5px;" }}>
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon
                    icon={faHeadset}
                    className="w-[16px] h-[16px]"
                  />
                  {i18next.t("online_customer_service")}
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="css-1smf7ma w-[110px]">
          <SelectLanguage />
        </div>
      </ul>
    </header>
  );
});

export default HeaderDesktop;
