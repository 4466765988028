import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { get } from "lodash";
import { buttons, gameTypeList, rateList } from "_common/constants/statusType";

import ModalBet from "./ModalBet";

export default function GameBoard({ game_id, data_price, direction }) {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();

  const [last_price, setLastPrice] = useState(get(data_price, "lastPrice", 0));
  const [game_type, setGameType] = useState(direction || "long");
  const [bet_type, setBetType] = useState("market");
  const [lever, setLever] = useState(50);
  const [amount, setAmount] = useState(0);
  const [loss_price, setLossPrice] = useState();
  const [profit_price, setProfitPrice] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [amount_type, setAmountType] = useState();

  const finalTotal = useMemo(() => (1000 / lever) * amount, [amount, lever]);

  const openEstAmount = useMemo(() => {
    const tradingBalance = Number(user?.balance_trading);
    return Math.floor(tradingBalance / (1000 / lever));
  }, [user, lever]);

  const handleChangeInput = (event) => {
    if (!event?.target?.value) {
      setAmount();
    } else {
      setAmount(Number(event?.target?.value));
    }
  };

  useEffect(() => {
    setLastPrice(Number(get(data_price, "lastPrice", 0)));
  }, [data_price]);

  return (
    <div className="feature-board">
      <div className="tabs">
        <div
          className={classNames("tab ", {
            "bg-[#3db485] !text-white": game_type === "long",
          })}
          onClick={() => setGameType("long")}
        >
          {i18next.t("open_long")}
        </div>
        <div
          className={classNames("tab", {
            "bg-[#fa6767] !text-white": game_type === "short",
          })}
          onClick={() => setGameType("short")}
        >
          {i18next.t("open_short")}
        </div>
      </div>
      <div className="flex justify-between py-[15px]">
        <span>{i18next.t("available")}</span>
        <b>{formatNumber(user?.balance_trading)}USDT</b>
      </div>
      <div className="flex items-center gap-3">
        <select
          className="w-[60%]"
          value={bet_type}
          onChange={(e) => setBetType(e?.target?.value)}
        >
          <option value="market" onClick={() => setBetType("market")}>
            {i18next.t("market")}
          </option>
          <option value="limit" onClick={() => setBetType("limit")}>
            {i18next.t("limit")}
          </option>
        </select>
        <select
          className="w-[40%]"
          value={lever}
          onChange={(e) => setLever(e?.target?.value)}
        >
          {rateList?.map((item) => (
            <option
              key={item?.value}
              value={item?.value}
              onClick={() => setLever(item?.value)}
            >
              {item?.text}
            </option>
          ))}
        </select>
      </div>
      {bet_type === "general_entrust" && (
        <div
          className="qty-input mt-[10px]"
          style={{
            width: "100%",
          }}
        >
          <button
            type="button"
            className="btn btn-add"
            onClick={() =>
              setLastPrice(last_price > 1000 && Number(last_price) - 1000)
            }
          >
            -
          </button>
          <input
            placeholder={user?.balance}
            type="number"
            step={0.00000001}
            inputMode="decimal"
            className="sbui-input sbui-input--large"
            min={0}
            value={last_price}
            onChange={(e) => setLastPrice(e?.target?.value)}
          />
          <button
            type="button"
            className="btn btn-add"
            onClick={() => setLastPrice(Number(last_price) + 1000)}
          >
            +
          </button>
        </div>
      )}

      <div className="qty-input !w-full mt-[10px]">
        <input
          placeholder={i18next.t("transaction_amount")}
          type="number"
          inputMode="decimal"
          className="sbui-input sbui-input--large"
          min={0}
          step={0.00000001}
          value={amount}
          onChange={handleChangeInput}
        />
      </div>
      <div className="quantity-type">
        {buttons.map((item) => (
          <button
            key={`num-${item.toString()}`}
            type="button"
            className={classNames("btn", { active: amount_type === item })}
            onClick={() => {
              setAmountType(item);
              setAmount(Number(Math.floor(openEstAmount * (item / 100))));
            }}
          >
            {item}%
          </button>
        ))}
      </div>
      <div className="input-profit mt-[15px] flex items-center justify-between gap-2">
        <input
          type="number"
          inputMode="decimal"
          step={0.00000001}
          value={profit_price}
          onChange={(e) => setProfitPrice(e?.target?.value)}
          placeholder={i18next.t("take_profit_trigger")}
        />
        <input
          type="number"
          step={0.00000001}
          inputMode="decimal"
          value={loss_price}
          onChange={(e) => setLossPrice(e?.target?.value)}
          placeholder={i18next.t("stop_loss_trigger")}
        />
      </div>
      <div className="button-footer mt-[20px]">
        <button
          type="button"
          className={classNames("btn !w-full ", {
            "btn-buy": game_type === "long",
            "btn-sell": game_type === "short",
          })}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {i18next.t("activate_future")}
        </button>
      </div>
      <div className="info mt-[5px]">
        <span className="text-[#707A8A]">{i18next.t("open")}</span>
        <span className="text-[#707A8A]">
          {formatNumber(openEstAmount)}
          {i18next.t("pies")}
        </span>
      </div>
      <div className="info mt-[5px]">
        <span className="text-[#707A8A]">{i18next.t("usable")}</span>
        <span className="text-[#707A8A]">
          {formatNumber(finalTotal)}&nbsp;USDT
        </span>
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            game_id,
            type: "contract",
            mode: bet_type,
            lever,
            game_type,
            amount,
            lastPrice: last_price,
            final_total: finalTotal,
            profit_price,
            loss_price,
          }}
        />
      )}
    </div>
  );
}
