import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const pairEndpoint = `${endpoint}/pairs`;

export const PairApi = {
  getList: async ({ params }) =>
    await api
      .get(`${pairEndpoint}/list`, params)
      .then((result) => result.data.data),
};
