import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import ShareLinkMobile from "./Mobile";
import ShareLinkDesktop from "./Desktop";

const ShareLinkPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <ShareLinkMobile />;
  return <ShareLinkDesktop />;
});

export default ShareLinkPage;
