import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "_common/hooks";

import { observer } from "mobx-react-lite";
import moment from "moment";
import { PaymentApi } from "states/api/payment";
import i18next from "i18next";

const IncomeBreakdown = observer(({ fund_id }) => {
  const navgate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const { data, refetch } = useQuery(
    ["funds", "profit"],
    () =>
      PaymentApi.getList({
        params: {
          page: 1,
          limit: 50,
          object_id: fund_id,
          type: "fund",
          mode: "profit",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="mt-[10px] text-[14px] text-[#989898] font-[500]">
      <div className="flex justify-between mb-[5px]">
        <span>{i18next.t("gain_profit")}</span>
        <span>{i18next.t("earning_time")}</span>
      </div>
      {data?.data?.map((item) => (
        <div
          className="flex justify-between mb-[8px] text-[#424344] font-[600]"
          key={item?.id}
        >
          <span>{formatNumber(item?.amount, "0,0.[00000]")} USDT</span>
          <span>
            {item?.created_at &&
              moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
      ))}
    </div>
  );
});

export default IncomeBreakdown;
