import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import {
  faExchange,
  faFileAlt,
  faWallet,
  faYenSign,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";
import { PriceApi } from "states/api/price";

import iconDeposit from "assets/icons/icon_deposit.svg";
import iconWithdraw from "assets/icons/icon_withdraw.svg";
import iconTransfer from "assets/icons/icon_transfer.svg";
import iconRecord from "assets/icons/icon_record.svg";

const SpotAccount = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { tokens, user, display_balance },
  } = useStores();

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  const [listCoin, setListCoin] = useState([]);
  const [btcBalance, setBTCBalance] = useState({
    btc: 0,
    usdt: 0,
    spot: 0,
    trading: 0,
    option: 0,
  });

  const { data, refetch } = useQuery(
    ["token_price", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 20,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let totalBTC = 0;
    let totalUSDT = 0;
    let totalSpotUSDT = 0;
    let totalTradingUSDT = 0;
    let totalOptionUSDT = 0;
    const btcPrice = data?.find((obj) => obj?.symbol === "BTCUSDT")?.lastPrice;
    const cloneList = tokens?.map((item) => {
      const coin = data?.find(
        (obj) => obj?.symbol?.toLowerCase() === item?.network_name
      );
      let lastPrice = Number(get(coin, "lastPrice", 0));
      if (item?.symbol === "usdt") {
        lastPrice = 1;
        const amountBalance = Number(get(item, "amount", 0));
        const tradingBalance = Number(get(item, "trading_amount", 0));
        const optionBalance = Number(get(item, "option_amount", 0));
        totalBTC += tradingBalance / btcPrice;
        totalBTC += optionBalance / btcPrice;
        totalUSDT += amountBalance;
        totalUSDT += tradingBalance;
        totalUSDT += optionBalance;
        totalTradingUSDT += tradingBalance;
        totalOptionUSDT += tradingBalance;
      }
      const convertUsdt = Number(get(item, "amount", 0)) * lastPrice;
      const convertBTC = convertUsdt / btcPrice;
      totalBTC += convertBTC;
      totalSpotUSDT += convertUsdt;

      return {
        ...item,
        lastPrice,
        convert_amount: convertUsdt,
        btcPrice: Number(btcPrice),
        convert_btc: Number(convertBTC).toFixed(6),
      };
    });

    setListCoin(cloneList);
    setBTCBalance({
      btc: totalBTC,
      usdt: totalUSDT,
      spot: totalSpotUSDT,
      trading: totalTradingUSDT,
      option: totalOptionUSDT,
    });
  }, [tokens, data]);

  return (
    <div className="container spot-account">
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex flex-1">
          <div className="flex flex-col w-full gap-4">
            <div className="allmoneybox !h-[150px]">
              <p className="py-[10px] px-[10px] opacity-[0.6]">
                {i18next.t("exchange_balance")} (USD)
              </p>
              <div className="allmbox_3">
                <span className="text-[24px]  mr-[5px]">≈</span>
                <span className="zhehebox font-bold text-[30px] ">
                  {formatBalance(user?.balance, display_balance)}
                </span>
              </div>
              <p className="mt-[10px] px-[10px] opacity-[0.6]">
                {i18next.t("today_profit")} (USD)
              </p>
              <h5 className="px-[10px] font-bold text-white text-[16px]">
                {formatNumber(user?.profit_today, "0,0.[00000]")}USDT /{" "}
                {formatNumber(user?.profit_today_percent, "0,0.[000]")}%
              </h5>
            </div>
            <div className="flex justify-between">
              <Link
                to="/member/recharge"
                className="flex flex-1 flex-col items-center justify-center gap-1 text-[#888] text-[15px]"
              >
                <img src={iconDeposit} alt="" className="w-[38px]" />
                <span className="font-[500]">{i18next.t("deposit")}</span>
              </Link>
              <Link
                to="/member/withdraw"
                className="flex flex-1 flex-col items-center justify-center gap-1 text-[#888] text-[15px]"
              >
                <img src={iconWithdraw} alt="" className="w-[38px]" />
                <span className="font-[500]">{i18next.t("withdraw")}</span>
              </Link>
              <Link
                to="/member/transfer"
                className="flex flex-1 flex-col items-center justify-center gap-1 text-[#888] text-[15px]"
              >
                <img src={iconTransfer} alt="" className="w-[38px]" />
                <span className="font-[500]">{i18next.t("transfer")}</span>
              </Link>
              <Link
                to="/board/history"
                className="flex flex-1 flex-col items-center justify-center gap-1 text-[#888] text-[15px]"
              >
                <img src={iconRecord} alt="" className="w-[38px]" />
                <span className="font-[500]">{i18next.t("record")}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="list-token">
          {listCoin?.map((item) => (
            <div key={item?.symbol} className="listbox">
              <div className="listbox_title !h-[40px]">
                <div className="listbox_title_l flex gap-3">
                  <span className="text-[#FBC501] text-[18px] font-bold">
                    {item?.name}
                  </span>
                </div>
              </div>
              <div className="w-full h-[60px] px-[10px]">
                <div className="w-[33.33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px]">
                    <span className="text-[#bdbdbd]">
                      {i18next.t("available")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[20px]">
                    <span
                      className="font-bold f16 text-[#1e1e1e]"
                      id="num_usdt"
                    >
                      {formatBalance(item?.amount, display_balance)}
                    </span>
                  </div>
                </div>
                <div className="w-[33.33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-center">
                    <span className="text-[#bdbdbd]">
                      {i18next.t("occupation")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[20px] text-center">
                    <span
                      className="font-bold f16 text-[#1e1e1e]"
                      id="numd_usdt"
                    >
                      {formatBalance(item?.pending_amount, display_balance)}
                    </span>
                  </div>
                </div>
                <div className="w-[33.33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-right">
                    <span className="text-[#bdbdbd]">
                      {i18next.t("equivalent")}(BTC)
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[20px] text-right">
                    <span
                      className="font-bold f16 text-[#1e1e1e]"
                      id="zhehe_usdt"
                    >
                      {formatBalance(item?.convert_amount, display_balance)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default SpotAccount;
