import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import ChangePasswordMobile from "./Mobile";
import ChangePasswordDesktop from "./Desktop";

const ChangePasswordPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <ChangePasswordMobile />;
  return <ChangePasswordDesktop />;
});

export default ChangePasswordPage;
