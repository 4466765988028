import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNotify } from "_common/component/NotifyProvider";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import { contractBetItemType } from "_common/constants/statusType";
import classNames from "classnames";
import { get } from "lodash";
import i18next from "i18next";

const Item = observer(({ status, data_price, data }) => {
  const navigate = useNavigate();
  const { dataGame } = useNotify();

  const process = useMemo(() => {
    const time = Number(get(dataGame?.[data?.id], "time", 0));
    return time;
  }, [dataGame]);

  return (
    <tr className=" f12" key={data?.id}>
      <td
        className={classNames("f14", {
          "text-[#f5465c]": data?.bet_game === "sell",
          "text-[#60c08c]": data?.bet_game === "buy",
        })}
      >
        {i18next.t(contractBetItemType[data?.bet_game])} {data?.mode}s
      </td>
      <td className="fck f14">{formatNumber(data?.open, "0,0.[0000]")}</td>
      {status === "bet" && (
        <td className="fck f14">{formatNumber(process)}s</td>
      )}
      {status === "bet" && (
        <td className="fck f14">{formatNumber(data_price?.lastPrice)}</td>
      )}
      <td className="fck f14">{formatNumber(data?.amount, "0,0.[0000]")}</td>
      <td className="fck f14">{formatNumber(data?.fee, "0,0.[0000]")}</td>
      <td className="fck f14">
        {data?.amount_payback > 0 && (
          <span className="text-[#3db485]">
            + {formatNumber(data?.amount_payback, "0,0.[0000]")}
          </span>
        )}
        {data?.amount_lose > 0 && (
          <span className="text-[#f5465c]">
            - {formatNumber(data?.amount_lose, "0,0.[0000]")}
          </span>
        )}
      </td>
      <td className="fck f14">
        {data?.created_at &&
          moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")}
      </td>
      {status !== "bet" && (
        <td className="fck f14">
          {data?.status === "bet"
            ? i18next.t("amount_pending")
            : i18next.t("closed")}
        </td>
      )}
    </tr>
  );
});

export default Item;
