import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import {
  faExchange,
  faFileAlt,
  faWallet,
  faYenSign,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";
import { PriceApi } from "states/api/price";
import iconTransfer from "assets/icons/icon_transfer.svg";
import iconRecord from "assets/icons/icon_record.svg";

const FinanceAccount = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { tokens, user, display_balance },
  } = useStores();

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  const [listCoin, setListCoin] = useState([]);

  return (
    <div className="container spot-account">
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex flex-1">
          <div className="flex flex-col w-full gap-4">
            <div className="allmoneybox">
              <p className="py-[10px] px-[10px] ">
                {i18next.t("investment_balance")} (USD)
              </p>
              <div className="allmbox_3">
                <span className="text-[24px]  mr-[5px]">≈</span>
                <span className="zhehebox font-bold text-[30px] ">
                  {formatBalance(user?.balance_web3, display_balance)}
                </span>
              </div>
            </div>
            <div className="flex justify-between px-[20px] gap-4">
              <Link
                to="/member/transfer"
                className="bg-[#fff] h-[50px] flex flex-1 items-center justify-center gap-2 text-[#888] text-[15px]"
              >
                <img src={iconTransfer} alt="" className="w-[28px]" />
                <span className="font-[500]">{i18next.t("transfer")}</span>
              </Link>
              <Link
                to="/board/history"
                className="bg-[#fff] h-[50px] flex flex-1 items-center justify-center gap-2 text-[#888] text-[15px]"
              >
                <img src={iconRecord} alt="" className="w-[28px]" />
                <span className="font-[500]">{i18next.t("record")}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="list-token">
          {listCoin?.map((item) => (
            <div key={item?.symbol} className="listbox">
              <div className="listbox_title !h-[40px]">
                <div className="listbox_title_l flex gap-3">
                  <span className="text-[#FBC501] text-[18px] font-bold">
                    {item?.name}
                  </span>
                </div>
              </div>
              <div className="w-full h-[60px] px-[10px]">
                <div className="w-[33.33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px]">
                    <span className="text-[#bdbdbd]">
                      {i18next.t("available")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[20px]">
                    <span
                      className="font-bold f16 text-[#1e1e1e]"
                      id="num_usdt"
                    >
                      {formatBalance(item?.balance_web3, display_balance)}
                    </span>
                  </div>
                </div>
                <div className="w-[33.33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-center">
                    <span className="text-[#bdbdbd]">
                      {i18next.t("occupation")}
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[20px] text-center">
                    <span
                      className="font-bold f16 text-[#1e1e1e]"
                      id="numd_usdt"
                    >
                      {formatBalance(item?.pending_amount, display_balance)}
                    </span>
                  </div>
                </div>
                <div className="w-[33.33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-right">
                    <span className="text-[#bdbdbd]">
                      {i18next.t("equivalent")}(BTC)
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[20px] text-right">
                    <span
                      className="font-bold f16 text-[#1e1e1e]"
                      id="zhehe_usdt"
                    >
                      {formatBalance(item?.convert_amount, display_balance)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default FinanceAccount;
