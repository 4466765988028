import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import classNames from "classnames";
import { contractBetItemType } from "_common/constants/statusType";

export default function Item({ data, data_price }) {
  const navigate = useNavigate();
  const { dataGame } = useNotify();

  const process = useMemo(() => {
    const time = Number(get(dataGame?.[data?.id], "mode", 0));
    const type = Number(get(data, "mode", 60));
    return (time / type) * 100;
  }, [dataGame, data]);

  return (
    <div key={data?.id} className="listbox">
      <div className="listbox_title !h-[35px]">
        <div className="listbox_title_l">
          <p
            className={classNames("f14 text-[16px] text-[500] uppercase", {
              "text-[#f5465c]": data?.bet_game === "sell",
              "text-[#60c08c]": data?.bet_game === "buy",
            })}
          >
            {i18next.t(contractBetItemType[data?.bet_game])} {data?.mode}s
          </p>
        </div>
        <div className="listbox_title_r">
          <FontAwesomeIcon icon={faShareSquare} className="text-[#666]" />
        </div>
      </div>
      <div className="w-full py-[5px] px-[12px] flex flex-wrap gap-2">
        <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
          <span className="text-[14px]">{i18next.t("open_price")}</span>
          <span className="text-[14px]">
            {formatNumber(data?.open, "0,0.[00000]")}
          </span>
        </div>
        <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
          <span className="text-[14px] text-center">{i18next.t("coin")}</span>
          <span className="text-[14px] text-center">{data?.game?.name}</span>
        </div>
        <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
          <span className="text-[14px] text-right">{i18next.t("amount")}</span>
          <span className="text-[14px] text-right">
            {formatNumber(data?.amount, "0,0.[0000]")}
          </span>
        </div>
        <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
          <span className="text-[14px]">{i18next.t("profit_loss")}</span>
          <span
            className={classNames("text-[14px]", {
              "text-[#60c08c]": data?.amount_payback > 0,
              "text-[#f5465c]": data?.amount_lose > 0,
            })}
          >
            {data?.status === "bet" && "--"}
            {data?.amount_payback > 0 && `+${data?.amount_payback}`}
            {data?.amount_lose > 0 && `-${data?.amount_lose}`}
          </span>
        </div>
        <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
          <span className="text-[14px] text-center">{i18next.t("direct")}</span>
          <span
            className={classNames("text-[14px] text-center", {
              "text-[#f5465c]": data?.bet_game === "sell",
              "text-[#60c08c]": data?.bet_game === "buy",
            })}
          >
            {i18next.t(contractBetItemType[data?.bet_game])}
          </span>
        </div>
        <div className="flex justify-between h-[40px] datas-center flex-col w-[31%]">
          <span className="text-[14px] text-right">{i18next.t("status")}</span>
          <span className={classNames("text-[14px] text-right")}>
            {data?.status === "bet" ? "Processing" : "Closed"}
          </span>
        </div>
      </div>
      {data?.status === "bet" && (
        <div className="progress h-[10px] bg-[#1b1d29] mt-[10px]">
          <div className="progress-bar" style={{ width: `${process}%` }}>
            <span className="time">
              {dataGame?.[data?.id]?.time} {i18next.t("sec")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
