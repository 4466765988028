import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { get } from "lodash";
import ModalBet from "./ModalBet";

const buttons = [25, 50, 75, 100];

export default function GameBoard({ game_id, data_price, balanceMain }) {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();
  const typeBet = [
    {
      type: "market",
      text: i18next.t("market"),
    },
    {
      type: "limit",
      text: i18next.t("limit"),
    },
  ];
  const [game_type, setGameType] = useState("buy");
  const [bet_type, setBetType] = useState(typeBet[0].type);
  const [amount, setAmount] = useState(0);
  const [last_price, setLastPrice] = useState(get(data_price, "lastPrice", 0));
  const [openModal, setOpenModal] = useState(false);
  const [amount_type, setAmountType] = useState();

  const volume = useMemo(() => {
    const lastPrice = Number(data_price?.lastPrice);

    return Number(amount) * lastPrice;
  }, [amount, data_price]);

  const handleChangeInput = (event) => {
    if (!event?.target?.value) {
      setAmount();
    } else {
      setAmount(Number(event?.target?.value));
    }
  };

  useEffect(() => {
    if (!data_price) return;
    setLastPrice(Number(data_price?.lastPrice));
  }, [bet_type, data_price, game_id]);

  return (
    <div className="feature-board">
      <div className="group-action">
        <button
          type="button"
          className={classNames("btn btn-buy ", {
            active: game_type === "buy",
          })}
          onClick={() => setGameType("buy")}
        >
          <span>{i18next.t("buy_2")}</span>
        </button>
        <button
          type="button"
          className={classNames("btn btn-sell ", {
            active: game_type === "sell",
          })}
          onClick={() => setGameType("sell")}
        >
          <span>{i18next.t("sell_2")}</span>
        </button>
      </div>
      <div className="formbox_op mb-[20px]">
        <select onChange={(e) => setBetType(e?.target?.value)}>
          {typeBet?.map((item) => (
            <option
              key={item?.type}
              value={item?.type}
              onClick={() => setBetType(item.type)}
            >
              {item?.text}
            </option>
          ))}
        </select>
      </div>
      {bet_type === "market" && (
        <div
          className="qty-input mt-[10px]"
          style={{
            width: "100%",
          }}
        >
          <input
            className="sbui-input sbui-input--large !bg-[#f6f6f6]"
            readOnly
            placeholder={i18next.t("buy_optimal")}
          />
        </div>
      )}
      {bet_type === "limit" && (
        <div
          className="qty-input"
          style={{
            width: "100%",
          }}
        >
          <button
            type="button"
            className="btn btn-add"
            onClick={() =>
              setLastPrice(last_price > 1000 && Number(last_price) - 1000)
            }
          >
            -
          </button>
          <input
            placeholder={user?.balance}
            type="number"
            step={0.00000001}
            inputMode="decimal"
            className="sbui-input sbui-input--large text-center"
            min={0}
            value={last_price}
            onChange={(e) => setLastPrice(e?.target?.value)}
          />
          <button
            type="button"
            className="btn btn-add"
            onClick={() => setLastPrice(Number(last_price) + 1000)}
          >
            +
          </button>
        </div>
      )}

      <div className="qty-input mt-[10px] !w-full">
        <input
          placeholder={i18next.t("amount")}
          type="number"
          step={0.00000001}
          inputMode="decimal"
          className="sbui-input sbui-input--large !bg-[#f6f6f6]"
          min={0}
          value={amount}
          onChange={handleChangeInput}
        />
      </div>
      <div className="quantity-type">
        {buttons.map((item) => (
          <button
            key={`num-${item.toString()}`}
            type="button"
            className={classNames("btn", { active: amount_type === item })}
            onClick={() => {
              const balance = Number(get(user, "balance", 0));
              const balanceConvert = Number(get(balanceMain, "amount", 0));

              setAmountType(item);
              if (game_type === "buy") {
                const quantityConvert = (balance * item) / 100 / last_price;
                setAmount(Number(Number(quantityConvert).toFixed(4)));
              } else {
                setAmount(
                  Number(Number((balanceConvert * item) / 100).toFixed(4))
                );
              }
            }}
          >
            {item}%
          </button>
        ))}
      </div>
      <div className="info mt-[10px]">
        <span className="text-[#707A8A] dark:text-white">
          {i18next.t("available")}
        </span>
        <span className="text-[#707A8A] dark:text-white">
          {game_type === "buy" ? (
            <span className="text-[#707A8A]">
              {formatNumber(user?.balance, "0,0.[00000]")}&nbsp;USDT
            </span>
          ) : (
            <span className="text-[#707A8A]">
              {formatNumber(balanceMain?.amount, "0,0.[00000]")}&nbsp;
              {balanceMain?.name}
            </span>
          )}
        </span>
      </div>
      <div className="info mt-[10px]">
        <span className="text-[#707A8A] dark:text-white">
          {i18next.t("volume")}
        </span>
        <span className="text-[#707A8A] dark:text-white">
          <span className="text-[#707A8A]">
            {formatNumber(volume, "0,0.[00000]")}&nbsp;USDT
          </span>
        </span>
      </div>
      <div className="button-footer mt-[20px]">
        {game_type === "buy" && (
          <button
            type="button"
            className="btn btn-buy  !w-full uppercase"
            onClick={() => setOpenModal(true)}
          >
            {i18next.t("buy_2")} {game_id?.split("usdt")?.[0]}
          </button>
        )}
        {game_type === "sell" && (
          <button
            type="button"
            className="btn btn-sell !w-full uppercase"
            onClick={() => setOpenModal(true)}
          >
            {i18next.t("sell_2")} {game_id?.split("usdt")?.[0]}
          </button>
        )}
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            symbol: balanceMain?.symbol,
            game_id,
            game_type,
            amount,
            bet_type,
          }}
        />
      )}
    </div>
  );
}
