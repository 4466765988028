import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import ModalBlock from "pages/Home/ModalBlock";
import ModalBonus from "pages/Home/ModalBonus";
import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import Loading from "_common/component/Loading";
import { useNotify } from "_common/component/NotifyProvider";
import { GameApi } from "states/api/game";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import MobileFooter from "../Footer/MobileFooter";
import HeaderDesktop from "../Header/HeaderDesktop";
import DesktopFooter from "../Footer/DesktopFooter";

const DesktopLayout = observer(() => {
  const queryClient = useQueryClient();
  const { notify, setNotify, new_time_game, last_id } = useNotify();
  const {
    authStore: { user, setLoading },
  } = useStores();

  const { mutate: onCheckResult } = useMutation(
    (variables) =>
      GameApi.checkResult({
        params: { ...variables, game_id: last_id },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "feature"]);
        // if (res?.data?.status === "win" || res?.data?.status === "lose") {
        //   setDataNotice(res?.data);
        //   setModalNotify(true);
        //   setNotify();
        // }
      },
      onError: (error) => {
        // setModalNotify(true);
        setNotify();
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        // NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  useEffect(() => {
    if (!new_time_game) return;
    queryClient.invalidateQueries(["get_profile"]);
    queryClient.invalidateQueries(["game-player", "list"]);
  }, [new_time_game]);

  useEffect(() => {
    if (!last_id) return;
    onCheckResult();
  }, [last_id]);

  return (
    <div className="tw-dark desktop-layout">
      <div className="desktop-layout font-['Roboto']  text-white w-full h-full">
        <HeaderDesktop />
        <div className="flex w-full h-full">
          <div className="flex flex-col w-full h-screen max-h-full min-h-full transition-all desktop-body">
            <div className={classNames("sbui-loading ")}>
              <div className="loading-mask" />
              <div className="sbui-loading-content">
                <Outlet />
                <DesktopFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default DesktopLayout;
