import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import ContractHistoryMobile from "./Mobile";
import ContractHistoryDesktop from "./Desktop";

const HistoryContractPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <ContractHistoryMobile />;
  return <ContractHistoryDesktop />;
});

export default HistoryContractPage;
