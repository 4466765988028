import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import WalletAddressMobile from "./Mobile";
import WalletAddressDesktop from "./Desktop";

const WalletAddress = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <WalletAddressMobile />;
  return <WalletAddressDesktop />;
});

export default WalletAddress;
