import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import Currency from "./Currency";
import Favorites from "./Favorites";

const MarketMobilePage = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games },
  } = useStores();

  const [gameList, setGameList] = useState(games);
  const [active, setActive] = useState("favorite");

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      gameList.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [gameList, data]
  );

  const handleKeydown = (event) => {
    if (event?.keyCode === 13) {
      const valueKey = event?.target?.value;
      const cloneData = [...gameList];
      const result = cloneData.filter(
        (obj) => obj?.name?.toLowerCase()?.search(valueKey?.toLowerCase()) > -1
      );
      if (!valueKey) {
        setGameList(games);
      } else {
        setGameList(result);
      }
    }
  };

  return (
    <div className="home-page market-page">
      <div className="tabs">
        <div
          className={classNames("tab", {
            active: active === "favorite",
          })}
          onClick={() => setActive("favorite")}
        >
          {i18next.t("favorite")}
        </div>
        <div
          className={classNames("tab", {
            active: active === "spot",
          })}
          onClick={() => setActive("spot")}
        >
          {i18next.t("spot")}
        </div>
        <div
          className={classNames("tab", {
            active: active === "futures",
          })}
          onClick={() => setActive("futures")}
        >
          {i18next.t("futures")}
        </div>
        <div
          className={classNames("tab", {
            active: active === "options",
          })}
          onClick={() => setActive("options")}
        >
          {i18next.t("options")}
        </div>
      </div>
      {active === "favorite" && (
        <Favorites quote="USDT" setActive={setActive} />
      )}
      {active === "spot" && <Currency quote="USDT" type="trade" />}
      {active === "futures" && <Currency quote="USDT" type="feature" />}
      {active === "options" && <Currency quote="USDT" type="contract" />}
    </div>
  );
});

export default MarketMobilePage;
