import React, { useEffect } from "react";

import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { handleCopyLink } from "_common/utils/helper";
import { get } from "lodash";
import i18next from "i18next";
import HeaderTop from "_common/Layout/Header/HeaderTop";

const schema = yup
  .object({
    full_name: yup.string(),
    email: yup.string(),
    phone: yup.string(),
  })
  .required();

export default function UpdateUserMobile() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const [params] = useSearchParams();

  const type = params.get("type");

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        navigate(-1);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  useEffect(() => {
    if (!user) return;
    if (type === "email") {
      setValue("email", user?.email);
    }
    if (type === "phone") {
      setValue("phone", user?.phone);
    }
    if (type === "full_name") {
      setValue("full_name", user?.full_name);
    }
  }, [user, type]);

  return (
    <div className="container mt-10">
      <HeaderTop title={i18next.t("update_user")} />
      <div className="sbui-card">
        <div className="sbui-card-content">
          <form onSubmit={handleSubmit(onSave)}>
            <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
              <div className="flex flex-col w-full">
                {type === "full_name" && (
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("full_name")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            placeholder={i18next.t("full_name")}
                            type="text"
                            className="sbui-input sbui-input--large"
                            {...register("full_name")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {type === "phone" && (
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("phone")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            placeholder={i18next.t("phone")}
                            type="text"
                            className="sbui-input sbui-input--large"
                            {...register("phone")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {type === "email" && (
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("email")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            placeholder={i18next.t("email")}
                            type="text"
                            className="sbui-input sbui-input--large"
                            {...register("email")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
              <div className="flex flex-col w-full">
                <span className="sbui-btn-container sbui-btn--w-full">
                  <button
                    type="submit"
                    className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                  >
                    <span>{i18next.t("confirm")}</span>
                  </button>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
