import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import icon1 from "assets/img/icon/chongzhi.png";
import icon2 from "assets/img/icon/tixin.png";
import icon3 from "assets/img/icon/buy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import {
  faAngleRight,
  faClock,
  faCoins,
  faExchange,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileExport,
  faHeadphones,
  faRandom,
  faRotate,
  faUser,
  faWallet,
  faYenSign,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";

import iconDeposit from "assets/icons/icon_deposit.svg";
import iconWithdraw from "assets/icons/icon_withdraw.svg";
import iconTransfer from "assets/icons/icon_transfer.svg";
import iconRecord from "assets/icons/icon_record.svg";

const General = observer(({ setActiveTab }) => {
  const queryClient = useQueryClient();
  const {
    authStore: { display_balance, user, setDisplayBalance },
  } = useStores();

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <div id="general">
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex flex-1">
          <div className="flex flex-col w-full gap-4">
            <div className="allmoneybox !h-[150px]">
              <p className="py-[10px] px-[10px] ">
                {i18next.t("total_balance")} (USD)
              </p>
              <div className="allmbox_3">
                <span className="text-[24px]  mr-[5px]">≈</span>
                <span className="zhehebox font-bold text-[30px] ">
                  {formatBalance(user?.balance, display_balance)}
                </span>
                <FontAwesomeIcon
                  icon={display_balance ? faEye : faEyeSlash}
                  onClick={() => setDisplayBalance(!display_balance)}
                  className="ml-[16px] text-[20px] text-[#fff]"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <Link
                to="/member/recharge"
                className="flex flex-1 flex-col items-center justify-center gap-1 text-[#888] text-[15px]"
              >
                <img src={iconDeposit} alt="" className="w-[38px]" />
                <span className="font-[500]">{i18next.t("deposit")}</span>
              </Link>
              <Link
                to="/member/withdraw"
                className="flex flex-1 flex-col items-center justify-center gap-1 text-[#888] text-[15px]"
              >
                <img src={iconWithdraw} alt="" className="w-[38px]" />
                <span className="font-[500]">{i18next.t("withdraw")}</span>
              </Link>
              <Link
                to="/member/transfer"
                className="flex flex-1 flex-col items-center justify-center gap-1 text-[#888] text-[15px]"
              >
                <img src={iconTransfer} alt="" className="w-[38px]" />
                <span className="font-[500]">{i18next.t("transfer")}</span>
              </Link>
              <Link
                to="/board/history"
                className="flex flex-1 flex-col items-center justify-center gap-1 text-[#888] text-[15px]"
              >
                <img src={iconRecord} alt="" className="w-[38px]" />
                <span className="font-[500]">{i18next.t("record")}</span>
              </Link>
            </div>
            <div className="px-[16px] list-token">
              <div onClick={() => setActiveTab("spot")}>
                <div className="lbox lbox-j !bg-white">
                  <div className=" flex justify-between items-center">
                    <span className="ml-[10px] f16 ">{i18next.t("spot")}</span>
                    <span className="ml-[10px] text-[#888] font-bold ">
                      {formatNumber(user?.balance, "0,0.[0000000]")} USDT
                      <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                    </span>
                  </div>
                </div>
              </div>
              <div onClick={() => setActiveTab("fiat")}>
                <div className="lbox lbox-j !bg-white">
                  <div className=" !w-full justify-between  flex items-center">
                    <span className="ml-[10px] f16 ">{i18next.t("fiat")}</span>
                    <span className="ml-[10px] text-[#888] font-bold ">
                      {formatNumber(user?.balance_fiat, "0,0.[0000000]")} USDT
                      <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                    </span>
                  </div>
                </div>
              </div>
              <div onClick={() => setActiveTab("future")}>
                <div className="lbox lbox-j !bg-white">
                  <div className=" flex justify-between  items-center">
                    <span className="ml-[10px] f16 ">
                      {i18next.t("futures")}
                    </span>
                    <span className="ml-[10px] text-[#888] font-bold ">
                      {formatNumber(user?.balance_trading, "0,0.[0000000]")}{" "}
                      USDT
                      <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                    </span>
                  </div>
                </div>
              </div>
              <div onClick={() => setActiveTab("option")}>
                <div className="lbox lbox-j !bg-white">
                  <div className=" flex justify-between  items-center !w-[100%]">
                    <span className="ml-[10px] f16 ">
                      {i18next.t("options")}
                    </span>
                    <span className="ml-[10px] text-[#888] font-bold ">
                      {formatNumber(user?.balance_option, "0,0.[0000000]")} USDT
                      <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                    </span>
                  </div>
                </div>
              </div>
              <div onClick={() => setActiveTab("finance")}>
                <div className="lbox lbox-j !bg-white">
                  <div className=" flex justify-between  items-center !w-[100%]">
                    <span className="ml-[10px] f16 ">
                      {i18next.t("financial_account")}
                    </span>
                    <span className="ml-[10px] text-[#888] font-bold ">
                      {formatNumber(user?.balance_web3, "0,0.[0000000]")} USDT
                      <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                  </div>
                </div>
              </div>
              {/* <div onClick={() => setActiveTab("forex")}>
                <div className="lbox lbox-j !bg-white">
                  <div className=" flex justify-between  items-center !w-[100%]">
                    <span className="ml-[10px] f16 ">
                      {i18next.t("forex_account")}
                    </span>
                    <span className="ml-[10px] text-[#888] font-bold ">
                      {formatNumber(user?.balance_forex)} USDT
                      <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default General;
