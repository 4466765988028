import React, { useEffect, useState } from "react";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import classNames from "classnames";
import { gameBetItemType } from "_common/constants/statusType";
import moment from "moment";
import { get } from "lodash";
import { GamePlayerApi } from "states/api/gamePlayer";
import { useQuery } from "@tanstack/react-query";
import NoData from "_common/component/NoData";
import DataItem from "./DataItem";

export default function SpotHistoryMobile() {
  const [status, setStatus] = useState("open");

  const { data, refetch } = useQuery(
    ["game-player", "spot", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [status]);

  return (
    <div className="container page-history pt-[50px]">
      <HeaderTop title={i18next.t("spot_history")} />
      <div className="tabs">
        <div
          className={classNames("tab", {
            active: status === "open",
          })}
          onClick={() => setStatus("open")}
        >
          {i18next.t("open_order")}
        </div>
        <div
          className={classNames("tab", {
            active: status === "all",
          })}
          onClick={() => setStatus("all")}
        >
          {i18next.t("order_history")}
        </div>
      </div>
      <div className="list-history-data ">
        {data?.data?.length > 0 ? (
          get(data, "data", [])?.map((item) => (
            <DataItem key={item?.id} data={item} />
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
