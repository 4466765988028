import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "_common/hooks";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserFundApi } from "states/api/userFund";
import { NotificationManager } from "react-notifications";
import { observer } from "mobx-react-lite";
import { Portal } from "react-portal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const schema = yup
  .object({
    amount: yup
      .number()
      .min(0, `${i18next.t("enter_large_amount_msg")} 0`)
      .required(i18next.t("verify_require")),
  })
  .required();

const ModalFund = observer(({ open, onClose, data }) => {
  const navgate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation(
    (variables) => UserFundApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        onClose();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["funds", "history"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    trigger();
    const amount = values?.amount;

    if (Number(amount) > Number(data?.max)) {
      return NotificationManager.error(
        `${i18next.t("enter_smaller_deposit_amount")} ${formatNumber(
          data?.max
        )}`
      );
    }

    if (Number(amount) < Number(data?.min)) {
      return NotificationManager.error(
        `${i18next.t("enter_large_deposit_amount")} ${formatNumber(data?.min)}`
      );
    }

    mutate({
      ...values,
      fund_id: data?.id,
    });

    return null;
  };

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" onClick={() => onClose()} />
        <div className="modal-content" style={{ width: "80%" }}>
          <div className="modal-header">
            <h5>{data?.name}</h5>
            <span className="btn-close" onClick={() => onClose()}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
          <div className="modal-body !p-[20px]">
            <div className="flex justify-between">
              <div>
                <div className="mb-[10px] text-[15px]">
                  <span>{i18next.t("staking_coin")}:</span>
                  <b>USDT</b>
                </div>
                <div className="mb-[10px] text-[15px]">
                  <span>{i18next.t("daily_rate")}: </span>
                  <b>{formatNumber(data?.profit, "0,0.[00000]")}%</b>
                </div>
                <div className="mb-[10px] text-[15px]">
                  <span>{i18next.t("remaining_share")}:</span>
                  <b>{i18next.t("unlimited")}</b>
                </div>
              </div>
              <div>
                <div className="mb-[10px] text-[15px]">
                  <span>{i18next.t("income_currency")}:</span>
                  <b>USDT</b>
                </div>
                <div className="mb-[10px] text-[15px]">
                  <span>{i18next.t("investment_period")}:</span>
                  <b>
                    {formatNumber(data?.date, "0,0.[00000]")} {i18next.t("day")}
                  </b>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSave)}>
              <div className="mt-2">
                <div className="sbui-formlayout__content-container-horizontal mt-2">
                  <div className="sbui-input-container input-fund">
                    <input
                      type="number"
                      inputMode="decimal"
                      min={0}
                      step={0.00000001}
                      className="sbui-input sbui-input--medium"
                      placeholder={`${formatNumber(
                        data?.min
                      )} USDT ~ ${formatNumber(data?.max)} USDT`}
                      {...register("amount")}
                    />
                    <button
                      type="button"
                      className="btn btn-all"
                      onClick={() => {
                        const temp = Number(get(user, "balance_web3", 0));
                        setValue("amount", temp);
                      }}
                    >
                      {i18next.t("total")}
                    </button>
                  </div>
                  <p className="mt-[10px] text-[13px] text-[#989898]">
                    {i18next.t("currently_available")}:{" "}
                    {formatNumber(user?.balance_web3)} USDT
                  </p>
                </div>
              </div>
              <div className="py-[15px] flex justify-between gap-4">
                <button
                  type="button"
                  onClick={() => onClose()}
                  className="btn btn-confirm !bg-[#c1c1c1] !mt-[0]"
                >
                  {i18next.t("cancel")}
                </button>
                <button
                  type="submit"
                  className="btn btn-confirm !bg-[#fbbc01] !mt-[0]"
                >
                  {i18next.t("confirm")}
                </button>
              </div>
            </form>
            <div className="mt-[10px] text-[14px] text-[#989898] font-[500]">
              <h5 className="text-[16px] font-bold text-[#000] mb-[10px]">
                {i18next.t("overview")}
              </h5>
              <div className="flex justify-between mb-[5px]">
                <span>{i18next.t("minimum_purchase_share")}</span>
                <span>{formatNumber(data?.min, "0,0.[00000]")} USDT</span>
              </div>
              <div className="flex justify-between mb-[5px]">
                <span>{i18next.t("purchase_limit_share")}</span>
                <span>{formatNumber(data?.max, "0,0.[00000]")} USDT</span>
              </div>
              <div className="flex justify-between mb-[5px]">
                <span>{i18next.t("remaining_copies_available")}</span>
                <span>{formatNumber(data?.max, "0,0.[00000]")} USDT</span>
              </div>
              <div className="flex justify-between mb-[5px]">
                <span>{i18next.t("effective_time")}</span>
                <span>{moment().format("YYYY-MM-DD HH:mm:ss")}</span>
              </div>
              <div className="flex justify-between mb-[5px]">
                <span>{i18next.t("redemption_time")}</span>
                <span>{moment().format("YYYY-MM-DD HH:mm:ss")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalFund;
