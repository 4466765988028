import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import UploadFile from "_common/component/UploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import { walletTypes } from "_common/constants/statusType";

export default function SelectAccount({ onClose, value, onChange }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  return (
    <div className="modal modal-full">
      <div className="mask" />
      <div className="modal-content">
        <div className="p-[20px]" onClick={() => onClose()}>
          <FontAwesomeIcon className="text-[20px]" icon={faAngleLeft} />
        </div>
        <div className="p-[20px]">
          <h2 className="text-black text-[22px] font-bold mb-[20px]">
            {i18next.t("select_account")}
          </h2>
          <div className="list-account">
            {Object.keys(walletTypes).map((item) => (
              <div
                className="flex justify-between py-[15px]"
                onClick={() => {
                  onChange(item);
                  onClose();
                }}
              >
                <span className="text-[#1e1e1e] font-[500] text-[16px]">
                  {i18next.t(walletTypes[item])}
                </span>
                {value === item && (
                  <FontAwesomeIcon
                    className="text-[#fbbc01] text-[16px]"
                    icon={faCheckCircle}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
