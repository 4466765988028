import React, { useEffect, useState } from "react";
import classNames from "classnames";

import {
  paymentMethodText,
  paymentStatusColor,
  paymentTypeColor,
  paymentAddType,
} from "_common/constants/statusType";
import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import NoData from "_common/component/NoData";

export default function ListData({ data }) {
  if (!data?.data?.length) return <NoData />;

  return (
    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
      <thead className="bg-gray-50 dark:bg-gray-800">
        <tr>
          <th
            scope="col"
            className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
          >
            {i18next.t("insert_at")}
          </th>
          <th
            scope="col"
            className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
          >
            {i18next.t("information")}
          </th>
          <th
            scope="col"
            className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
          >
            {i18next.t("status")}
          </th>
          <th
            scope="col"
            className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
          >
            {i18next.t("description")}
          </th>
        </tr>
      </thead>
      <tbody className="text-sm bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 md:text-base">
        {data?.data?.map((item) => (
          <tr
            key={item?.id}
            className="odd:bg-white dark:odd:bg-gray-800 even:bg-slate-50 dark:even:bg-gray-700"
          >
            <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
              <span className="text-xs sm:text-base">
                {moment(item?.created_at).format("HH:mm:ss DD/MM/YYYY")}
              </span>
            </td>
            <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
              <span
                className={classNames(
                  "text-xs sm:text-base whitespace-nowrap flex flex-col",
                  {
                    [paymentTypeColor[item?.type]]: true,
                  }
                )}
              >
                <span>{item?.content}</span>
                <span className="text-gray-900 dark:text-white">
                  ({paymentAddType[item?.type]}){" "}
                  {formatNumber(item?.final_total, "0,0.[000000]")}{" "}
                  {item?.token?.name}
                </span>
              </span>
            </td>
            <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
              <span
                className={classNames(
                  "whitespace-nowrap text-xs sm:text-base",
                  {
                    [paymentStatusColor[item?.status]]: true,
                  }
                )}
              >
                {i18next.t(item?.status)}
              </span>
            </td>
            <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
              <span className="max-w-[180px] block overflow-hidden whitespace-pre-line text-xs sm:text-base">
                {item?.note}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
