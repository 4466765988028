import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import i18next from "i18next";

import General from "./General";
import SpotAccount from "./SpotAccount";
import ContractAccount from "./ContractAccount";
import OptionAccount from "./OptionAccount";
import FiatAccount from "./FiatAccount";
import FinanceAccount from "./FinanceAccount";

const BalanceMobile = observer(() => {
  const [activeTab, setActiveTab] = useState("overview");
  return (
    <div className="wallet-page">
      <div className="tabs tab-bin">
        <div
          className={classNames("tab", {
            active: activeTab === "overview",
          })}
          onClick={() => setActiveTab("overview")}
        >
          <span>{i18next.t("overview")}</span>
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "spot",
          })}
          onClick={() => setActiveTab("spot")}
        >
          <span>{i18next.t("spot")}</span>
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "fiat",
          })}
          onClick={() => setActiveTab("fiat")}
        >
          <span>{i18next.t("fiat")}</span>
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "future",
          })}
          onClick={() => setActiveTab("future")}
        >
          <span>{i18next.t("futures")}</span>
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "option",
          })}
          onClick={() => setActiveTab("option")}
        >
          <span>{i18next.t("options")}</span>
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "finance",
          })}
          onClick={() => setActiveTab("finance")}
        >
          <span>{i18next.t("financial_account")}</span>
        </div>
        {/* <div
          className={classNames("tab", {
            active: activeTab === "forex",
          })}
          onClick={() => setActiveTab("forex")}
        >
          <span>{i18next.t("forex_account")}</span>
        </div> */}
      </div>
      {activeTab === "overview" && <General setActiveTab={setActiveTab} />}
      {activeTab === "spot" && <SpotAccount />}
      {activeTab === "fiat" && <FiatAccount />}
      {activeTab === "future" && <ContractAccount />}
      {activeTab === "option" && <OptionAccount />}
      {activeTab === "finance" && <FinanceAccount />}
      {/* {activeTab === "forex" && <ForexAccount />} */}
    </div>
  );
});

export default BalanceMobile;
