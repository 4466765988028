import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronRight,
  faDollarSign,
  faEye,
  faEyeSlash,
  faLocationDot,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import i18next from "i18next";

import icon40 from "assets/img/icon/icon40.png";
import LeftNav from "pages/User/LeftNav";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";

import SpotAccount from "./SpotAccount";
import ContractAccount from "./ContractAccount";
import OptionAccount from "./OptionAccount";
import FiatAccount from "./FiatAccount";
import FinanceAccount from "./FinanceAccount";

const BalanceDesktop = observer(() => {
  const {
    authStore: { user, display_balance, setDisplayBalance },
  } = useStores();

  const [activeTab, setActiveTab] = useState("spot");

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div className="bg-[#f8f8f8]">
              <div className="title">
                <h3 className="text-[#fbbc01]">{i18next.t("balance")}</h3>
              </div>
              <div className="current-num mb-[20px]">
                <div>
                  <div className="text-[18px] font-bold mb-[10px]">
                    {i18next.t("total_balance")}
                    <FontAwesomeIcon
                      icon={display_balance ? faEye : faEyeSlash}
                      onClick={() => setDisplayBalance(!display_balance)}
                      className="ml-[16px] text-[16px] text-[#fbbc01]"
                    />
                  </div>
                  <div className="flex items-center font-[500]">
                    <div className="text-[#fbbc01] font-[700] text-[24px]">
                      {formatBalance(user?.balance, display_balance)} USDT
                    </div>
                    <div>/</div>
                    <div>
                      {formatBalance(user?.balance, display_balance)} USD
                    </div>
                  </div>
                </div>
                <div className="flex gap-2">
                  <Link to="/member/wallet" type="button" className="huazhuan">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <span>{i18next.t("manage_payment_methods")}</span>
                  </Link>
                  <Link
                    to="/member/transfer"
                    type="button"
                    className="huazhuan"
                  >
                    <FontAwesomeIcon icon={faDollarSign} />
                    <span>{i18next.t("transfer")}</span>
                  </Link>
                </div>
              </div>
              <div className="tabs tab-box">
                <div
                  className={classNames("tab", {
                    active: activeTab === "spot",
                  })}
                  onClick={() => setActiveTab("spot")}
                >
                  {i18next.t("spot")}
                </div>
                <div
                  className={classNames("tab", {
                    active: activeTab === "fiat",
                  })}
                  onClick={() => setActiveTab("fiat")}
                >
                  {i18next.t("french_currency")}
                </div>
                <div
                  className={classNames("tab", {
                    active: activeTab === "feature",
                  })}
                  onClick={() => setActiveTab("feature")}
                >
                  {i18next.t("futures")}
                </div>
                <div
                  className={classNames("tab", {
                    active: activeTab === "option",
                  })}
                  onClick={() => setActiveTab("option")}
                >
                  {i18next.t("options")}
                </div>
                <div
                  className={classNames("tab", {
                    active: activeTab === "finance",
                  })}
                  onClick={() => setActiveTab("finance")}
                >
                  {i18next.t("financial")}
                </div>
              </div>
              {activeTab === "spot" && <SpotAccount />}
              {activeTab === "fiat" && <FiatAccount />}
              {activeTab === "future" && <ContractAccount />}
              {activeTab === "option" && <OptionAccount />}
              {activeTab === "finance" && <FinanceAccount />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BalanceDesktop;
