import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { get } from "lodash";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import classNames from "classnames";
import { buttons, leverList } from "_common/constants/statusType";

import ModalBet from "../Mobile/ModalBet";

const rate = 0.001;

const GameBoard = observer(({ game_id, data_price }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();

  const [last_price, setLastPrice] = useState(get(data_price, "lastPrice", 0));
  const [game_type, setGameType] = useState("long");
  const [bet_type, setBetType] = useState("market");
  const [lever, setLever] = useState(50);
  const [amount, setAmount] = useState(0);
  const [loss_price, setLossPrice] = useState();
  const [profit_price, setProfitPrice] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [amount_type, setAmountType] = useState();

  const finalTotal = useMemo(() => (1000 / lever) * amount, [amount, lever]);

  const openEstAmount = useMemo(() => {
    const tradingBalance = Number(user?.balance_trading);
    return Math.floor(tradingBalance / (1000 / lever));
  }, [user, lever]);

  const handleChangeInput = (event) => {
    if (!event?.target?.value) {
      setAmount();
    } else {
      setAmount(Number(event?.target?.value));
    }
  };

  useEffect(() => {
    setLastPrice(Number(get(data_price, "lastPrice", 0)));
  }, [data_price]);

  return (
    <div className="w-full ">
      <div className="w-full">
        <p className="text-[12px]">
          <span className="text-[#bdbdbd]">{i18next.t("available")}</span>
          <b className="text-white ml-[5px]">
            {formatNumber(user?.balance_trading, "0,0.[00000]")} USDT
          </b>
        </p>
        <div className="cus-select mt-[10px]">
          <span>{i18next.t("multiple")}</span>
          <select value={lever} onChange={(e) => setLever(e?.target?.value)}>
            {leverList?.map((item) => (
              <option value={item?.value} onClick={() => setLever(item?.value)}>
                {item?.text}
              </option>
            ))}
          </select>
        </div>
        <div className="flex gap-3 mt-[15px]">
          <div
            className={`formbox_op_list jy-btn ${
              bet_type === "limit" && "btn_bg_color"
            }`}
            onClick={() => setBetType("limit")}
          >
            {i18next.t("limit")}
          </div>
          <div
            className={`formbox_op_list jy-btn ${
              bet_type === "market" && "btn_bg_color"
            }`}
            onClick={() => setBetType("market")}
          >
            {i18next.t("market")}
          </div>
          <div
            className={`formbox_op_list jy-btn ${
              bet_type === "plan_price" && "btn_bg_color"
            }`}
            onClick={() => setBetType("plan_price")}
          >
            {i18next.t("plan_price_limit")}
          </div>
        </div>
        <div className="tradebox_l !w-full !p-[0]">
          <div className="formbox">
            {bet_type === "limit" && (
              <div className="input-item ">
                <div className="input-group">
                  <input
                    type="number"
                    inputMode="decimal"
                    step={0.00000001}
                    placeholder={i18next.t("market_optimal_price")}
                    onChange={(e) => setLastPrice(e?.target?.value)}
                    value={Number(last_price)}
                  />
                  <span>USDT</span>
                </div>
              </div>
            )}
            {bet_type === "market" && (
              <div className="input-item ">
                <div className="input-group">
                  <input
                    type="text"
                    value={i18next.t("market_price_text")}
                    readOnly
                  />
                </div>
              </div>
            )}

            <div className="input-item  mt-[15px]">
              <div className="input-group">
                <input
                  type="number"
                  inputMode="decimal"
                  placeholder="Amount"
                  step={0.00000001}
                  value={amount}
                  onChange={handleChangeInput}
                />
                <span onClick={() => setAmount(Number(user?.balance_trading))}>
                  {i18next.t("pies")}
                </span>
              </div>
            </div>
            <div className="mt-[4px] text-[#bdbdbd] text-[11px]">
              ={formatNumber(rate * amount, "0,0.000")} BTC (1
              {i18next.t("pies")}={formatNumber(rate, "0,0.000")}BTC)
            </div>
            <div className="quantity-type mb-[15px]">
              {buttons.map((item) => (
                <button
                  key={`num-${item.toString()}`}
                  type="button"
                  className={classNames("btn", {
                    active: amount_type === item,
                  })}
                  onClick={() => {
                    setAmountType(item);
                    setAmount(Number(Math.floor(openEstAmount * (item / 100))));
                  }}
                >
                  {item}%
                </button>
              ))}
            </div>
            <button
              type="button"
              onClick={() => {
                setGameType("long");
                setOpenModal(true);
              }}
              className="w-full h-[36px] leading-[36px] text-white text-center !bg-[#01bc9b] rounded-[20px] mt-[10px]"
            >
              <span className="f12 !text-white">
                {i18next.t("buy")}/{i18next.t("open_long")}
              </span>
            </button>
            <button
              type="button"
              onClick={() => {
                setGameType("short");
                setOpenModal(true);
              }}
              className="w-full h-[36px] leading-[36px] text-white text-center !bg-[#e54b4b] rounded-[20px] mt-[10px]"
            >
              {i18next.t("sell")}/{i18next.t("open_sell")}
            </button>
            <div className="mt-[10px] text-[#bdbdbd] text-[11px]">
              <span>{i18next.t("open")}</span>
              <b className="ml-[5px] text-white">
                {formatNumber(openEstAmount, "0,0.[0000]")}
                {i18next.t("pies")}
              </b>
            </div>
            <div className="mt-[10px] text-[#bdbdbd] text-[11px]">
              <span>{i18next.t("usable")}</span>
              <b className="ml-[5px] text-white">
                {formatNumber(finalTotal, "0,0.[0000]")}USDT
              </b>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            game_id,
            type: "contract",
            mode: bet_type,
            lever,
            game_type,
            amount,
            lastPrice: last_price,
            final_total: finalTotal,
            profit_price,
            loss_price,
          }}
        />
      )}
    </div>
  );
});

export default GameBoard;
