import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import i18next from "i18next";

import GameHeader from "./GameHeader";
import GameChart from "./GameChart";
import HistoryPrice from "./HistoryPrice";
import GameBoard from "./GameBoard";
import HistoryTrade from "./HistoryTrade";

const ContractDesktop = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const navigate = useNavigate();
  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const theme = params?.get("theme") || "dark";

  const [game_id, setGameId] = useState(games[0]?.code);
  const [isLight, setIsLight] = useState(false);

  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    if (!theme) return;
    setIsLight(theme === "light");
  }, [theme]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <div className="css-1wr4jig pt-[80px] !bg-[#151617] !border-t-[1px] !border-[#151617]">
      <div className="container">
        <div className="flex gap-[10px]">
          <div className="flex-1">
            <GameHeader game_id={game_id} data_price={priceBtc} />
            <div className="flex gap-[10px] mt-[10px]">
              <div className="flex-1">
                <GameChart game_id={game_id} isLight={isLight} />
              </div>
              <div className="w-[250px] h-[550px]  bg-[#1a1b1c]">
                <HistoryPrice data_price={priceBtc} />
              </div>
            </div>
          </div>
          <div className="w-[250px] h-[610px] pl-[10px] float-left  bg-[#1a1b1c]">
            <div className="w-full px-[15px]">
              <div className="h-[40px] leading-[40px] px-[5px]">
                <span className="f14 text-[#fbbc01] font-bold">
                  {i18next.t("order")}
                </span>
              </div>
              <div className="w-full mb-[10px]">
                <div className="curent-time">
                  <p className="text-[10px] text-[#bdbdbd]">
                    {i18next.t("time")}
                  </p>
                  <p className="text-[#2e96f6] font-bold">
                    {moment().format("YYYY-MM-DD HH:mm:ss")}
                  </p>
                </div>
              </div>
              <div className="w-full">
                <GameBoard game_id={game_id} data_price={priceBtc} />
              </div>
            </div>
          </div>
        </div>
        <HistoryTrade data_price={priceBtc} />
      </div>
    </div>
  );
});

export default ContractDesktop;
