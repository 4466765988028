import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import w1 from "assets/img/why/w1.png";
import w2 from "assets/img/why/w2.png";
import w3 from "assets/img/why/w3.png";
import w4 from "assets/img/why/w4.png";
import w5 from "assets/img/why/w5.png";
import i18next from "i18next";

const Why = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { isMobile } = useMediaQuery();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="why">
      <p className="why-title">{i18next.t("tit12")}</p>
      <ul className="why-item">
        <li>
          <img src={w1} alt="" className="why-item-image" />
          <p className="why-item-title">{i18next.t("tit13")}</p>
          <p className="why-item-desc">{i18next.t("tit14")}</p>
          <p className="why-item-desc">{i18next.t("tit15")}</p>
          <p className="why-item-desc">{i18next.t("tit16")}</p>
        </li>
        <li>
          <img src={w2} alt="" className="why-item-image" />
          <p className="why-item-title">{i18next.t("tit17")}</p>
          <p className="why-item-desc">{i18next.t("tit18")}</p>
          <p className="why-item-desc">{i18next.t("tit19")}</p>
          <p className="why-item-desc">{i18next.t("tit20")}</p>
        </li>
        <li>
          <img src={w3} alt="" className="why-item-image" />
          <p className="why-item-title">{i18next.t("tit21")}</p>
          <p className="why-item-desc">{i18next.t("tit22")}</p>
          <p className="why-item-desc">{i18next.t("tit23")}</p>
          <p className="why-item-desc">{i18next.t("tit24")}</p>
        </li>
        <li>
          <img src={w4} alt="" className="why-item-image" />
          <p className="why-item-title">{i18next.t("tit25")}</p>
          <p className="why-item-desc">{i18next.t("tit26")}</p>
          <p className="why-item-desc">{i18next.t("tit27")}</p>
          <p className="why-item-desc">{i18next.t("tit28")}</p>
        </li>
        <li>
          <img src={w5} alt="" className="why-item-image" />
          <p className="why-item-title">{i18next.t("tit29")}</p>
          <p className="why-item-desc">{i18next.t("tit30")}</p>
          <p className="why-item-desc">{i18next.t("tit31")}</p>
          <p className="why-item-desc">{i18next.t("tit32")}</p>
        </li>
      </ul>
    </div>
  );
});

export default Why;
