import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import i18next from "i18next";
import { PairApi } from "states/api/pair";

const Currency = observer(({ onClose, quote }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { usdtCoins },
  } = useStores();

  const { data: listCurrency, refetch: onGetList } = useQuery(
    ["pairs", "list", quote],
    () =>
      PairApi.getList({
        params: {
          page: 1,
          limit: 50,
          quote,
          transaction_status: 1,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!quote) return;
    onGetList();
  }, [quote]);

  const coinListPrice = useMemo(
    () =>
      listCurrency?.data?.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return {
          ...item,
          ...price,
          priceChangePercent: item?.percent || price?.priceChangePercent,
          volume: item?.volume || price?.volume,
          lastPrice: item?.price || price?.lastPrice,
          symbol: item?.code,
        };
      }),
    [listCurrency, data]
  );

  return (
    <div className="coin-list">
      <div className="list">
        <div className="h-[100vh] overflow-y-scroll">
          <div className="item !bg-[transparent]">
            <div className="col1">
              <div className="flex items-center gap-2">{i18next.t("pair")}</div>
            </div>
            <div className="col2 !justify-center">
              {i18next.t("latest_price")}
            </div>
            <div className="col3">{i18next.t("change")}</div>
          </div>
          {coinListPrice?.map((item) => (
            <div
              onClick={() => {
                navigate(`/spot?symbol=${item?.symbol}`);
                onClose();
              }}
              className="item !bg-[transparent]"
              key={item?.id}
            >
              <div className="col1">
                <div className="flex items-center gap-2">
                  <p className="name">{item?.name}</p>
                </div>
              </div>
              <div className="col2 !justify-center">
                <span
                  className={classNames({
                    "text-green": item?.priceChangePercent > 0,
                    "text-red": item?.priceChangePercent <= 0,
                  })}
                >
                  <b>{formatNumber(item?.lastPrice, "0,0.[00000]")}</b>
                </span>
              </div>
              <div className="col3">
                <span
                  className={classNames({
                    "text-green": item?.priceChangePercent > 0,
                    "text-red": item?.priceChangePercent <= 0,
                  })}
                >
                  <b>{formatNumber(item?.priceChangePercent, "0,0.[00]")}%</b>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Currency;
