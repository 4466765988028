import React from "react";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import classNames from "classnames";
import HeaderDesktop from "../Header/HeaderDesktop";
import DesktopFooter from "../Footer/DesktopFooter";

const UnAuthLayout = observer(() => {
  const { isMobile } = useMediaQuery();
  if (isMobile)
    return (
      <div className="mobile-layout">
        <Outlet />
      </div>
    );

  return (
    <div className="tw-dark desktop-layout">
      <div className="desktop-layout font-['Roboto']  text-white w-full h-full">
        <HeaderDesktop />
        <div className="flex w-full h-full">
          <div className="flex flex-col w-full h-screen max-h-full min-h-full transition-all desktop-body">
            <div className={classNames("sbui-loading ")}>
              <div className="loading-mask" />
              <div className="sbui-loading-content">
                <Outlet />
                <DesktopFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UnAuthLayout;
