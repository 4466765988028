import React, { useEffect } from "react";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { UserWalletApi } from "states/api/wallet";
import moment from "moment";

export default function WalletAddressMobile() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { setAddressSelect },
  } = useStores();

  const [params] = useSearchParams();

  const type = params?.get("type");

  const { data, refetch } = useQuery(
    ["user_wallet", "list"],
    () =>
      UserWalletApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container mt-10">
      <HeaderTop title={i18next.t("manage_payment_methods")} />
      <div className="pt-[25px]">
        {data?.data?.length > 0 && (
          <div className="list-bank-info px-2 ">
            {data?.data?.map((item) => (
              <div
                key={item?.id}
                className="item card-note mt-2"
                onClick={() => {
                  if (type === "select") {
                    setAddressSelect(item);
                    navigate(-1);
                  }
                }}
              >
                <div className=" flex  item-center gap-4 w-full mb-1">
                  <div className="text-gray-600">
                    <p>
                      <span className="text-uppercase">{item?.symbol}</span>
                    </p>
                    <p className="font-bold">{item?.address}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <button
        type="button"
        className="btn btn-add-wallet"
        onClick={() => navigate("/member/add-wallet")}
      >
        {i18next.t("add_wallet_address")}
      </button>
    </div>
  );
}
