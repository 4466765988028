import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChartSimple,
  faCog,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import GameBoard from "./GameBoard";
import HistoryPrice from "./HistoryPrice";
import HistoryTrade from "./HistoryTrade";
import ListCoin from "./ListCoin";

const SpotTradeMobilePage = observer(() => {
  const {
    authStore: { games, user, tokens },
  } = useStores();

  const navigate = useNavigate();

  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const [activeTab, setActiveTab] = useState("spot");

  const [openModal, setOpenModal] = useState(false);
  const [game_id, setGameId] = useState(games[0]?.code);
  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  const balanceMain = useMemo(() =>
    tokens?.find((obj) => obj?.network_name === game_id, [tokens, game_id])
  );

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <>
      <div className="feature-page spot-page">
        <div className="tabs">
          <div
            className={classNames("tab", {
              active: activeTab === "spot",
            })}
            onClick={() => setActiveTab("spot")}
          >
            {i18next.t("spot")}
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "otc",
            })}
            onClick={() => setActiveTab("otc")}
          >
            {i18next.t("buy_coin")}
          </div>
        </div>
        <div
          className="flex justify-between mt-[15px]"
          style={{ border: "none" }}
        >
          <div className="content_title_l tleft">
            <div className="mu-btn" onClick={() => setOpenModal(true)}>
              <FontAwesomeIcon icon={faBars} />
            </div>
            <span className="text-uppercase text-[18px] font-[700] text-black">
              {game_id?.split("usdt")?.[0]}/USDT
            </span>
          </div>
          <div className="content_title_r tright flex items-center justify-end">
            <Link
              to={`/market/detail?symbol=${game_id}&type=trade`}
              className="text-[#FBC501] text-[18px]"
            >
              <FontAwesomeIcon icon={faChartSimple} />
            </Link>
          </div>
        </div>
        <div className="flex">
          <div className="flex-1">
            <GameBoard
              game_id={game_id}
              data_price={priceBtc}
              balanceMain={balanceMain}
            />
          </div>
          <div className="flex-1">
            <HistoryPrice />
          </div>
        </div>
        <div className="flex items-center justify-between p-x-md pt-3">
          <div className="tabs tab-bin">
            <div className="tab active">
              <span>{i18next.t("open_order")}</span>
            </div>
          </div>

          <span onClick={() => navigate("/spot/order")}>
            <FontAwesomeIcon
              icon={faFileAlt}
              className="text-[#888] text-[18px]"
            />
          </span>
        </div>
        <HistoryTrade />
      </div>
      {openModal && (
        <ListCoin open={openModal} onClose={() => setOpenModal(false)} />
      )}
    </>
  );
});

export default SpotTradeMobilePage;
