import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong, faSearch } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import LoginComponent from "_common/component/LoginComponent";

import icon1 from "assets/icons/icon_recharge.svg";
import icon2 from "assets/icons/icon_invite.svg";
import icon3 from "assets/icons/icon_defi.svg";
import icon19 from "assets/icons/icon_chat.svg";
import icon4 from "assets/icons/icon_feedback.svg";

import ListCoin from "./ListCoin";
import HomeHeader from "./HomeHeader";

const MobileHome = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, user, games, authenticated },
  } = useStores();

  const handleKeydown = (event) => {
    if (event?.keyCode === 13) {
      const valueKey = event?.target?.value;
      console.log(valueKey);
    }
  };

  return (
    <div className="home-page">
      <HomeHeader />
      <div className="px-[15px]">
        <div className="search-container">
          <FontAwesomeIcon icon={faSearch} />
          <input
            onKeyDown={handleKeydown}
            type="text"
            placeholder={i18next.t("search")}
          />
        </div>
      </div>
      {!authenticated && <LoginComponent />}
      <div className="py-[5px] px-[15px] mt-[10px] text-[#000000]">
        <div className="w-full flex flex-col gap-4">
          <Link to="/member/recharge" className="quickly-buy long">
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <span className="text-[#FBC501] text-[18px] font-bold ">
                  {i18next.t("fast_buy")}
                </span>
                <FontAwesomeIcon
                  icon={faRightLong}
                  className="text-[#FBC501] text-[20px]"
                />
              </div>
              <div className="text-[13px] text-[#1e1e1e] font-[500]">
                {i18next.t("open_transaction")}
              </div>
            </div>
          </Link>
          {!user?.advanced_verify && (
            <div
              onClick={() => {
                if (!user?.advanced_verify) {
                  navigate("/member/kyc");
                }
              }}
              className="certify-box"
            >
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span className="text-[#FBC501] text-[18px] font-bold ">
                    {i18next.t("id_verification")}
                  </span>
                  <FontAwesomeIcon
                    icon={faRightLong}
                    className="text-[#FBC501] text-[20px]"
                  />
                </div>
                <div className="text-[13px] text-[#1e1e1e] font-[500]">
                  {i18next.t("kyc_tip")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="h-[200px]  rounded-[20px] py-[5px] px-[8px] text-[#000000]">
        <div className="w-full h-[80px] flex flex-wrap">
          <Link to="/member/recharge" className="w-[25%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={icon1} alt="" className="w-[50px] inline-block" />
              </div>
              <div className="optext">
                <span className="text-[15px] font-[500] ">
                  {i18next.t("deposit")}
                </span>
              </div>
            </div>
          </Link>
          <Link to="/member/sharing" className="w-[25%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={icon2} alt="" className="w-[50px] inline-block" />
              </div>
              <div className="optext">
                <span className="text-[15px] font-[500] ">
                  {i18next.t("invite")}
                </span>
              </div>
            </div>
          </Link>
          <Link to="/board/fund" className="w-[25%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={icon3} alt="" className="w-[50px] inline-block" />
              </div>
              <div className="optext">
                <span className="text-[15px] font-[500] ">
                  {i18next.t("defi")}
                </span>
              </div>
            </div>
          </Link>
          <Link to="/cskh" className="w-[25%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={icon19} alt="" className="w-[50px] inline-block" />
              </div>
              <div className="optext">
                <span className="text-[15px] font-[500] ">
                  {i18next.t("chat")}
                </span>
              </div>
            </div>
          </Link>
          <Link to="/cskh" className="w-[25%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={icon4} alt="" className="w-[50px] inline-block" />
              </div>
              <div className="optext">
                <span className="text-[15px] font-[500] ">
                  {i18next.t("feedback")}
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <ListCoin />
    </div>
  );
});

export default MobileHome;
