import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useMediaQuery } from "_common/component/MediaQueryProvider";
import ModalWelcome from "_common/component/ModalWelcome";

import MobileHome from "./MobileHome";
import DesktopHome from "./DesktopHome";

const HomeUnAuth = observer(() => {
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();
  const [open, setOpen] = useState(true);

  if (isMobile)
    return (
      <>
        <MobileHome />
        {open && <ModalWelcome onClose={() => setOpen(false)} />}
      </>
    );

  return (
    <>
      <DesktopHome />
      {open && <ModalWelcome onClose={() => setOpen(false)} />}
    </>
  );
});

export default HomeUnAuth;
