import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { FundApi } from "states/api/fund";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

import { Link, useNavigate } from "react-router-dom";

import ItemData from "./ItemData";

const FundDesktop = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const { data, refetch } = useQuery(
    ["funds", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="fund-detail-page">
      <div className="boximg">
        <div className="boxtitle">
          <article>
            <span>{i18next.t("pledge")}</span>
          </article>
          <h2>{i18next.t("earn_multiple_return")}</h2>
        </div>
      </div>
      <div className="container mt-5">
        <div className="w-full flex items-center justify-between mb-[20px]">
          <span className="text-black text-[18px] font-bold">
            {i18next.t("defi")}
          </span>
          <Link
            to="/board/fund/history"
            type="button"
            className="btn !w-[120px] !bg-[#fbbc01] flex items-center"
          >
            <FontAwesomeIcon icon={faFunnelDollar} />
            <span>{i18next.t("my_staking")}</span>
          </Link>
        </div>
        <table className="table-fund">
          <thead>
            <tr>
              <th>{i18next.t("mining_pool")}</th>
              <th>{i18next.t("staking_coin")}</th>
              <th>{i18next.t("income_currency")}</th>
              <th>{i18next.t("remaining_share")}</th>
              <th>{i18next.t("rate_of_return")}</th>
              <th>{i18next.t("staking_amount_range")}</th>
              <th>{i18next.t("investment_period")}</th>
              <th>{i18next.t("income_distribution")}</th>
              <th>{i18next.t("rebate_type")}</th>
              <th>{i18next.t("operate")}</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((item) => (
              <ItemData data={item} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default FundDesktop;
