import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";

const Option = observer(({ onClose }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  return (
    <div className="coin-list">
      <div className="list">
        <div className="h-[100vh] overflow-y-scroll">
          <div />
        </div>
      </div>
    </div>
  );
});

export default Option;
