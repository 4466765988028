import i18next from "i18next";
import React from "react";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import KycForm from "../KycForm";

export default function KYCMobile() {
  return (
    <div className="container !mt-[50px]">
      <HeaderTop title={i18next.t("kyc")} />
      <KycForm />
    </div>
  );
}
