import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import moment from "moment";

export default function TotalStation() {
  const {
    authStore: { trading_view },
  } = useStores();

  const { dataGame } = useNotify();

  function genRand(min, max) {
    return Math.random() * (max - min) + min;
  }

  const listBuy = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 22; index += 1) {
      result.push({
        price: beginPrice + index * alpha,
        volume: genRand(0, 1),
        type: Math.floor(Math.random() * 2) + 1,
        line: genRand(10, 90),
        time: moment().format("HH:mm:ss"),
      });
    }

    return result;
  }, [dataGame]);

  return (
    <div className="w-full">
      <div className="w-full h-[20px] px-[5px]">
        <div className="tradebox_title col-4 tcl">
          <span className="fccs f12">{i18next.t("time")}</span>
        </div>
        <div className="tradebox_title tcc col-4">
          <span className="fccs f12">{i18next.t("price")}</span>
        </div>
        <div className="tradebox_title col-4 tcr">
          <span className="fccs f12">{i18next.t("number")}</span>
        </div>
      </div>
      <div className="trade_listbox !h-full" id="tradesellbox">
        {listBuy.reverse().map((item, index) => (
          <div className="w-full h-[30px]" key={`buy-${index.toString()}`}>
            <div className="trade_list col-4 tcr">
              <span
                className={classNames("f12", {
                  red: item?.type === 1,
                  green: item?.type === 2,
                })}
              >
                {item?.time}
              </span>
            </div>
            <div className="trade_list col-4 tcl">
              <span
                className={classNames("f12", {
                  red: item?.type === 1,
                  green: item?.type === 2,
                })}
              >
                {formatNumber(item.price, "0,0.[00]")}
              </span>
            </div>
            <div className="trade_list col-4 tcc">
              <span
                className={classNames("f12", {
                  red: item?.type === 1,
                  green: item?.type === 2,
                })}
              >
                {formatNumber(item.volume, "0,0.[000000]")}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
