import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import BalanceMobile from "./Mobile";
import BalanceDesktop from "./Desktop";

const BalancePage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <BalanceMobile />;
  return <BalanceDesktop />;
});

export default BalancePage;
