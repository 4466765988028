import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";
import { buttons } from "_common/constants/statusType";

const ModalClosePosition = observer(({ open, onClose, data_price, data }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const [markPrice, setMarkPrice] = useState(data_price?.lastPrice);
  const [amount, setAmount] = useState(Number(data?.amount));
  const [mode, setMode] = useState("market_order");
  const [amount_type, setAmountType] = useState();

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.closePosition({
        id: data?.id,
        params: {
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "contract"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        setLoading(false);
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (loading) return null;
      if (!amount) throw i18next.t("enter_large_amount_msg");

      onSubmit({
        amount: data.amount,
        price: markPrice,
      });
      return null;
    } catch (error) {
      setLoading(false);
      return NotificationManager.error(error);
    }
  };

  useEffect(() => {
    if (mode === "market_order") {
      setMarkPrice(data_price?.lastPrice);
    }
  }, [data_price?.lastPrice, mode]);

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" />
        <div className="modal-content" style={{ width: "80%" }}>
          <div className="modal-body">
            <h5 className="text-center mb-[10px]">
              {i18next.t("close_position")}
            </h5>
            <div className="flex  items-center">
              <div className="flex-1">
                <p>{data?.game?.name}</p>
                <p
                  className={classNames({
                    "text-[#f5465c]": data?.bet_game === "short",
                    "text-[#60c08c]": data?.bet_game === "long",
                  })}
                >
                  {i18next.t(data?.bet_game)} {formatNumber(data?.lever)}X
                </p>
              </div>
              <div className="flex-1">
                <p className="text-center">{i18next.t("entry_price")}</p>
                <p className="text-center">
                  {formatNumber(data_price?.lastPrice, "0,0.[00000]")}
                </p>
              </div>
              <div className="flex-1">
                <p className="text-right">{i18next.t("mark_price")}</p>
                <p className="text-right">
                  {formatNumber(markPrice, "0,0.[00000]")}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2 mt-[15px]">
              <select
                className="w-[40%]"
                value={mode}
                onChange={(e) => setMode(e?.target?.value)}
              >
                <option
                  value="market_order"
                  onClick={() => setMode("market_order")}
                >
                  {i18next.t("market_order")}
                </option>
                <option
                  value="general_entrust"
                  onClick={() => setMode("general_entrust")}
                >
                  {i18next.t("general_entrust")}
                </option>
              </select>
              <div className="input-wrapper w-[60%]">
                {mode === "market_order" ? (
                  <input
                    className="input-control !w-full"
                    value={i18next.t("market_price")}
                  />
                ) : (
                  <div className="qty-input !w-full">
                    <button
                      type="button"
                      className="btn btn-add"
                      onClick={() =>
                        setMarkPrice(
                          markPrice > 1000 && Number(markPrice) - 1000
                        )
                      }
                    >
                      -
                    </button>
                    <input
                      placeholder={user?.balance}
                      type="number"
                      step={0.00000001}
                      inputMode="decimal"
                      className="sbui-input sbui-input--large"
                      min={0}
                      value={Number(markPrice)}
                      onChange={(e) => setMarkPrice(e?.target?.value)}
                    />
                    <button
                      type="button"
                      className="btn btn-add"
                      onClick={() => setMarkPrice(Number(markPrice) + 1000)}
                    >
                      +
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center flex-col mt-[15px]">
              <div className="input-wrapper w-full">
                <div className="qty-input ">
                  <button
                    type="button"
                    className="btn btn-add"
                    onClick={() =>
                      setAmount(amount > 1000 && Number(amount) - 1000)
                    }
                  >
                    -
                  </button>
                  <input
                    placeholder={formatNumber(data?.amount, "0,0.[00000]")}
                    type="number"
                    step={0.00000001}
                    inputMode="decimal"
                    className="sbui-input sbui-input--large"
                    min={0}
                    value={amount}
                    onChange={(e) => setAmount(e?.target?.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-add"
                    onClick={() => setAmount(Number(amount) + 1000)}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="quantity-type w-full">
                {buttons.map((item) => (
                  <button
                    key={`num-${item.toString()}`}
                    type="button"
                    className={classNames("btn !w-full", {
                      active: amount_type === item,
                    })}
                    onClick={() => {
                      const balance = Number(data?.amount);

                      setAmountType(item);
                      setAmount(Number((balance * item) / 100));
                    }}
                  >
                    {item}%
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button
                type="button"
                className="btn-cancel"
                onClick={() => onClose()}
              >
                {i18next.t("cancel")}
              </button>
              <button
                type="button"
                className="btn-save"
                onClick={() => handleSubmit()}
              >
                <span className="text-blue">{i18next.t("close_position")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalClosePosition;
