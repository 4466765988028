import React from "react";
import { useStores } from "_common/hooks";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import NotifyProvider from "_common/component/NotifyProvider";
import Maintenance from "_common/component/Maintenance";
import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";

const DigitalLayout = observer(() => {
  const {
    authStore: { logo, token, user, general },
  } = useStores();
  const { isMobile } = useMediaQuery();

  if (!logo) return <PageLoading />;
  if (Number(general?.is_maintenance) === 1) return <Maintenance />;

  if (isMobile)
    return (
      <NotifyProvider user={user}>
        <MobileLayout />
      </NotifyProvider>
    );

  return (
    <NotifyProvider user={user}>
      <DesktopLayout />
    </NotifyProvider>
  );
});

export default DigitalLayout;
