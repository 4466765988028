import React, { useEffect, useState } from "react";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import classNames from "classnames";
import { gameBetItemType } from "_common/constants/statusType";
import moment from "moment";
import { get } from "lodash";
import { GamePlayerApi } from "states/api/gamePlayer";
import { useQuery } from "@tanstack/react-query";
import NoData from "_common/component/NoData";
import LeftNav from "pages/User/LeftNav";
import { useSearchParams } from "react-router-dom";

export default function SpotHistoryMobile() {
  const [params] = useSearchParams();

  const type = params?.get("type");

  const [status, setStatus] = useState(type || "open");

  const { data, refetch } = useQuery(
    ["game-player", "spot", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          type: "spot",
          status,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    if (!type) return;
    setStatus(type);
  }, [type]);

  useEffect(() => {
    refetch();
  }, [status]);

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div className="bg-[#f8f8f8]">
              <div className="title">
                <h3 className="text-[#fbbc01]">{i18next.t("order")}</h3>
              </div>
              <div className="tabs tab-box">
                <div
                  className={classNames("tab", {
                    active: status === "open",
                  })}
                  onClick={() => setStatus("open")}
                >
                  {i18next.t("open_order")}
                </div>
                <div
                  className={classNames("tab", {
                    active: status === "all",
                  })}
                  onClick={() => setStatus("all")}
                >
                  {i18next.t("order_history")}
                </div>
              </div>
              <div className="bg-white py-[20px]  bx-[30px]">
                <table className="table-coin">
                  <thead>
                    <tr>
                      <th>
                        <div className="cell">{i18next.t("time")}</div>
                      </th>
                      <th>
                        <div className="cell">{i18next.t("pair")}</div>
                      </th>
                      <th>
                        <div className="cell">{i18next.t("direction")}</div>
                      </th>
                      <th>
                        <div className="cell">{i18next.t("price")}</div>
                      </th>
                      <th>
                        <div className="cell">{i18next.t("amount")}</div>
                      </th>
                      <th>
                        <div className="cell">{i18next.t("executed")}</div>
                      </th>
                      <th>
                        <div className="cell">{i18next.t("volume")}</div>
                      </th>
                      <th>
                        <div className="cell">{i18next.t("fee")}</div>
                      </th>
                      <th>
                        <div className="cell">{i18next.t("state")}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((item) => (
                      <tr key={item?.symbol}>
                        <td>
                          <div className="cell">
                            {item?.created_at &&
                              moment(item?.created_at).format(
                                "HH:mm:ss DD/MM/YYYY"
                              )}
                          </div>
                        </td>
                        <td>
                          <div className="cell font-bold uppercase">
                            {item?.game?.name}
                          </div>
                        </td>
                        <td>
                          <div className="cell">
                            <b
                              className={classNames({
                                "text-[#11c1a4]": item?.bet_game === "buy",
                                "text-[#ff2a2a]": item?.bet_game === "sell",
                              })}
                            >
                              {i18next.t(item?.bet_game)}
                            </b>
                          </div>
                        </td>
                        <td>
                          <div className="cell">
                            {formatNumber(item?.open, "0,0.[000000]")}
                          </div>
                        </td>
                        <td>
                          <div className="cell">
                            {formatNumber(item?.amount, "0,0.[000000]")}
                          </div>
                        </td>
                        <td>
                          <div className="cell">
                            {formatNumber(item?.amount, "0,0.[000000]")}
                          </div>
                        </td>
                        <td>
                          <div className="cell">
                            {formatNumber(
                              Number(item?.amount) * Number(item?.open),
                              "0,0.[000000]"
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="cell">
                            {formatNumber(item?.fee, "0,0.[000000]")}
                          </div>
                        </td>
                        <td>
                          <div className="cell">{i18next.t(item?.status)}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
