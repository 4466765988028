import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import MarketMobilePage from "./Mobile";
import MarketDesktopPage from "./Desktop";

const MarketPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <MarketMobilePage />;
  return (
    <div className="pt-[60px]">
      <div className="quote-page">
        <div className="quote-box">
          <MarketDesktopPage />
        </div>
      </div>
    </div>
  );
});

export default MarketPage;
