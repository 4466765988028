import React from "react";
import { Link } from "react-router-dom";
import { useStores } from "_common/hooks";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

export default function Security() {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="container !mt-[55px]">
      <HeaderTop title={i18next.t("change_password")} />
      <div className="no_content px-[15px]">
        <div className="no_inbox">
          <Link to="/member/password">
            <div className="lbox">
              <div className="lbox_l">
                <span className="fcc fe6im fzmmm !text-[14px]">
                  {i18next.t("password")}
                </span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
              </div>
            </div>
          </Link>
          <Link to="/member/wallet-password">
            <div className="lbox">
              <div className="lbox_l">
                <span className="fcc fzmmm fe6im !text-[14px]">
                  {i18next.t("wallet_password")}
                </span>
              </div>
              <div className="lbox_r">
                <span className="f12  rzred">{i18next.t("setting")}</span>
                <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
              </div>
            </div>
          </Link>
          <Link to="/member/wallet">
            <div className="lbox">
              <div className="lbox_l">
                <span className="fcc fe6im fzmmm !text-[14px]">
                  {i18next.t("wallet_address")}
                </span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
