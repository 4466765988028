import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import UploadFile from "_common/component/UploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import QRCode from "react-qr-code";
import classNames from "classnames";
import { walletTypes } from "_common/constants/statusType";
import iconSwap from "assets/icons/icon_swap.svg";
import LeftNav from "pages/User/LeftNav";
import SelectAccount from "../Mobile/SelectAccount";

export default function TransferDesktop() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { pushNotify } = useNotify();

  const schema = yup
    .object({
      amount: yup.number().required(i18next.t("verify_require")),
    })
    .required();

  const [from_wallet, setFromWallet] = useState("amount");
  const [to_wallet, setToWallet] = useState("trading_amount");
  const [openModalFromAccount, setOpenModalFromAccount] = useState();
  const [openModalToAccount, setOpenModalToAccount] = useState();

  const {
    authStore: { user, general, banks, game_wallet, coins },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.transfer({
        params: {
          ...variables,
          symbol: "usdt",
          to: to_wallet,
          from: from_wallet,
          type: "transfer",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
        pushNotify({
          type: "transfer",
        });
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    onSubmit({ ...values });
    return null;
  };

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div>
              <div className="title">
                <h3>{i18next.t("transfer")}</h3>
              </div>
              <form
                className="imgbox !w-[427px] mx-auto"
                onSubmit={handleSubmit(onSave)}
              >
                <div className="mode-style">
                  <div className="group">
                    <div
                      className="item"
                      onClick={() => setOpenModalFromAccount("from")}
                    >
                      <span className="flex items-center text-[16px]">
                        <div className="poin" />
                        <span className="ml-[10px]">{i18next.t("from")}</span>
                        <b className="text-black font-bold ml-[15px]">
                          {i18next.t(walletTypes[from_wallet])}
                        </b>
                      </span>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                    <div
                      className="item"
                      onClick={() => setOpenModalToAccount("to")}
                    >
                      <span className="flex items-center text-[16px]">
                        <div className="poin !bg-[#fbbc01]" />
                        <span className=" ml-[10px]">{i18next.t("to")}</span>
                        <b className="text-black font-bold ml-[15px]">
                          {i18next.t(walletTypes[to_wallet])}
                        </b>
                      </span>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                  </div>
                  <div
                    className="swap"
                    onClick={() => {
                      setFromWallet(to_wallet);
                      setToWallet(from_wallet);
                    }}
                  >
                    <img src={iconSwap} alt="" />
                  </div>
                </div>
                <div>
                  <div className="zz-select my-[15px]">
                    <select className="zz_input">
                      <option>USDT</option>
                    </select>
                    <span>
                      {i18next.t("select_coin")}
                      <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                  </div>
                  <div className="mb-[10px]">
                    <span className="text-black text-[15px] font-bold ">
                      {i18next.t("amount_3")}
                    </span>
                  </div>
                  <div className="zz_inputbox">
                    <input
                      type="number"
                      inputMode="decimal"
                      min={0}
                      step={0.000001}
                      className="zz_input"
                      placeholder={i18next.t("amount_3")}
                      {...register("amount")}
                    />
                    <span className="text-[#A5A5A5]">USDT</span>
                    <span className="px-[10px] text-[#A5A5A5]">|</span>
                    <span
                      className="text-black"
                      onClick={() => {
                        if (from_wallet === "amount")
                          setValue("amount", Number(user?.balance));
                        if (from_wallet === "balance_option")
                          setValue("amount", Number(user?.balance_option));
                        if (from_wallet === "trading_amount")
                          setValue("amount", Number(user?.balance_trading));
                      }}
                    >
                      All
                    </span>
                  </div>
                  <div className="h-[40px] flex justify-between items-center">
                    <div className="w-[50%] h-[40px] leading-[40px]">
                      <span className="text-[13px] text-[#888]">
                        {i18next.t("available")}:{" "}
                      </span>
                      <span className="text-[13px] text-[#888] font-bold">
                        {from_wallet === "amount" &&
                          formatNumber(user?.balance, "0,0.0000")}
                        {from_wallet === "option_amount" &&
                          formatNumber(user?.balance_option, "0,0.0000")}
                        {from_wallet === "trading_amount" &&
                          formatNumber(user?.balance_trading, "0,0.0000")}
                      </span>
                    </div>
                  </div>
                  <div className="imgbox_8 mt-[10px]">
                    <div className="imgbox_10" id="sumbtn">
                      <button type="submit" className="btn w-full uppercase">
                        {i18next.t("transfer")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {openModalFromAccount && (
        <SelectAccount
          onClose={() => setOpenModalFromAccount()}
          value={from_wallet}
          onChange={(value) => {
            if (value === to_wallet) {
              setToWallet(from_wallet);
            }
            setTimeout(() => {
              setFromWallet(value);
            }, 100);
            setValue("amount", "");
          }}
        />
      )}
      {openModalToAccount && (
        <SelectAccount
          onClose={() => setOpenModalToAccount()}
          value={to_wallet}
          onChange={(value) => {
            if (value === from_wallet) {
              setFromWallet(to_wallet);
            }
            setTimeout(() => {
              setToWallet(value);
            }, 100);
            setValue("amount", "");
          }}
        />
      )}
    </div>
  );
}
