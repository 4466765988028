import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { FundApi } from "states/api/fund";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import HeaderTop from "_common/Layout/Header/HeaderTop";

import { useNavigate } from "react-router-dom";

import ListData from "./ListData";

const FundMobile = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const { data, refetch } = useQuery(
    ["funds", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container fund-detail-page">
      <HeaderTop
        right={
          <span
            onClick={() => navigate("/board/fund/history")}
            className="text-[#fbc501] font-bold text-[16px]"
          >
            {i18next.t("my_staking")}
          </span>
        }
      />
      <h5 className="px-[20px] font-bold text-[26px]">{i18next.t("defi")}</h5>
      <div className=" mt-5">
        <ListData data={data?.data || []} />
      </div>
    </div>
  );
});

export default FundMobile;
