import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import {
  faExchange,
  faFileAlt,
  faSearch,
  faWallet,
  faYenSign,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";
import { PriceApi } from "states/api/price";

import iconDeposit from "assets/icons/icon_deposit.svg";
import iconWithdraw from "assets/icons/icon_withdraw.svg";
import iconTransfer from "assets/icons/icon_transfer.svg";
import iconRecord from "assets/icons/icon_record.svg";

const SpotAccount = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { tokens, user, display_balance },
  } = useStores();

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  const [listCoin, setListCoin] = useState([]);
  const [btcBalance, setBTCBalance] = useState({
    btc: 0,
    usdt: 0,
    spot: 0,
    trading: 0,
    option: 0,
  });

  const { data, refetch } = useQuery(
    ["token_price", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 20,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let totalBTC = 0;
    let totalUSDT = 0;
    let totalSpotUSDT = 0;
    let totalTradingUSDT = 0;
    let totalOptionUSDT = 0;
    const btcPrice = data?.find((obj) => obj?.symbol === "BTCUSDT")?.lastPrice;
    const cloneList = tokens?.map((item) => {
      const coin = data?.find(
        (obj) => obj?.symbol?.toLowerCase() === item?.network_name
      );
      let lastPrice = Number(get(coin, "lastPrice", 0));
      if (item?.symbol === "usdt") {
        lastPrice = 1;
        const amountBalance = Number(get(item, "amount", 0));
        const tradingBalance = Number(get(item, "trading_amount", 0));
        const optionBalance = Number(get(item, "option_amount", 0));
        totalBTC += tradingBalance / btcPrice;
        totalBTC += optionBalance / btcPrice;
        totalUSDT += amountBalance;
        totalUSDT += tradingBalance;
        totalUSDT += optionBalance;
        totalTradingUSDT += tradingBalance;
        totalOptionUSDT += tradingBalance;
      }
      const convertUsdt = Number(get(item, "amount", 0)) * lastPrice;
      const convertBTC = convertUsdt / btcPrice;
      totalBTC += convertBTC;
      totalSpotUSDT += convertUsdt;

      return {
        ...item,
        lastPrice,
        convert_amount: convertUsdt,
        btcPrice: Number(btcPrice),
        convert_btc: Number(convertBTC).toFixed(6),
      };
    });

    setListCoin(cloneList);
    setBTCBalance({
      btc: totalBTC,
      usdt: totalUSDT,
      spot: totalSpotUSDT,
      trading: totalTradingUSDT,
      option: totalOptionUSDT,
    });
  }, [tokens, data]);

  return (
    <div className="spot-account">
      <div className="flex justify-between py-[20px] px-[30px]">
        <div>
          <div className="mb-[10px]">{i18next.t("exchange_balances")}:</div>
          <div className="flex">
            <div className="font-bold">
              {formatBalance(btcBalance?.btc, display_balance)} BTC
            </div>
            <div>/</div>
            <div>{formatBalance(btcBalance?.usdt, display_balance)} USD</div>
          </div>
        </div>
        <div>
          <div className="mb-[10px]">24H {i18next.t("amount")}:</div>
          <div className="flex">
            <div className="font-bold">50/50 BTC</div>
            <button type="button" className="btn-asset change ml-[17px]">
              {i18next.t("increase_credit_limit")}
            </button>
          </div>
        </div>
        <div>
          <div className="search-container">
            <FontAwesomeIcon icon={faSearch} />
            <input placeholder="Search" />
          </div>
        </div>
        <div>
          <div className="text-[#2c3e50] font-bold leading-[40px]">
            <span>{i18next.t("hide_small_balances")}</span>
          </div>
        </div>
      </div>
      <div className="w-full px-[30px]">
        <table className="table-coin">
          <thead>
            <tr>
              <th>
                <div className="cell">{i18next.t("coin")}</div>
              </th>
              <th>
                <div className="cell">{i18next.t("amount")}</div>
              </th>
              <th>
                <div className="cell">{i18next.t("available")}</div>
              </th>
              <th>
                <div className="cell">{i18next.t("on_order")}</div>
              </th>
              <th>
                <div className="cell">{i18next.t("lock_up")}</div>
              </th>
              <th>
                <div className="cell">{i18next.t("total_balance")}(BTC)</div>
              </th>
              <th>
                <div className="cell">{i18next.t("operation")}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {listCoin?.map((item) => (
              <tr key={item?.symbol}>
                <td>
                  <div className="cell font-bold">{item?.name}</div>
                </td>
                <td>
                  <div className="cell">
                    {formatBalance(item?.amount, display_balance)}
                  </div>
                </td>
                <td>
                  <div className="cell">
                    {formatBalance(item?.amount, display_balance)}
                  </div>
                </td>
                <td>
                  <div className="cell">
                    {formatBalance(item?.order_amount, display_balance)}
                  </div>
                </td>
                <td>
                  <div className="cell">
                    {formatBalance(item?.pending_amount, display_balance)}
                  </div>
                </td>
                <td>
                  <div className="cell">
                    {formatBalance(item?.convert_amount, display_balance)}
                  </div>
                </td>
                <td>
                  <div className="cell flex w-full">
                    <Link
                      to={`/member/deposit?symbol=${item?.symbol}`}
                      className="link mr-[12px]"
                    >
                      {i18next.t("deposit")}
                    </Link>
                    <Link
                      to={`/member/withdraw/submit?symbol=${item?.symbol}`}
                      className="link mr-[12px]"
                    >
                      {i18next.t("withdraw")}
                    </Link>
                    <Link to="/board/spot" className="link mr-[12px]">
                      {i18next.t("trade")}
                    </Link>
                    <div className="link">{i18next.t("record")}</div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default SpotAccount;
