import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import WalletAddressAddMobile from "./Mobile";
import WalletAddressAddDesktop from "./Desktop";

const WalletAddressAddAdd = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <WalletAddressAddMobile />;
  return <WalletAddressAddDesktop />;
});

export default WalletAddressAddAdd;
