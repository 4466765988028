import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";

export default function HistoryPrice({ game_id }) {
  const {
    authStore: { trading_view },
  } = useStores();

  const { dataGame } = useNotify();

  function genRand(min, max) {
    return Math.random() * (max - min) + min;
  }

  const listBuy = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 6; index += 1) {
      result.push({
        price: beginPrice + index * alpha,
        volume: genRand(0, 1),
        line: genRand(10, 90),
      });
    }

    return result;
  }, [dataGame]);

  const listSell = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 6; index += 1) {
      result.push({
        price: beginPrice - index * alpha,
        volume: genRand(0, 1),
        line: genRand(10, 90),
      });
    }

    return result;
  }, [dataGame]);

  return (
    <div className="price-history flex gap-5 w-full">
      <div className="flex-1">
        <div className="item">
          <div className="wrapper">
            <div>
              <p className="!text-[#707a8a]">{i18next.t("price")}</p>
            </div>
            <div>
              <p className="!text-[#707a8a]">{i18next.t("amount")}</p>
            </div>
          </div>
        </div>
        {listSell.map((item, index) => (
          <div className="item sell" key={`sell-${index.toString()}`}>
            <div className="line" style={{ width: `${item?.line}%` }} />
            <div className="wrapper">
              <div>
                <p className="text-red">
                  {formatNumber(item.price, "0,0.[00]")}
                </p>
              </div>
              <div>
                <p className="!text-[#868d9a]">
                  {formatNumber(item.volume, "0,0.[000000]")}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex-1">
        <div className="item">
          <div className="wrapper">
            <div>
              <p className="!text-[#707a8a]">{i18next.t("price")}</p>
            </div>
            <div>
              <p className="!text-[#707a8a]">{i18next.t("amount")}</p>
            </div>
          </div>
        </div>
        {listBuy.reverse().map((item, index) => (
          <div className="item buy" key={`buy-${index.toString()}`}>
            <div className="line" style={{ width: `${item?.line}%` }} />
            <div className="wrapper">
              <div>
                <p className="text-green">
                  {formatNumber(item.price, "0,0.[00]")}
                </p>
              </div>
              <div>
                <p className="!text-[#868d9a]">
                  {formatNumber(item.volume, "0,0.[000000]")}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
