import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";

import Banner from "./Banner";
import Why from "./Why";
import MarketPrice from "./MarketPrice";
import News from "./News";
import Trade from "./Trade";

const DesktopHome = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <main className="css-1wr4jig">
      <Banner />
      <div className="regist-now">
        <h3>{i18next.t("home_title3")}</h3>
        <h4>{i18next.t("home_title4")}</h4>
      </div>
      <div className="css-194m5n4" style={{ paddingTop: 30 }}>
        <MarketPrice />
      </div>
      <Why />
      <News />
      <Trade />
    </main>
  );
});

export default DesktopHome;
