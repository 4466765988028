/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import classNames from "classnames";

function RegisterDesktop() {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const [params] = useSearchParams();

  const schema = yup
    .object({
      username: yup.string().required(i18next.t("verify_require_username")),
      password: yup.string().required(i18next.t("verify_require_password")),
      confirmed: yup
        .string()
        .required(i18next.t("verify_require_confirm_password"))
        .oneOf([yup.ref("password")], i18next.t("verify_unmatch_password")),
    })
    .required();

  const [activeTab, setActiveTab] = useState("phone");

  const ref_no = params?.get("ref_no");

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data);
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
        // setIsSuccess(true);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    if (!ref_no) return;
    setValue("ref_no", ref_no);
  }, [ref_no]);

  return (
    <>
      <div className="starfield">
        <div className="static" />
        <div className="moving-1" />
        <div className="moving-2" />
        <div className="moving-3" />
      </div>
      <div className="container auth-page h-[100vh]">
        <div className="card">
          <form className="card-form" onSubmit={handleSubmit(handleRegister)}>
            <div className="margin-topbox-px-10">
              <div data-bn-type="text" className="css-1g5tc38  tcc fch f36 fw">
                {i18next.t("sign_up")}
                <span className="floginbr">
                  {authStore?.general?.name_website}
                </span>
              </div>
            </div>
            <div className="tabs mb-[15px]">
              <div
                className={classNames("tab", {
                  active: activeTab === "phone",
                })}
                onClick={() => setActiveTab("phone")}
              >
                {i18next.t("phone")}
              </div>
              <div
                className={classNames("tab", {
                  active: activeTab === "email",
                })}
                onClick={() => setActiveTab("email")}
              >
                Email
              </div>
            </div>
            <div className="input">
              <input
                type="text"
                className="input-field"
                name="username"
                {...register("username")}
              />
              <label className="input-label">
                {activeTab === "email"
                  ? i18next.t("enter_email")
                  : i18next.t("enter_phone")}
              </label>
            </div>
            <div className="input">
              <input
                type="password"
                className="input-field"
                {...register("password")}
              />
              <label className="input-label">
                {i18next.t("enter_login_password")}
              </label>
            </div>
            <div className="input">
              <input
                type="password"
                className="input-field"
                {...register("confirmed")}
              />
              <label className="input-label">
                {i18next.t("enter_confirm_login_password")}
              </label>
            </div>
            <div className="action">
              <button type="submit" className="action-button">
                {i18next.t("sign_up")}
              </button>
            </div>
          </form>
          <div className="login-reg tcr">
            <Link
              to="/auth/login"
              className="css-utqtyo fbaseblue"
              style={{ fontSize: 18 }}
            >
              {i18next.t("sign_in")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterDesktop;
