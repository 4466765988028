import React, { useEffect, useState } from "react";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import classNames from "classnames";
import { gameBetItemType } from "_common/constants/statusType";
import moment from "moment";
import { get } from "lodash";
import { GamePlayerApi } from "states/api/gamePlayer";
import { useQuery } from "@tanstack/react-query";

export default function DataItem({ data }) {
  return (
    <div key={data.id} className="listbox">
      <div className="listbox_title">
        <div className="listbox_title_l">
          <p className="text-[20px] uppercase">{data?.game?.name}</p>
        </div>
        <div className="listbox_title_r">
          <p
            className={classNames("f14 text-[16px] text-[500] uppercase", {
              "text-[#f5465c]": data?.bet_game === "sell",
              "text-[#60c08c]": data?.bet_game === "buy",
            })}
          >
            {i18next.t(gameBetItemType[data?.bet_game])}
          </p>
        </div>
      </div>
      <div className="w-full py-[5px] flex flex-wrap">
        <div className="flex justify-between h-[30px] datas-center w-full">
          <span className="fbv text-[14px]">{i18next.t("order_number")}</span>
          <span className="text-[14px]">{data?.id}</span>
        </div>
        <div className="flex justify-between h-[30px] datas-center w-full">
          <span className="fbv text-[14px]">{i18next.t("order_time")}</span>
          <span className="text-[14px]">
            {" "}
            {moment(data?.created_at).format("MM-DD HH:mm:ss")}
          </span>
        </div>
        <div className="flex justify-between h-[30px] datas-center w-full">
          <span className="fbv text-[14px]">
            {i18next.t("transaction_mode")}
          </span>
          <span className="text-[14px]">{i18next.t(data?.mode)}</span>
        </div>
        <div className="flex justify-between h-[30px] datas-center w-full">
          <span className="fbv text-[14px]">{i18next.t("price")}</span>
          <span className="text-[14px]">
            {formatNumber(data?.open, "0,0.[00000]")}
          </span>
        </div>
        <div className="flex justify-between h-[30px] datas-center w-full">
          <span className="fbv text-[14px]">{i18next.t("quantity")}</span>
          <span className="text-[14px]">
            {formatNumber(data?.amount, "0,0.[0000]")}
          </span>
        </div>
        <div className="flex justify-between h-[30px] datas-center w-full">
          <span className="fbv text-[14px]">{i18next.t("total_price")}</span>
          <span className="text-[14px]">
            {formatNumber(data?.final_total, "0,0.[00000]")}
          </span>
        </div>
        <div className="flex justify-between h-[30px] datas-center w-full">
          <span className="fbv text-[14px]">{i18next.t("arrived_amount")}</span>
          <span className="text-[14px]">
            {formatNumber(data?.amount, "0,0.[0000]")}
          </span>
        </div>
        <div className="flex justify-between h-[30px] datas-center w-full">
          <span className="fbv text-[14px]">{i18next.t("status")}</span>
          <span className="text-[14px]">
            {data?.status === "bet"
              ? i18next.t("inprogress")
              : i18next.t("complete")}
          </span>
        </div>
      </div>
    </div>
  );
}
