import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faRotate, faStar } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import GameChart from "./GameChart";
import GameHeader from "./GameHeader";
import TotalStation from "./TotalStation";

const MarketDetailPage = observer(() => {
  const {
    authStore: { games, user, favorites, setFavorites },
  } = useStores();
  const { isMobile } = useMediaQuery();

  const navigate = useNavigate();
  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const time = params?.get("time") || "1m";
  const theme = params?.get("theme") || "dark";
  const type = params?.get("type") || "contract";

  const [game_id, setGameId] = useState(games[0]?.code);
  const [isLight, setIsLight] = useState(false);
  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    if (!theme) return;
    setIsLight(theme === "light");
  }, [theme]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  const handelSubmit = (direction) => {
    if (type === "contract") {
      navigate(`/contract?direction=${direction}`);
    }
    if (type === "feature") {
      navigate(`/feature?direction=${direction}`);
    }
    if (type === "trade") {
      navigate(`/spot?direction=${direction}`);
    }
  };

  return (
    <div
      className={classNames(
        "flex flex-col flex-wrap flex-1 trade-page trade-detail-page",
        {
          light: isLight,
        }
      )}
    >
      {isMobile && (
        <HeaderTop
          title={
            <span className="uppercase">{`${
              game_id?.split("usdt")?.[0]
            } / USDT`}</span>
          }
          right={
            <FontAwesomeIcon
              className={classNames("text-[20px]", {
                "text-[#11c1a4]": favorites?.indexOf(game_id) > -1,
              })}
              icon={faStar}
              onClick={() => setFavorites(game_id)}
            />
          }
        />
      )}

      <GameHeader
        data_price={priceBtc}
        game_id={game_id}
        isLight={isLight}
        setIsLight={() => setIsLight(!isLight)}
        time={time}
      />
      <div className="flex flex-1">
        <div className="relative w-full h-full">
          <div className="relative w-full h-full">
            <div className="w-full h-full">
              <GameChart game_id={game_id} isLight={isLight} time={time} />
            </div>
          </div>
        </div>
      </div>
      <div className="trade-footer">
        <button
          type="button"
          onClick={() => handelSubmit("buy")}
          className="btn-buy"
        >
          <span>
            {type === "contract" ? i18next.t("rise") : i18next.t("buy")}
          </span>
        </button>
        <button
          type="button"
          onClick={() => handelSubmit("sell")}
          className="btn-sell"
        >
          {type === "contract" ? i18next.t("down") : i18next.t("sell")}
        </button>
      </div>
      <div className="feature-page !p-0">
        <h4 className="h-[40px] px-[25px] ">
          <span className="text-white ">{i18next.t("tit33")}</span>
        </h4>
      </div>
      <TotalStation />
    </div>
  );
});

export default MarketDetailPage;
