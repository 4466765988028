import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FundApi } from "states/api/fund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "_common/hooks";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { UserFundApi } from "states/api/userFund";
import { NotificationManager } from "react-notifications";

const schema = yup
  .object({
    amount: yup
      .number()
      .min(0, `${i18next.t("enter_large_amount_msg")} 0`)
      .required(i18next.t("verify_require")),
  })
  .required();

export default function FundDetail() {
  const navgate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const id = params?.id;
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const { data: detail, refetch } = useQuery(
    ["funds", "detail", id],
    () =>
      FundApi.getDetail({
        id,
      }),
    {
      staleTime: 300000,
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    refetch();
  }, []);

  const { mutate, isLoading } = useMutation(
    (variables) => UserFundApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        navgate(-1);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    trigger();
    const amount = values?.amount;

    if (Number(amount) > Number(detail?.max)) {
      return NotificationManager.error(
        `${i18next.t("enter_smaller_deposit_amount")} ${formatNumber(
          detail?.max
        )}`
      );
    }

    if (Number(amount) < Number(detail?.min)) {
      return NotificationManager.error(
        `${i18next.t("enter_large_deposit_amount")} ${formatNumber(
          detail?.min
        )}`
      );
    }

    mutate({
      ...values,
      fund_id: id,
    });

    return null;
  };

  const profitPlan = useMemo(() => {
    const min = Number(get(detail, "min", 0));
    const max = Number(get(detail, "max", 0));
    const date = Number(get(detail, "date", 0));
    const max_profit = Number(get(detail, "max_profit", 0)) / 100;
    const min_profit = Number(get(detail, "min_profit", 0)) / 100;
    return {
      min_fund: min * min_profit * date,
      max_fund: max * max_profit * date,
    };
  }, [detail]);

  return (
    <div className="sbui-loading">
      <HeaderTop title={i18next.t("mining_confirmation")} />
      <form className="fund-detail" onSubmit={handleSubmit(onSave)}>
        <div key={detail?.id} className="item">
          <div className="fund-head">
            <h2 className="font-bold text-center text-[20px] text-[#000]">
              {detail?.name}
            </h2>
            <h2 className="mt-[15px] font-bold leading-[30px] text-center text-[32px] text-[#ff6a6a]">
              {formatNumber(detail?.profit, "0,0.[0000]")}%
            </h2>
            <p className="font-[500] text-center text-[16px]">
              {i18next.t("daily_rate")}
            </p>
          </div>
          <div className="flex justify-between items-center font-[500] text-[16px] mt-[15px]">
            <div>
              <span>{i18next.t("staking_coin")}</span>
              <span className="text-[#FBC501] ml-[5px]">USDT</span>
            </div>
            <div>
              <span>{i18next.t("profit_coin")}</span>
              <span className="text-[#FBC501] ml-[5px]">USDT</span>
            </div>
          </div>
          <div className="flex justify-between items-center font-[500] text-[16px] mt-[10px]">
            <div>
              <span>{i18next.t("remaining_share")}</span>
              <span className="text-[#000] ml-[5px]">
                {i18next.t("unlimited")}
              </span>
            </div>
            <div>
              <span>{i18next.t("duration")}</span>
              <span className="text-[#000] ml-[5px]">
                {detail?.date} {i18next.t("day")}
              </span>
            </div>
          </div>
          <div className="section-info  mt-2">
            <p className="text-[#000] font-bold">
              {i18next.t("investment_amount")}
            </p>
            <div className="sbui-formlayout__content-container-horizontal mt-2">
              <div className="sbui-input-container input-fund">
                <input
                  type="number"
                  inputMode="decimal"
                  min={0}
                  step={0.00000001}
                  className="sbui-input sbui-input--medium"
                  placeholder={`${formatNumber(
                    detail?.min
                  )}USDT ~ ${formatNumber(detail?.max)}USDT`}
                  {...register("amount")}
                />
                <button
                  type="button"
                  className="btn btn-all"
                  onClick={() => {
                    const temp = Number(get(user, "balance_web3", 0));
                    setValue("amount", temp);
                  }}
                >
                  {i18next.t("total")}
                </button>
              </div>
            </div>
          </div>
          <div className="font-[500] text-[#888] text-[13px]">
            <p className="text-right">
              {i18next.t("available")}:{" "}
              {formatNumber(user?.balance_web3, "0,0.[00000]")} USDT
            </p>
            <p className="text-right">
              {i18next.t("remaining_copies_available")}:{" "}
              {formatNumber(detail?.max, "0,0.[00000]")} USDT
            </p>
          </div>
          <button type="submit" className="btn-fund">
            {i18next.t("staking_now")}
          </button>
        </div>
      </form>
    </div>
  );
}
