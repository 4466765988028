import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import MemberMobile from "./Mobile/MemberMobile";
import MemberDesktop from "./Desktop/MemberDesktop";

const MarketPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <MemberMobile />;
  return <MemberDesktop />;
});

export default MarketPage;
