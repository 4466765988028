import React from "react";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import HeaderTop from "_common/Layout/Header/HeaderTop";

import { faAngleRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import telegramIcon from "assets/img/icon/telegram.png";
import cskhIcon from "assets/img/icon/cskh.png";

export default function ServiceMobile() {
  const navigate = useNavigate();
  const {
    authStore: { general },
  } = useStores();

  return (
    <div className="container !w-[100%]">
      <HeaderTop title="Customer service" />
      <div className="customer-cont">
        {/* <div className="title">Customer service</div> */}
        <div className="list">
          {general?.telegram_link1 && (
            <a href={general?.telegram_link1} target="_blank" rel="noreferrer">
              <div className="line">
                <div className="left">
                  <img src={telegramIcon} alt="" className="icon" />
                  <div className="line-text">
                    {i18next.t("cskh")} ({i18next.t("line")} 1)
                  </div>
                </div>
                <div className="right">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </a>
          )}
          {general?.telegram_link2 && (
            <a href={general?.telegram_link2} target="_blank" rel="noreferrer">
              <div className="line">
                <div className="left">
                  <img src={telegramIcon} alt="" className="icon" />
                  <div className="line-text">
                    <div className="line-text">
                      {i18next.t("cskh")} ({i18next.t("line")} 2)
                    </div>
                  </div>
                </div>
                <div className="right">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </a>
          )}
          {general?.telegram_link3 && (
            <a href={general?.telegram_link3} target="_blank" rel="noreferrer">
              <div className="line">
                <div className="left">
                  <img src={telegramIcon} alt="" className="icon" />
                  <div className="line-text">
                    {i18next.t("cskh")} ({i18next.t("line")} 3)
                  </div>
                </div>
                <div className="right">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </a>
          )}
          {general?.link_support && (
            <a href={general?.link_support} target="_blank" rel="noreferrer">
              <div className="line">
                <div className="left">
                  <img src={cskhIcon} alt="" className="icon" />
                  <div className="line-text">{i18next.t("live_chat")}</div>
                </div>
                <div className="right">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
