import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { UserFundApi } from "states/api/userFund";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import { fundStatus } from "_common/constants/statusType";
import moment from "moment";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function ListDataHistory() {
  const navigate = useNavigate();
  const { data, refetch } = useQuery(
    ["funds", "history"],
    () =>
      UserFundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <HeaderTop title={i18next.t("my_staking")} />
      <div className="list-history-data">
        {data?.data?.map((item) => (
          <div key={item?.id} className="item">
            <div className="flex  justify-between items-center text-white py-[10px] px-[12px]">
              <h3 className="text-[#1e1e1e] font-bold text-[16px]">
                {item?.fund.name}
              </h3>
              <div
                className="flex items-center gap-2"
                onClick={() => navigate(`/board/fund/history/${item?.id}`)}
              >
                <span className="text-[#fbbc01] font-bold text-[14px]">
                  {i18next.t("stacking")}
                </span>
                <FontAwesomeIcon
                  className="text-[#bdbdbd]"
                  icon={faAngleRight}
                />
              </div>
            </div>
            <div className="flex  justify-between items-center gap-2 px-[12px]">
              <div className="flex flex-col items-center flex-1">
                <h4 className="text-[15px] text-[#888] mb-[5px]">
                  {i18next.t("amount")}
                </h4>
                <span className="text-[16px] font-bold text-center text-[#1e1e1e]">
                  {formatNumber(item?.amount)}
                </span>
              </div>
              <div className="flex flex-col items-center flex-1">
                <h4 className="text-[15px] text-[#888] mb-[5px] text-center">
                  {i18next.t("daily_rate")}
                </h4>
                <span className="text-[16px] font-bold text-center text-[#1e1e1e]">
                  {formatNumber(item?.fund?.profit, "0,0.[000]")}%
                </span>
              </div>
              <div className="flex flex-col items-center flex-1">
                <h4 className="text-[15px] text-[#888] mb-[5px] text-right">
                  {i18next.t("expected_profit")}
                </h4>
                <span className="text-[16px] font-bold text-center text-[#fbbc01]">
                  {formatNumber(item?.amount_payback, "0,0.[000]")} USDT
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
