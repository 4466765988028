import React, { useState } from "react";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import { handleCopyLink } from "_common/utils/helper";
import QRCode from "react-qr-code";

import imgBgRef from "assets/img/bg-ref.jpg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeftNav from "pages/User/LeftNav";
import classNames from "classnames";
import ListRecord from "./ListRecord";

export default function ShareLinkDesktop() {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  const [activeTab, setActiveTab] = useState("invite");

  return (
    <div className="user-page">
      <div className="container">
        <div className="box-sm flex justify-between">
          <LeftNav />
          <div className="person-right">
            <div className="bg-[#f8f8f8]">
              <div className="title">
                <h3>{i18next.t("invite")}</h3>
              </div>
              <div className="detail-box flex items-center mb-[20px]">
                <div className="flex items-center">
                  <div className="lever-box">
                    <div className="lever" />
                    <div className="flex items-center mb-[6px]">
                      <div className="font-bold">
                        {i18next.t("current_level")}:
                      </div>
                      <div className="current-lever">--</div>
                    </div>
                    <div className="flex items-center mb-[6px]">
                      <div className="font-bold">
                        {i18next.t("rebate_ratio")}:
                      </div>
                      <div className="current-lever">--%</div>
                    </div>
                  </div>
                  <div className="progress-box">
                    <div className="font-bold text-[13px] text-[#888] mb-[20px]">
                      {i18next.t("tip5")}
                    </div>
                    <div className="flex items-center mb-[6px]">
                      <div className="font-bold">{i18next.t("tip6")}</div>
                      <div className="el-progress" style={{ width: 400 }}>
                        <div className="el-progress-bar">
                          <div className="el-progress-bar__outer" />
                        </div>
                        <div className="el-progress__text">
                          <span className="progress-text">
                            0/1 {i18next.t("people")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center mb-[6px]">
                      <div className="font-bold">
                        {i18next.t("number_of_promotion")}
                      </div>
                      <div className="el-progress" style={{ width: 400 }}>
                        <div className="el-progress-bar">
                          <div className="el-progress-bar__outer" />
                        </div>
                        <div className="el-progress__text">
                          <span className="progress-text">
                            0/1 {i18next.t("people")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-start">
                  <div className="text-center">
                    <div className="rebate">
                      {i18next.t("commission_today")}
                    </div>
                    <div className="rebate-num">0</div>
                  </div>
                  <div className="text-center">
                    <div className="rebate">
                      {i18next.t("cumulative_commission")}
                    </div>
                    <div className="rebate-num">0</div>
                  </div>
                </div>
              </div>
              <div className="detail-box flex items-center mb-[20px]">
                <div className="flex items-center justify-between w-full gap-4">
                  <div className="invita-left">
                    <div className="font-bold text-[#989898]">
                      {i18next.t("recommend_id")}
                    </div>
                    <div className="kerword ">
                      <span className="text-[12px] font-[500]">
                        {user?.ref_no}
                      </span>
                      <span
                        className="btn-asset text-[#fbbc01]"
                        onClick={() => handleCopyLink(user?.ref_no)}
                      >
                        {i18next.t("copy")}
                      </span>
                    </div>
                  </div>
                  <div className="invita-center">
                    <div className="font-bold text-[#989898]">
                      {i18next.t("invite_link")}
                    </div>
                    <div className="kerword !w-[430px]">
                      <span className="text-[12px] font-[500]">
                        {user?.link_ref}
                      </span>
                      <span
                        className="btn-asset text-[#fbbc01]"
                        onClick={() => handleCopyLink(user?.link_ref)}
                      >
                        {i18next.t("copy")}
                      </span>
                    </div>
                  </div>
                  <div className="invita-right w-[237px] flex justify-between items-start">
                    <div className="text-center">
                      <div className="rebate">{i18next.t("tip7")}</div>
                      <div className="rebate-num">0</div>
                    </div>
                    <div className="text-center">
                      <div className="rebate">{i18next.t("tip8")}</div>
                      <div className="rebate-num">0</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs tab-box">
                <div
                  className={classNames("tab", {
                    active: activeTab === "invite",
                  })}
                  onClick={() => setActiveTab("invite")}
                >
                  {i18next.t("invite_detail")}
                </div>
                <div
                  className={classNames("tab", {
                    active: activeTab === "cashback",
                  })}
                  onClick={() => setActiveTab("cashback")}
                >
                  {i18next.t("cashback_detail")}
                </div>
              </div>
              <div className="bg-white pt-[20px]">
                <div className="px-[30px]">
                  <ListRecord />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
