import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import ModalFund from "./ModalFund";

export default function ItemData({ data }) {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  return (
    <tr key={data?.id}>
      <td>{data?.name}</td>
      <td className="uppercase">{data?.currency}</td>
      <td className="uppercase">{data?.currency}</td>
      <td>{i18next.t("unlimited")}</td>
      <td>
        {formatNumber(data?.profit, "0,0.[00000]")}%{i18next.t("daily_rate")}
      </td>
      <td>
        {formatNumber(data?.min, "0,0.[00000]")} ~{" "}
        {formatNumber(data?.max, "0,0.[00000]")}
      </td>
      <td>{formatNumber(data?.date, "0,0.[00000]")}</td>
      <td>{i18next.t("daily")}</td>
      <td>{i18next.t("income")}</td>
      <td>
        <button
          type="button"
          className="btn btn-mine"
          onClick={() => setOpenModal(true)}
        >
          {i18next.t("mine_now")}
        </button>
      </td>
      {openModal && (
        <ModalFund
          open={openModal}
          data={data}
          onClose={() => setOpenModal(false)}
        />
      )}
    </tr>
  );
}
