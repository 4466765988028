import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import ServiceMobile from "./Mobile";
import ServiceDesktop from "./Desktop";

const CSKHPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <ServiceMobile />;
  return <ServiceDesktop />;
});

export default CSKHPage;
