import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";

export default function GameHeader({ game_id, data_price }) {
  const {
    authStore: { games, user },
  } = useStores();

  return (
    <div className="w-[100%] h-[50px] border-b-[3px] border-[#151617] bg-[#1a1b1c]">
      <div className="klinetitle !w-[58%] ">
        <div className="klinetitle_l">
          <p className="newpricebox fw !h-[50px] !leading-[50px] !text-[16px] !text-center">
            <b className="text-uppercase">{game_id?.split("usdt")?.[0]}</b>
            /USDT
          </p>
        </div>

        <div className="klinetitle_r">
          <div className="col-2 klinetitle-s-box">
            <div
              className={classNames("newpricebox f12 fcf", {
                red: data_price?.priceChangePercent <= 0,
                green: data_price?.priceChangePercent > 0,
              })}
            >
              {formatNumber(data_price?.lastPrice, "0,0.[0000]")}
            </div>
            <div className="changebox f12 fcf">
              ≈{formatNumber(data_price?.lastPrice, "0,0.[0000]")}
            </div>
          </div>
          <div className="col-2 klinetitle-s-box">
            <div className="newpricebox f12 fcf">{i18next.t("change")}</div>
            <div
              className={classNames("changebox f12 fcf", {
                red: data_price?.priceChangePercent <= 0,
                green: data_price?.priceChangePercent > 0,
              })}
            >
              {formatNumber(data_price?.priceChangePercent, "0,0.[00]")}%
            </div>
          </div>
          <div className="col-2 klinetitle-s-box">
            <div className="newpricebox f12 fcf">{i18next.t("high")}</div>
            <div className="changebox f12 fcf">
              {formatNumber(data_price?.highPrice, "0,0.[0000]")}
            </div>
          </div>
          <div className="col-2 klinetitle-s-box">
            <div className="newpricebox f12 fcf">{i18next.t("low")}</div>
            <div className="changebox f12 fcf">
              {formatNumber(data_price?.lowPrice, "0,0.[0000]")}
            </div>
          </div>
          <div className="col-2 klinetitle-s-box">
            <div className="newpricebox f12 fcf">{i18next.t("24h_vol")}</div>
            <div className="changebox f12 fcf">
              {formatNumber(data_price?.volume, "0,0.[0000]")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
