import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import FeatureHistoryMobile from "./Mobile";
import FeatureHistoryDesktop from "./Desktop";

const HistoryFeaturePage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <FeatureHistoryMobile />;
  return <FeatureHistoryDesktop />;
});

export default HistoryFeaturePage;
