import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import i18next from "i18next";
import HeaderNav from "_common/Layout/Header/HeaderNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChartSimple,
  faCog,
  faFile,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import GameBoard from "./GameBoard";
import HistoryPrice from "./HistoryPrice";
import HistoryTrade from "./HistoryTrade";
import ListCoin from "./ListCoin";

const FeatureMobilePage = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();
  const navigate = useNavigate();

  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const direction = params?.get("direction") || "long";

  const [reportData, setReportData] = useState({
    percent: 0,
    profit: 0,
    margin: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const [game_id, setGameId] = useState(games[0]?.code);
  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  const mainCoin = useMemo(
    () => games?.find((obj) => obj?.code === game_id),
    [games, game_id]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  const handleChangeProfit = useCallback((data) => {
    setReportData(data);
  }, []);

  return (
    <>
      <div className="feature-page spot-page">
        <div className="w-full">
          <div className="tabs">
            <div className="tab active" onClick={() => navigate("/feature")}>
              {i18next.t("usdt_feature")}
            </div>
            <div className="tab" onClick={() => navigate("/contract")}>
              {i18next.t("options")}
            </div>
          </div>
        </div>
        <div className="flex justify-between" style={{ border: "none" }}>
          <div className="w-full h-[50px] flex justify-between items-center gap-3">
            <div className="flex items-center gap-3">
              <span
                className="leading-[20px] text-[18px] "
                onClick={() => setOpenModal(true)}
              >
                <FontAwesomeIcon icon={faBars} />
              </span>
              <b className="text-uppercase text-black text-[18px] leading-[20px]">
                {mainCoin?.name}
              </b>
              <span className="text-[18px]">{i18next.t("swap")}</span>
            </div>
            <div className="flex items-center justify-end gap-2">
              <FontAwesomeIcon
                className="text-[18px] text-[#b9b9b9] border-co"
                icon={faStar}
              />
              <Link
                to={`/market/detail?symbol=${game_id}&type=feature`}
                className="text-[#FBC501] text-[18px]"
              >
                <FontAwesomeIcon icon={faChartSimple} />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-between bg-[#f7f7f7] p-[8px] rounded-[14px] text-[13px]">
          <div className="w-[27%]">
            <p>{i18next.t("user_right")}</p>
            <p className="">
              {formatNumber(
                Number(user?.balance_trading) + Number(reportData?.profit),
                "0,0.[00000]"
              )}
            </p>
          </div>
          <div className="w-[35%]">
            <p className="text-center">{i18next.t("unrealized_profit_loss")}</p>
            <p className=" text-center">
              {formatNumber(reportData?.profit, "0,0.000000")}
            </p>
          </div>
          <div className="w-[27%] text-right">
            <p>{i18next.t("risk_rate")}</p>
            <p className="">
              {reportData?.margin > 0 &&
                formatNumber(
                  ((Number(user?.balance_trading) +
                    Number(reportData?.profit)) /
                    Number(reportData?.margin)) *
                    100,
                  "0,0.[00]"
                )}
              %
            </p>
          </div>
        </div>
        <div className="flex mt-[15px]">
          <div className="flex-1">
            <GameBoard
              game_id={game_id}
              data_price={priceBtc}
              direction={direction}
            />
          </div>
          <div className="flex-1">
            <HistoryPrice />
          </div>
        </div>
        <HistoryTrade
          data_price={priceBtc}
          setReportData={handleChangeProfit}
        />
      </div>
      {openModal && (
        <ListCoin open={openModal} onClose={() => setOpenModal(false)} />
      )}
    </>
  );
});

export default FeatureMobilePage;
