import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import HistoryDepositMobile from "./Mobile";
import HistoryDepositDesktop from "./Desktop";

const ListHistoryBalance = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <HistoryDepositMobile />;
  return <HistoryDepositDesktop />;
});

export default ListHistoryBalance;
