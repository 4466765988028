import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

import Pagination from "_common/component/Pagination";
import { PaymentApi } from "states/api/payment";
import i18next from "i18next";
import HeaderTop from "_common/Layout/Header/HeaderTop";
import ListData from "./ListData";

export default function ListHistoryBalance() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const [params, setParams] = useState();
  const [activeTab, setActiveTab] = useState();

  const { data, refetch } = useQuery(
    ["histories", activeTab, params],
    () =>
      PaymentApi.getList({
        params: {
          page: 1,
          limit: 20,
          type: activeTab,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("transaction_history")} />
      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg shadow">
              <ListData data={data} />
            </div>
          </div>
        </div>
      </div>
      {/* <Pagination /> */}
    </div>
  );
}
