import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import { gameBetSpotStatus } from "_common/constants/statusType";
import classNames from "classnames";
import { get } from "lodash";
import i18next from "i18next";
import ModalClosePosition from "pages/Feature/Mobile/ModalClosePosition";

const Item = observer(({ status, data_price, data }) => {
  const navigate = useNavigate();

  const [openModalClose, setModalClose] = useState();
  const [dataDetail, setDataDetail] = useState();

  const handleClosePosition = (data) => {
    setDataDetail(data);
    setModalClose(true);
  };

  const percentAmount = useMemo(() => {
    const lastPrice = Number(get(data_price, "lastPrice", 0));
    const open = Number(get(data, "open", 1));
    const amountLever = Number(get(data, "lever", 0));
    const betGame = data?.bet_game;

    if (betGame === "short") {
      return ((open - lastPrice) / open) * 100 * amountLever;
    }

    return ((lastPrice - open) / open) * 100 * amountLever;
  }, [data, data_price?.lastPrice]);

  const profitAmount = useMemo(() => {
    const lastPrice = Number(get(data_price, "lastPrice", 0));
    const open = Number(get(data, "open", 1));
    const amountLever = Number(get(data, "amount_lever", 0));
    const betGame = data?.bet_game;
    if (betGame === "short") {
      return (open - lastPrice) * amountLever;
    }

    return (lastPrice - open) * amountLever;
  }, [data, percentAmount]);

  return (
    <tr className=" f12" key={data?.id}>
      <td
        className={classNames("f14", {
          "text-[#f5465c]": data?.bet_game === "short",
          "text-[#60c08c]": data?.bet_game === "long",
        })}
      >
        {data?.game?.name} {data?.lever}X
      </td>
      <td className="fck f14">{formatNumber(data?.open, "0,0.[0000]")}</td>
      {status === "closed" && (
        <td className="fck f14">{formatNumber(data?.close, "0,0.[0000]")}</td>
      )}
      <td className="fck f14">{formatNumber(data?.total, "0,0.[0000]")}</td>
      <td className="fck f14">{formatNumber(data?.amount, "0,0.[0000]")}</td>
      <td
        className={classNames("f14", {
          "text-[#f5465c]": data?.bet_game === "short",
          "text-[#60c08c]": data?.bet_game === "long",
        })}
      >
        {i18next.t(data?.bet_game)}
      </td>
      <td className="fck f14">
        {data?.created_at &&
          moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")}
      </td>
      {status === "open" && (
        <td className="fck f14">{formatNumber(profitAmount, "0,0.[0000]")}</td>
      )}
      {status === "closed" && (
        <td className="fck f14">{formatNumber(data?.fee, "0,0.[0000]")}</td>
      )}
      <td className="fck f14">
        {status === "open" && (
          <span
            className={classNames("text-[14px] text-right", {
              "text-[#f5465c]": percentAmount <= 0,
              "text-[#60c08c]": percentAmount > 0,
            })}
          >
            {formatNumber(percentAmount, "0,0.[00]")}%
          </span>
        )}
        {data?.amount_payback > 0 && (
          <span className="text-[#3db485]">
            + {formatNumber(data?.amount_payback, "0,0.[0000]")}
          </span>
        )}
        {data?.amount_lose > 0 && (
          <span className="text-[#f5465c]">
            - {formatNumber(data?.amount_lose, "0,0.[0000]")}
          </span>
        )}
      </td>
      <td className="fck f14">{i18next.t(gameBetSpotStatus[data?.status])}</td>
      {status === "open" && (
        <td>
          <button
            type="button"
            className="btn btn-contract"
            onClick={() => handleClosePosition(data)}
          >
            {i18next.t("close_position")}
          </button>
        </td>
      )}
      {openModalClose && (
        <ModalClosePosition
          data_price={data_price}
          data={dataDetail}
          open={openModalClose}
          onClose={() => setModalClose(false)}
        />
      )}
    </tr>
  );
});

export default Item;
