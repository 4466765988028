import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import FeatureMobilePage from "./Mobile";
import FeatureDesktopPage from "./Desktop/FeatureDesktop";

const FeaturePage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <FeatureMobilePage />;
  return <FeatureDesktopPage />;
});

export default FeaturePage;
